import { Box, Divider, Flex, FormControl, Img, Text, useToast } from '@chakra-ui/react'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { Body } from '../../Layouts/Body'
import { BoxColor, InfoPDI, MediaGeralContainer, OutlineColumn, OutlineContainer, StatusContainer, TipoPesquisa, Titulo } from './styles'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { FaChartBar, FaPeopleArrows, FaRegWindowRestore, FaSearch, FaUserCheck, FaUsers } from 'react-icons/fa'
import { GaugeChart } from './Components/GaugeChart'
import LogoStake from '../../../assets/PesquisaStakeholders.svg'
import { useState, useEffect } from 'react'
import { appApi } from '../../../services/appApi'
import { iNomeId } from '../../../interfaces'
import { useNavigate } from 'react-router-dom'
import IconeOutros from '../../../assets/IconeOutrosAzul.svg'
import IconeStake from '../../../assets/IconeStakeAzul.svg'
import IconeLider from '../../../assets/IconeLider.svg'
import IconeCritico from '../../../assets/IconeCritico.svg'
import { ImgSah } from '../../../components/ImgSah'
import { parseJwt } from '../../../services/token'
import { PropInvalida } from '../../../Utils/Helper'
import { MediaAvaliacoesChart } from './Components/MediaAvaliacoesArea'
import { MediaAvaliacoesChartGestor } from './Components/MediaAvaliacoesGestor'
import { SelectCmp } from '../../../components/SelectCmp'
import { InputCmp } from '../../../components/InputCmp'
import LoadingContainer from '../../../components/LoadingContainer'

interface iStatusCount {
  totalElaboracao: number
  totalAndamento: number
  totalFinalizadas: number
}

interface iMediaCount {
  nome: string
  media: number
}

interface iModel {
  statusCount: iStatusCount
  totalPdiAndamento: number
  totalPdiAprovacao: number
  pesquisaSelecionada?: string
  pesquisas: iNomeId[]
}

interface iStatusCategoria {
  total: number
  totalRespondido: number
  nome: string
}

interface iCountGeral {
  totalAvaliacoes: number
  totalRespostas: number
  totalAvaliadores: number
  totalAdesao: number
}

interface iResumoResultado {
  id: string
  nomePesquisa: string
  statusCategorias: iStatusCategoria[]
  countGeral: iCountGeral
  mediaStakeholders: iMediaCount
  mediaAutoavaliacao: iMediaCount
  maiorMedia: iMediaCount
  menorMedia: iMediaCount
  departamentos: iNomeId[]
  indicativa: boolean
}

export interface iPropsGrafico {
  competencias: string[]
  dados: number[]
  grafico: number[]
  fator: number
  escala: string
  id: string
}
export interface iPropsGraficoGestor {
  competencias: string[]
  avatar: string[]
  gestoresId: string[]
  gestoresNome: string[]
  dados: number[]
  grafico: number[]
  fator: number
  escala: string
  id: string
}

export const DashboardDesempenho: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()

  const [Model, setModel] = useState<iModel>()
  const [Resumo, setResumo] = useState<iResumoResultado>()
  const [PesquisaId, setPesquisaId] = useState<string>('')
  const [GestorId, setGestorId] = useState<string>('')
  const [DepartamentoId, setDepartamentoId] = useState<string>('')
  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingGestor, setIsLoadingGestor] = useState(false)
  const [DadosGrafico, setDadosGrafico] = useState<iPropsGrafico[]>()
  const [DadosGraficoGestor, setDadosGraficoGestor] = useState<iPropsGraficoGestor[]>()
  const [DataIncio, setDataIncio] = useState('')
  const [DataFim, setDataFim] = useState('')

  function getDash(): void {
    setIsLoading(true)
    appApi.get('PesquisaAnaliseDesempenho/Desempenho/Dashboard')
      .then(res => {
        setModel(res.data)
        if (res.data.pesquisaSelecionada) {
          getResumo(res.data.pesquisaSelecionada, '')
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function getResumo(pesqusaiId: string, departamentoId: string): void {
    setIsLoading(true)
    setPesquisaId(pesqusaiId)
    setDepartamentoId(departamentoId)
    setResumo(undefined)
    appApi.get(`AvaliacaoPesquisa/${pesqusaiId}/ResumoResultadoGeral?departamentoId=${departamentoId}`)
      .then(res => {
        setResumo(res.data)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function getIcon(nome: string): any {
    switch (nome) {
      case 'Autoavaliação': return <FaUserCheck size={22} style={{ marginRight: '.25rem' }} />
      case 'Pares': return <FaPeopleArrows size={22} style={{ marginRight: '.25rem' }} />
      case 'Lider': return <Img height={'2rem'} marginRight={'.25rem'} src={IconeLider} />
      case 'Liderados': return <FaUsers size={22} style={{ marginRight: '.25rem' }} />
      case 'Outros': return <img src={IconeOutros} width={22} height={22} style={{ marginRight: '.25rem' }}/>
      default: return <FaUserCheck size={22} style={{ marginRight: '.25rem' }} />
    }
  }

  function getIconAvaliados(nome: string): any {
    switch (nome) {
      case 'Autoavaliação': return <FaUserCheck size={40} />
      case 'Pares': return <FaPeopleArrows size={40} />
      case 'lider': return <Img height={'4rem'} marginRight={'.5rem'} src={IconeCritico} width={44}/>
      case 'Liderados': return <FaUsers size={40} />
      case 'Outros': return <img src={IconeOutros} width={44}/>
      default: return <FaUserCheck size={40} />
    }
  }

  function getDadosGrafico(dataInicio?: string, dataFim?: string): void {
    setIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/ResultadoPorDepartamento?dataInicio=${dataInicio ?? ''}&dataFim=${dataFim ?? ''}&`)
      .then((res) => {
        setDadosGrafico(res.data)
      }).catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }
  function getDadosGraficoGestor(gestorId: string): void {
    setGestorId(gestorId)
    setIsLoadingGestor(true)
    appApi.get(`AvaliacaoPesquisa/ResultadoPorGestor?gestorId=${gestorId}`)
      .then((res) => {
        setDadosGraficoGestor(res.data)
      }).catch(err => console.log(err))
      .finally(() => setIsLoadingGestor(false))
  }

  function handleButtonCmpClick(): void {
    if (new Date(DataFim ?? '') < new Date(DataIncio ?? '')) {
      toast({
        title: 'A data de fim deve ser posterior a data de início',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }
    getDadosGrafico(DataIncio, DataFim)
  }

  useEffect(() => {
    if (GestorId.length > 4) getDadosGraficoGestor(GestorId)
  }, [GestorId])

  useEffect(() => {
    setIsLoadingGestor(false)
  }, [DadosGraficoGestor])

  useEffect(() => {
    getDadosGrafico()
    getDadosGraficoGestor('00000000-0000-0000-0000-000000000000')
    getDash()
  }, [])

  return (
        <Body isLoading={IsLoading}>
            <WhiteContainer>
                <Flex gap={'.5rem'}>
                    <OutlineContainer theme={'Azul'}>
                        <Flex justifyContent={'center'}>
                            <StatusContainer>
                                <img src={LogoStake} width={'56rem'}/>
                                <Flex marginLeft={'1rem'} flexDir={'column'}>
                                    {
                                        (Model) && (
                                            <h3>{(Model?.statusCount?.totalFinalizadas + Model?.statusCount?.totalAndamento)} Pesquisas por competência</h3>
                                        )
                                    }
                                    <Text fontSize={'.875rem'} fontWeight={'700'} mb={'.5rem'}>Desenvolvidas no último ano</Text>
                                    {parseJwt().adm === 'True' &&
                                    <Flex gap={'.5rem'}>
                                        <ButtonCmp onClick={() => nav('/Desempenho/AnaliseDesempenho/Gestao')} VarColor='Rosa'>
                                            Gestão de pesquisas
                                        </ButtonCmp>
                                        <ButtonCmp onClick={() => nav('/Desempenho/AnaliseDesempenho/SelecionarModelo')} VarColor='Green2'>
                                            Criar nova pesquisa
                                        </ButtonCmp>
                                    </Flex>}
                                </Flex>
                                <hr />
                                <Flex flexDir={'column'} gap={'.125rem'}>
                                    <TipoPesquisa onClick={() => nav('/Desempenho/AnaliseDesempenho/Gestao?filtro=0')}>{Model?.statusCount?.totalElaboracao ?? 0} Pesquisas em elaboração <FaRegWindowRestore size={14}/></TipoPesquisa>
                                    <TipoPesquisa onClick={() => nav('/Desempenho/AnaliseDesempenho/Gestao?filtro=1')}>{Model?.statusCount?.totalAndamento ?? 0} Pesquisas em andamento <FaRegWindowRestore size={14}/></TipoPesquisa>
                                    <TipoPesquisa onClick={() => nav('/Desempenho/AnaliseDesempenho/Gestao?filtro=2')}>{Model?.statusCount?.totalFinalizadas ?? 0} Pesquisas finalizadas <FaRegWindowRestore size={14}/></TipoPesquisa>
                                </Flex>
                            </StatusContainer>
                        </Flex>
                    </OutlineContainer>

                    <Flex gap={'.5rem'}>
                        <OutlineContainer theme={'Azul'}>
                            <InfoPDI>
                                <h2>{Model?.totalPdiAprovacao}</h2>
                                <p>PDI(s) aguardando aprovação</p>
                            </InfoPDI>
                        </OutlineContainer>

                        <OutlineContainer theme={'Azul'}>
                            <InfoPDI>
                                <h2>{Model?.totalPdiAndamento}</h2>
                                <p>PDI(s) em andamento</p>
                            </InfoPDI>
                        </OutlineContainer>
                    </Flex>
                </Flex>
            </WhiteContainer>

            <LoadingContainer my='1rem' linhas={5} loading={!Model?.pesquisas}>
                {
                    (Model?.pesquisas && Model?.pesquisas?.length > 0) && (
                        <WhiteContainer margin='1rem 0 0 0'>
                            <Flex marginBottom={'.75rem'} gap={'.75rem'} width='70%'>
                                <FormControl>
                                    <SelectCmp title='Pesquisa' OnChange={(e) => { setDepartamentoId(''); getResumo(e, '') }} value={PesquisaId} borderColor={'var(--Gray4)'}>
                                        {
                                            Model?.pesquisas?.map((e) => {
                                              return (
                                                    <option key={e.id} value={e.id}>{e.nome}</option>
                                              )
                                            })
                                        }
                                    </SelectCmp>
                                </FormControl>

                                <FormControl isDisabled={Resumo && Resumo.countGeral.totalRespostas === 0}>
                                    <SelectCmp title='Área' OnChange={(e) => getResumo(PesquisaId, e)} value={DepartamentoId} borderColor={'var(--Gray4)'}>
                                        <option value={''}>Todas</option>
                                        {
                                            Resumo?.departamentos?.map((e) => {
                                              return (
                                                    <option key={e.id} value={e.id}>{e.nome}</option>
                                              )
                                            })
                                        }
                                    </SelectCmp>
                                </FormControl>
                            </Flex>

                            <LoadingContainer linhas={3} loading={!Resumo}>
                                {
                                    (Resumo) && (
                                        <>
                                            <Flex marginBottom={'1rem'} alignItems={'center'}>
                                                <h2>{Resumo.nomePesquisa}</h2>
                                                {parseJwt().adm === 'True' && <ButtonCmp
                                                    isDisabled={Resumo.countGeral.totalRespostas === 0}
                                                    VarColor='Green2' onClick={() => nav(`/Desempenho/AnaliseDesempenho/${Resumo.indicativa ? 'Indicativa' : 'Quantitativa'}/ResultadoGeral/${Resumo.id}`)}
                                                    size={'lg'} marginLeft={'1rem'}
                                                    leftIcon={<FaChartBar />}
                                                >Visualizar resultados</ButtonCmp>}
                                            </Flex>
                                            <Flex width={'100%'} gap='.75rem' justifyContent={'space-between'} marginBottom={'.75rem'}>
                                                <OutlineContainer theme={'Azul'} style={{ flex: '1' }}>
                                                    <Flex height={'100%'} justifyContent={'center'}>
                                                        <Flex alignItems={'center'}>
                                                            <OutlineColumn>
                                                                <h3>{Resumo.countGeral.totalAvaliacoes}</h3>
                                                                <h4>Avaliados</h4>
                                                            </OutlineColumn>

                                                            <OutlineColumn>
                                                                <h3>{Resumo.countGeral.totalAvaliadores}</h3>
                                                                <h4>Avaliadores</h4>
                                                            </OutlineColumn>

                                                            <OutlineColumn>
                                                                <h3>{Resumo.countGeral.totalRespostas}</h3>
                                                                <h4>Respostas</h4>
                                                            </OutlineColumn>

                                                        </Flex>
                                                        <Flex alignItems={'center'} width='12rem' mb={'-.5rem'} mt={'.5rem'}>
                                                            <GaugeChart id='sa' val={Resumo.countGeral.totalAdesao} />
                                                        </Flex>
                                                    </Flex>
                                                </OutlineContainer>

                                                <OutlineContainer theme={'Azul'} style={{ flex: '2' }}>
                                                    <h4 style={{ marginBottom: '.75rem', fontSize: '1.125rem' }}>Respostas por categoria</h4>
                                                    <Flex gap={'.5rem 0'} justifyContent={'start'} color={'var(--Azul)'} flexWrap='wrap'>
                                                        {
                                                            Resumo?.statusCategorias.map((e, i) => {
                                                              return (
                                                                    <Flex flexDir={'column'} fontSize={'.825rem'} alignItems={'center'} opacity={(e.totalRespondido === 0 && e.total === 0) ? '.5' : ''} cursor={(e.totalRespondido === 0 && e.total === 0) ? 'not-allowed' : ''} borderRight={Resumo?.statusCategorias.length !== (i + 1) ? '2px dashed var(--AzulOpaco)' : ''} p={Resumo?.statusCategorias.length !== (i + 1) ? '0 .75rem 0 .75rem' : '0 0 0 .75rem'}>
                                                                        <Flex alignItems={'center'}>
                                                                            {getIcon(e.nome)}
                                                                            <strong>{e.totalRespondido}/{e.total}</strong>
                                                                        </Flex>
                                                                        <strong style={{ color: 'black' }}>{e.nome}</strong>
                                                                    </Flex>
                                                              )
                                                            })
                                                        }
                                                    </Flex>

                                                </OutlineContainer>
                                            </Flex>
                                            {
                                                (!Resumo.indicativa && Resumo.countGeral.totalRespostas > 0) && (
                                                    <Flex gap={'.75rem'}>
                                                        <OutlineContainer theme={'Azul'}>
                                                            <Flex marginBottom={'.75rem'} justifyContent={'center'}>
                                                                <Text fontSize={'1rem'} fontWeight={'900'}>Média geral</Text>
                                                            </Flex>
                                                            <MediaGeralContainer>
                                                                <Flex>
                                                                    <Flex alignItems={'center'} marginRight='.75rem' flexDir={'column'}>
                                                                        <img src={IconeStake} width={42}/>
                                                                        <span style={{ color: 'black', fontWeight: 600 }}>Stakeholders</span>
                                                                    </Flex>
                                                                    <Flex flexDir={'column'} fontWeight={500}>
                                                                        <h3 style={{ color: 'black', fontSize: '.9rem' }}>Nota média</h3>
                                                                        <h2 style={{ color: 'var(--Azul)' }}>{Resumo?.mediaStakeholders.media.toFixed(2)}</h2>
                                                                    </Flex>
                                                                </Flex>
                                                                {
                                                                    (!PropInvalida(Resumo?.mediaAutoavaliacao)) && (
                                                                        <>
                                                                            <hr />
                                                                            <Flex>
                                                                                <Flex color={'black'} alignItems={'center'} marginRight='.75rem' flexDir={'column'}>
                                                                                    <FaUserCheck size={40} color={'var(--Azul)'}/>
                                                                                    <span style={{ fontWeight: 600 }}>Autoavaliação</span>
                                                                                </Flex>
                                                                                <Flex flexDir={'column'} color={'black'} fontWeight={500}>
                                                                                    <h3 style={{ fontSize: '.9rem' }}>Nota média</h3>
                                                                                    <h2 style={{ color: 'var(--Azul)' }}>{Resumo?.mediaAutoavaliacao.media.toFixed(2)}</h2>
                                                                                </Flex>
                                                                            </Flex>
                                                                        </>
                                                                    )
                                                                }
                                                            </MediaGeralContainer>
                                                        </OutlineContainer>
                                                        {
                                                            (Resumo.maiorMedia !== null || Resumo.menorMedia !== null) && (
                                                                <Flex gap={'.75rem'}>
                                                                    <OutlineContainer theme={'Azul'}>
                                                                        <Flex width={'13rem'} marginBottom={'.75rem'} justifyContent={'center'}>
                                                                            <Text fontSize={'1rem'} fontWeight={'900'}>Grupo que melhor avaliou</Text>
                                                                        </Flex>
                                                                        <BoxColor theme={'Azul'}>
                                                                            <Flex marginRight={'1rem'} alignItems={'center'} flexDir={'column'}>
                                                                                {Resumo.maiorMedia !== null && getIconAvaliados(Resumo?.maiorMedia.nome)}
                                                                                <span style={{ color: 'black', fontWeight: 600 }}>{Resumo?.maiorMedia?.nome}</span>
                                                                            </Flex>
                                                                            <Flex flexDir={'column'}>
                                                                                <span style={{ color: 'black' }}>Nota média</span>
                                                                                <h2>{Resumo?.maiorMedia?.media.toFixed(2)}</h2>
                                                                            </Flex>
                                                                        </BoxColor>
                                                                    </OutlineContainer>
                                                                    <OutlineContainer theme={'Azul'}>
                                                                        <Flex width={'13rem'} marginBottom={'.75rem'} justifyContent={'center'}>
                                                                            <Text fontSize={'1rem'} fontWeight={'900'}>Grupo mais crítico</Text>
                                                                        </Flex>
                                                                        <BoxColor theme={'terc3'}>
                                                                            <Flex marginRight={'1rem'} alignItems={'center'} flexDir={'column'}>
                                                                                {Resumo.menorMedia !== null && getIconAvaliados(Resumo?.menorMedia.nome)}
                                                                                <span style={{ color: 'black', fontWeight: 600 }}>{Resumo?.menorMedia?.nome}</span>
                                                                            </Flex>
                                                                            <Flex flexDir={'column'}>
                                                                                <span style={{ color: 'black' }}>Nota média</span>
                                                                                <h2>{Resumo?.menorMedia?.media.toFixed(2)}</h2>
                                                                            </Flex>
                                                                        </BoxColor>
                                                                    </OutlineContainer>
                                                                </Flex>
                                                            )
                                                        }

                                                    </Flex>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </LoadingContainer>

                        </WhiteContainer>
                    )
                }
            </LoadingContainer>

            <LoadingContainer my='1rem' linhas={12} loading={!(Model?.pesquisas && DadosGrafico !== undefined)}>
                {Model?.pesquisas && Model?.pesquisas?.length > 0 && DadosGrafico !== undefined && (
                    <>
                        <WhiteContainer margin='1rem 0 0 0'>
                            <Titulo>Gráfico de desempenho por área</Titulo>
                            <Flex gap={'1rem'} marginBottom={'1rem'} alignItems={'end'}>
                                <Box>
                                    <InputCmp
                                        label='Data de início:'
                                        type="date"
                                        w={'13rem'}
                                        value={DataIncio}
                                        max={DataFim}
                                        OnChange={setDataIncio}
                                    />
                                </Box>

                                <Box>
                                    <InputCmp
                                        label='Data de término:'
                                        type="date"
                                        w={'13rem'}
                                        value={DataFim}
                                        min={DataIncio}
                                        OnChange={setDataFim}
                                    />
                                </Box>
                                <ButtonCmp VarColor='Green2' isLoading={IsLoading} rightIcon={<FaSearch/>} onClick={handleButtonCmpClick}>Buscar</ButtonCmp>
                            </Flex>

                            {DadosGrafico?.length === 0 && (
                                <Text fontWeight='bold' alignSelf={'center'} my='1rem'>Não há resultados disponíveis</Text>
                            )}

                            {DadosGrafico?.map(e => {
                              return (
                                    <>
                                        <Flex gap={'1rem'}>
                                            <MediaAvaliacoesChart informacoes={e} />
                                        </Flex>
                                        <Divider mt={'.75rem'} mb={'1.5rem'} borderColor={'var(--c3)'} orientation='horizontal' />
                                    </>
                              )
                            })}
                        </WhiteContainer>

                    </>
                )}
            </LoadingContainer>

            <LoadingContainer my='1rem' linhas={12} loading={!(DadosGraficoGestor)}>
                {(DadosGraficoGestor && DadosGraficoGestor?.length > 0) &&
                    <WhiteContainer styles={{ minHeight: '22rem' }} margin='1rem 0 0 0' IsLoading={IsLoadingGestor}>
                        <Titulo>Gráfico de desempenho por gestor</Titulo>
                        {DadosGraficoGestor?.map((e, i) => {
                          return (
                                <>
                                    <Flex gap={'1rem'} marginBottom={'1rem'} alignItems={'center'}>
                                        {(e.gestoresId.length > 1 && i === 0) &&
                                            <>
                                                <SelectCmp title='Filtrar por gestor:' value={GestorId} OnChange={setGestorId}>
                                                    {e.gestoresId.map((a, i) => {
                                                      return (
                                                            <option value={a}>{e.gestoresNome[i]}</option>
                                                      )
                                                    })}
                                                </SelectCmp>
                                            </>
                                        }
                                    </Flex>
                                    <Flex gap={'1rem'}>
                                        <MediaAvaliacoesChartGestor informacoes={e} key={e.id}/>
                                    </Flex>
                                    <Divider mt={'.75rem'} mb={'1.5rem'} borderColor={'var(--c3)'} orientation='horizontal' />
                                </>
                          )
                        })}
                    </WhiteContainer>
                }
            </LoadingContainer>

            {
                (Model?.pesquisas && Model?.pesquisas?.length === 0 && !IsLoading) && (
                    <ImgSah entidade='pesquisa'>
                        {
                            (parseJwt().adm === 'True') && (
                                <ButtonCmp
                                    VarColor='Green2'
                                    size={'lg'}
                                    onClick={() => nav('/Desempenho/AnaliseDesempenho/SelecionarModelo')}
                                >Criar nova pesquisa</ButtonCmp>
                            )
                        }
                    </ImgSah>
                )
            }
        </Body>
  )
}
