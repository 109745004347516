import { useNavigate } from 'react-router-dom'
import { Flex, Img, Text, useToast } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { Body } from '../../../../Layouts/Body'
import { CardModelo } from './Components/CardModelo'
import { FaArrowCircleRight, FaFolderPlus, FaGlobe, FaMugHot, FaSpaceShuttle } from 'react-icons/fa'
import { appApi } from '../../../../../services/appApi'
import { useEffect, useState } from 'react'
import { TimelineCriarPEC } from '../../Components/Timeline'

interface iModelProps {
  ultimaPesquisa: boolean
  modalPesquisas: boolean
}

export const SelecionarModeloEngajamento: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const [Model, setModel] = useState<iModelProps>()

  function SelecionarModelo(id?: string, cultura?: number, duplicar?: boolean): void {
    appApi.get(`Pec/Criar/Rota?id=${id ?? ''}&cultura=${cultura ?? ''}&duplicar=${duplicar ?? ''}`)
      .then(res => {
        nav(res.data)
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Ocorreu um erro para criar a pesquisa, tente novamente mais tarde',
          duration: 4000,
          status: 'error'
        })
      })
  }

  function GetModalInfos(): void {
    appApi.get('Pec/SelecionarModelo/Infos')
      .then(res => {
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetModalInfos()
  }, [])

  return (
    <Body>
      <TimelineCriarPEC color={['var(--Azul)']}/>
      <Flex justify={'space-between'}>
          <h1>Criar pesquisa</h1>
          <Flex gap={'.75rem'}>
            <ButtonCmp
              VarColor={'c6'}
              onClick={() => nav(-1)}
            >Voltar</ButtonCmp>
            <ButtonCmp
              VarColor={'Green2'}
              onClick={() => nav(-1)}
            >Lançar resultados de anos anteriores</ButtonCmp>
          </Flex>
      </Flex>
      <Flex flexDir={'column'} gap={'1.5rem'} mt={'1rem'}>
        <Flex gap={'1rem'} w={'100%'}>
          <CardModelo
            icon={<FaArrowCircleRight size={33}/>}
            color='Green2'
            titulo='Criar pesquisa do zero'
            descricao='O formulário começará vazio. Você ainda terá acesso a todo nosso banco de questões.'
            onSelect={() => SelecionarModelo()}
          />
          {Model?.ultimaPesquisa && <CardModelo
            icon={<Img src='https://sigah.app/css/InconePEC.svg' w={'2rem'} h='2rem'/>}
            color='Green2'
            titulo='Questões da ultima pesquisa de engajamento'
            descricao='Questões usadas na última Pesquisa de Engajamento na sua organização'
            onSelect={() => SelecionarModelo(undefined, undefined, true)}
          />}
        </Flex>
        <Flex flexDir={'column'} gap={'.25rem'}>
          <h1>Carregar questões pré definidas</h1>
          <Text fontSize='.913rem' fontWeight={'400'}>Todo o nosso banco de questões foi definido por uma equipe com anos de experiencia na área. Vale resaltar que para todas as questões que você utilizar em nosso banco, você pode editá-las conforme quiser.</Text>
        </Flex>
        <Flex flexDir={'column'} gap={'1rem'}>
          <Flex gap={'1rem'} w={'100%'}>
            <CardModelo
              icon={<FaFolderPlus size={33}/>}
              color='Orange'
              titulo='100 questões mais utilizadas'
              descricao='Constam as 100 questões mais utilizadas pelas empresas no Brasil nos últimos 12 meses.'
              onSelect={() => SelecionarModelo(undefined, 6)}
            />
            <CardModelo
              icon={<FaSpaceShuttle size={33}/>}
              color='Rosa'
              titulo='Express'
              descricao='20 Questões sugeridas para uma versão de Pesquisa de Engajamento rápida (versão Express).'
              onSelect={() => SelecionarModelo(undefined, 4)}
            />
          </Flex>
          <Flex gap={'1rem'} w={'100%'}>
            <CardModelo
              icon={<FaGlobe size={33}/>}
              color='Roxo'
              titulo='Cultura da organização'
              descricao='20 Questões sugeridas para uma versão de Pesquisa de Engajamento rápida (versão Express), customizada de acordo com a Cultura da Organização.'
              onSelect={() => nav('/Engajamento/Diagnostico')}
            />
            <CardModelo
              icon={<FaMugHot size={33}/>}
              color='Red'
              titulo='Experiência dos colaboradores'
              descricao='Questões sugeridas para Pesquisa de Engajamento direcionada para a experiência das pessoas que atuam na organização (Employee Experience).'
              onSelect={() => SelecionarModelo(undefined, 7)}
            />
          </Flex>
        </Flex>
      </Flex>
    </Body>
  )
}
