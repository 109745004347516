import { Spinner } from '@chakra-ui/react'
import { Container, ContainerMax } from './styles'

interface iBodyProps{
  isLoading?: boolean
  style?: any
}

export const Body: React.FC<iBodyProps> = ({ isLoading, children, style }) => {
  return (
    <ContainerMax>
      {isLoading &&
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        height={'15rem'}
        width='15rem'
        position={'absolute'}
        top='50%'
      />
      }
      <Container style={style} isLoading={isLoading}>{children}</Container>
    </ContainerMax>
  )
}
