import { Flex, FormControl, FormLabel, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaBullhorn, FaTrash, FaUserPlus } from 'react-icons/fa'
import { FindUser } from '../../../../../../components/FindBox'
import { iDemografia, iNomeId } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { ModalBase } from '../../../../../../components/ModalBase'
import { ModalComunicao } from '../ModalComunicacao'
import { TableConvites } from '../Tabela'
import { ActionButton } from './styles'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { UsuarioGestaoConviteDto } from '../..'

interface iModalProps {
  isOpen: boolean
  rows: UsuarioGestaoConviteDto[]
  paramId: string
  Reload: () => void
  onClose: () => void
}
export const ModalConviteUsuario: React.FC<iModalProps> = ({ isOpen, rows, paramId, onClose, Reload }) => {
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Departamentos, setDepartamentos] = useState<iDemografia[]>([])
  const [Email, setEmail] = useState('')
  const [DepartamentoId, setDepartamentoId] = useState('')
  const toast = useToast()

  const [Selecionados, setSelecionados] = useState<string[]>([])

  const [ModalComunicacaoIsOpen, setModalComunicacaoIsOpen] = useState(false)

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function getDepartamentos(): void {
    appApi.get('Departamento/Select')
      .then(res => setDepartamentos(res.data))
      .catch(err => console.log(err))
  }

  function CadastrarPorEmail(): void {
    appApi.post(`Pec/${paramId}/AdicionarEmail`, { texto: Email })
      .then(Reload)
      .catch(err => console.log(err))
  }

  function ConvidarPorDepartamentoId(): void {
    appApi.post(`Pec/${paramId}/AdicionarTodosEmail?departamentoId=${DepartamentoId}`)
      .then(Reload)
      .catch(err => console.log(err))
  }

  function ConvidarTodosColaboradores(): void {
    appApi.post(`Pec/${paramId}/AdicionarTodosEmail`)
      .then(Reload)
      .catch(err => console.log(err))
  }

  function onCheck(email: string, all?: boolean): void {
    if (!PropInvalida(all)) {
      if (all) {
        setSelecionados(Selecionados.concat(rows.filter(r => !Selecionados.some(t => t === r.email)).map((e) => { return e.email })))
      } else {
        setSelecionados([])
      }
    } else {
      const copy = [...Selecionados]
      if (copy.find(e => e === email) === undefined) {
        copy.push(email)
        setSelecionados(copy)
      } else {
        setSelecionados([])
        const filtrado = copy.filter(e => e !== email)
        setSelecionados(filtrado)
      }
    }
  }

  function onDeleteConvites(): void {
    appApi.post(`Pec/${paramId}/DeletarConvitesEmail`, { list: Selecionados })
      .then(() => {
        Reload()
        setSelecionados([])
      })
      .catch(err => console.log(err))
  }

  function EnviarSelecionadosEmail(): void {
    appApi.post(`Pec/${paramId}/EnviarConvitesEmail`, { list: Selecionados })
      .then(() => {
        Reload()
        toast({
          status: 'success',
          position: 'top',
          title: 'Convites enviados com sucesso!',
          duration: 4000
        })
        onClose()
      })
      .catch(err => console.log(err))
  }

  function SelecionarUsuario(id: string): void {
    const colab = Colaboradores.find(e => e.id === id)
    if (colab) {
      setEmail(colab.email ?? '')
    }
  }

  useEffect(() => {
    getColaboradores()
    getDepartamentos()
  }, [])

  return (
    <ModalBase
      Titulo="Convite por usuário"
      Subtitulo="Utilize os filtros para selecionar apenas os convites que desejar. Você pode filtrar por área ou nome, selecionando os convites que desejar. Poderá também clicar na barra de seleção e adicionar todos os convites que estão aparecendo na lista a partir dos filtros realizados."
      isOpen={isOpen}
      onClose={onClose}
      Width='52rem'
    >
      <ModalComunicao
        isOpen={ModalComunicacaoIsOpen}
        pesquisaId={paramId}
        onClose={() => setModalComunicacaoIsOpen(false)}
      />
      <Flex p={'0 1rem 1rem 1rem'} flexDir={'column'} gap={'.75rem'}>
        <Flex width={'100%'} gap={'.5rem'} alignItems={'end'}>
          <FormControl>
            <SelectCmp title='Convite por área' value={DepartamentoId} OnChange={setDepartamentoId}>
              <option value={''}>Nenhum</option>
              {
                Departamentos.map((e, i) => {
                  return (
                    <option value={e.id} key={i}>{e.nome}</option>
                  )
                })
              }
            </SelectCmp>
          </FormControl>
          <ButtonCmp w={'12rem'} isDisabled={!DepartamentoId} onClick={ConvidarPorDepartamentoId} VarColor='Green2'>Adicionar usuários</ButtonCmp>
        </Flex>
        <Flex width={'100%'} gap={'.5rem'} alignItems={'end'}>
          <FormControl alignItems={'start'}>
            <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Pesquisar usuário</FormLabel>
            <FindUser placeholder='Digite um nome ou email' lista={Colaboradores} _onChange={(e) => setEmail(e)} onChoice={SelecionarUsuario} />
          </FormControl>
          <ButtonCmp w={'12rem'} VarColor='Green2' onClick={() => CadastrarPorEmail()}>Adicionar usuário/email</ButtonCmp>
        </Flex>
        <Flex margin={'.25rem 0rem'} justifyContent={'center'} gap={'1.5rem'}>
          <ActionButton theme={'var(--Rosa)'} onClick={() => setModalComunicacaoIsOpen(true)}>
            <FaBullhorn size={22} />
            <Flex flexDir={'column'}>
              <span>Adicionar comunicação</span>
              <small>Você pode adicionar textos, imagens e vídeos</small>
            </Flex>
          </ActionButton>
          <ActionButton onClick={ConvidarTodosColaboradores} theme={'var(--Azul)'}>
            <FaUserPlus size={22} />
            <Flex flexDir={'column'}>
              <span>Selecionar todos os colaboradores cadastrados</span>
            </Flex>
          </ActionButton>
        </Flex>

        {
          (rows !== undefined && rows.length > 0) && (
            <>
              <Flex fontWeight={'400'} justifyContent={'space-between'} alignItems={'center'}>
                <Text fontSize={'.875rem'} fontWeight={'700'}>Usuários de aplicativos selecionados</Text>
                <ButtonCmp leftIcon={<FaTrash />} VarColor={'Red'} onClick={onDeleteConvites}>Excluir convites selecionados</ButtonCmp>
              </Flex>
              <TableConvites
                isEdit
                rows={rows}
                onCheck={onCheck}
                selecionados={Selecionados}
                onSend={() => { }}
              />
            </>
          )
        }
      </Flex>
      <Flex padding={'0 1rem 1rem 0'} justifyContent={'end'} gap={'.5rem'}>
        <ButtonCmp VarColor='c5' onClick={onClose}>Voltar</ButtonCmp>
        <ButtonCmp VarColor='Green2' onClick={EnviarSelecionadosEmail}>Enviar convite para selecionados</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}
