import { Flex, FormControl, Img, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { appApi } from '../../../../../../services/appApi'
import { ModalBase } from '../../../../../../components/ModalBase'
import { FileInput } from './styles'
import { TextAreaCmp } from '../../../../../../components/TextareaCmp'

interface iModalComunicacao {
  isOpen: boolean
  pesquisaId: string
  onClose: () => void
}

interface iComunicao {
  id?: string
  urlVideo: string
  urlImagem: string
  conteudo: string
  pesquisaId: string
}

export const ModalComunicao: React.FC<iModalComunicacao> = ({ isOpen, pesquisaId, onClose }) => {
  const toast = useToast()

  const [Model, setModel] = useState<iComunicao>({ conteudo: '', urlImagem: '', urlVideo: '', pesquisaId: pesquisaId })
  const [Url, setUrl] = useState('')
  const [Descricao, setDescricao] = useState('')
  const [Name, setName] = useState('')
  const [isEdit, setisEdit] = useState(false)

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    if (Url !== undefined && Url.length > 0) {
      appApi.post(`UploadFile?url=${Url}`, formdata).then(resposnse => {
        setUrl(resposnse.data.uri)
        toast({
          title: 'Arquivo atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      }).catch(e => console.log(e))
    } else {
      appApi.post('UploadFile', formdata).then(resposnse => {
        setUrl(resposnse.data.uri)
        setName(resposnse.data.name)
        toast({
          title: 'Arquivo cadastrado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      }).catch(e => console.log(e))
    }
  }

  const getComunicao = (): void => {
    appApi.get(`Pec/${pesquisaId}/Comunicacao`).then(res => {
      if (res.status === 200) {
        setModel(res.data)
        setisEdit(true)
      }
    }).catch(err => console.log(err))
  }

  const PostComunicacao = (): void => {
    const form: iComunicao = {
      ...Model,
      urlImagem: Url ?? '',
      conteudo: Descricao ?? '',
      urlVideo: Model.urlVideo ?? ''
    }

    appApi.post(`Pec/${pesquisaId}/Comunicacao?comId=${form.id ?? ''}`, form)
      .then(() => {
        toast({
          title: isEdit ? 'Comunicação atualizada com sucesso' : 'Comunicação criada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getComunicao()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getComunicao()
  }, [])

  return (
    <ModalBase
      Titulo="Adicionar comunicação"
      isOpen={isOpen}
      onClose={onClose}
      Width='70rem'
      Subtitulo='Adicione uma imagem, vídeo ou texto para ser exibido no aplicativo com a pesquisa a ser realizada'
    >
      <Flex padding={'1rem'} flexDir={'column'} fontSize={'.875rem'}>
        {Model.urlImagem && <Flex flexDir={'column'} gap={'.325rem'} mb={'1rem'}>
            <Text fontSize={'14px'} fontWeight={700} lineHeight={'14px'}>Comunicação atual</Text>
            <span>Esta é a comunicação utilizada nesta pesquisa, caso deseje substituir basta adicionar outra abaixo</span>
            <Img src={Model.urlImagem} maxH={'13rem'} maxW={'15rem'} objectFit='contain' border={'2px solid var(--c4)'}/>
            <Text fontSize={'14px'}><strong>Conteúdo:</strong> {Model.conteudo}</Text>
          </Flex>}
        <Flex boxShadow={'var(--SombraBackground)'} flexDir={'column'} marginBottom={'1rem'} bg={'var(--c2)'} padding={'1rem'} borderRadius={'.25rem'}>
          <span>Selecione um arquivo de imagem (*.jpg ou *.png) ou de vídeo(*.mp4) com o vídeo de sensibilização (tamanho max. 30MB)</span>
          <FileInput>
            <input
              id='i-Com'
              type={'file'}
              onChange={AtualizarArquivo}
              style={{ display: Url.length > 0 ? 'none' : 'inherit', borderRadius: '.25rem' }}
            />
            {Url.length > 0 &&
              <Flex alignItems={'center'}>
                <label htmlFor='i-Com'>Trocar arquivo</label>
                <span>Arquivo selecionado: <strong>{Name}</strong></span>
              </Flex>
            }
          </FileInput>
        </Flex>
        <FormControl>
          <TextAreaCmp
            label='Digite o texto que será exibido na tela de início:'
            OnChange={setDescricao}
            value={Descricao}
          />
        </FormControl>
      </Flex>
      <Flex justifyContent={'end'} padding={'0 1rem 1rem 1rem'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={onClose}>Fechar</ButtonCmp>
        {
          isEdit
            ? <ButtonCmp onClick={PostComunicacao} VarColor='Green2'>Confirmar alterações e editar</ButtonCmp>
            : <ButtonCmp onClick={PostComunicacao} VarColor='Green2'>Adicionar</ButtonCmp>
        }
      </Flex>
    </ModalBase>
  )
}
