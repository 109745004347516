import { Divider, Flex, Img, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { FaKey } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { InputCmp } from '../../../../components/InputCmp'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'

export const DigitarChavePec: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const [CodigoA, setCodigoA] = useState('')
  const [CodigoB, setCodigoB] = useState('')

  function CheckCode(): void {
    if (CodigoA.length !== 6 || CodigoB.length !== 6) {
      toast({
        title: 'Código inválido',
        status: 'error',
        duration: 4000,
        position: 'top'
      })
      return
    }

    appApi.get(`ResponderPec/ChaveAcesso?codigoA=${CodigoA}&codigoB=${CodigoB}`)
      .then(res => {
        nav(`/Engajamento/Pec/f/${res.data}`)
      })
      .catch(err => {
        toast({
          title: 'Código inválido',
          status: 'error',
          duration: 4000,
          position: 'top'
        })
        console.log(err)
      })
  }

  return (
    <Body>
      <WhiteContainer margin='3rem 0'>
        <Img height={'16rem'} objectFit={'contain'} alt='Logo da empresa' src={'https://sigah.app/css/sigahpp.jpg'} />
        <Flex justifyContent={'center'} alignItems={'center'} flexDir={'column'} gap={'1rem'} mt={'3rem'} p={'2rem'}>
          <Flex gap={'.625rem'} color={'var(--Azul)'} fontWeight={'700'} alignItems={'center'} justifyContent={'center'} fontSize={'1.5rem'}>
            <FaKey/>
            <Text>
              Digite a chave da pesquisa
            </Text>
          </Flex>
          <Flex justifyContent={'center'} flexDir={'column'} alignItems={'center'} p={'1.25rem'} border={'1px solid var(--Azul)'} borderRadius={'.325rem'} gap={'.75rem'} w={'70%'}>
            <Flex gap={'.625rem'} alignItems={'center'}>
              <InputCmp OnChange={setCodigoA} value={CodigoA} width={'14rem'} placeholder={'Digite a primeira parte da senha'}/>
              <Divider width={'20px'} opacity={'1'} borderColor={'black'}/>
              <InputCmp OnChange={setCodigoB} value={CodigoB} width={'14rem'} placeholder={'Digite a segunda parte da senha'}/>
            </Flex>
            <ButtonCmp onClick={CheckCode} VarColor='Green2'>Responder pesquisa</ButtonCmp>
          </Flex>
        </Flex>
      </WhiteContainer>
    </Body>
  )
}
