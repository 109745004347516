/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
import Roboto from '../../../../../assets/fonts/Roboto-Bold.ttf'

import {
  Document,
  Font,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { iDemografiaPEC, iEscala, PerguntaPec } from '../../../../../interfaces'
import { Header } from './Components/Header'
import { Footer } from './Components/Footer'
import { ArrayToChunks } from '../../../../../Utils/Helper'

interface iModel {
  atributos: PerguntaPec[]
  demografias: iDemografiaPEC[]
  escala?: iEscala
  nomePesquisa: string
  nomeEmpresa: string
}

interface iPergunta {
  pergunta: string
  atributoId?: string
  perguntas?: PerguntaPec[]
  isDemografia: boolean
}

interface iProps {
  model: iModel
}

export const FormularioPreenchimentoPDF: React.FC<iProps> = ({
  model
}) => {
  const notasEnps: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [Model, setModel] = useState<iPergunta[]>([])
  Font.register({
    family: 'Roboto',
    fonts: [{
      src: Roboto
    }]
  })

  const styles = StyleSheet.create({
    DefaultPage: {
      paddingTop: 16,
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  })

  Font.registerHyphenationCallback((e) => {
    if (e.includes('http') && e.length > 100) {
      const arr: string[] = []
      for (let i = 0; i < e.length; i += 100) {
        arr.push(e.substring(i, i + 100))
      }
      return arr
    }

    if (e.includes('Flexibilidade/Adaptabilidade')) {
      return e.split('/')
    }

    if (e.includes('Autodesenvolvimento')) {
      return ['Autodesenvolvi', 'mento']
    }

    if (e.length === 1) {
      return [e, '']
    }
    return [e]
  })

  function GerarItens(): any {
    const arr: iPergunta[] = []

    model.demografias.filter(e => e.demografias && e.demografias?.length >= 22).forEach(e => {
      arr.push({
        pergunta: e.pergunta,
        perguntas: e.demografias?.map(a => { return { pergunta: a.nome } as PerguntaPec }),
        isDemografia: true
      })
      arr.push({
        pergunta: '',
        perguntas: [],
        isDemografia: true
      })
      arr.push({
        pergunta: '',
        perguntas: [],
        isDemografia: true
      })
      arr.push({
        pergunta: '',
        perguntas: [],
        isDemografia: true
      })
    })

    model.demografias.filter(e => e.demografias && (e.demografias?.length < 22 && e.demografias?.length >= 10)).forEach(e => {
      arr.push({
        pergunta: e.pergunta,
        perguntas: e.demografias?.map(a => { return { pergunta: a.nome } as PerguntaPec }),
        isDemografia: true
      })
      arr.push({
        pergunta: '',
        perguntas: [],
        isDemografia: true
      })
    })

    model.demografias.filter(e => e.demografias && e.demografias?.length < 10).forEach(e => {
      arr.push({
        pergunta: e.pergunta,
        perguntas: e.demografias?.map(a => { return { pergunta: a.nome } as PerguntaPec }),
        isDemografia: true
      })
    })

    model.atributos.forEach(e => {
      arr.push({ ...e, isDemografia: false, atributoId: e.atributoId })
    })

    setModel(arr)
  }

  useEffect(() => {
    GerarItens()
  }, [model])

  return (
    <Document>
      {ArrayToChunks(Model, 4).map(item => {
        return (
        <Page style={styles.DefaultPage}>
          <View>
            <Header
              texto={'Formulário'}
              titulo={`${model?.nomePesquisa}`}
            />
            <View style={{ paddingLeft: 32, paddingRight: 32, paddingVertical: 26 }}>
              <View style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                {item?.filter(e => e.atributoId !== 'ab2d00c8-67db-4477-9460-cf440a6964cb').filter(e => e.pergunta !== '').map(e => {
                  return (
                    <View style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <View style={{ display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center', marginBottom: '4px' }}>
                        {e.isDemografia ? <Svg stroke="#1961E0" fill="#1961E0" stroke-width="0" viewBox="0 0 544 512" color="#1961E0" height="12px" width="12px"><Path d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z"></Path></Svg> : <Text style={{ fontSize: '11px', fontFamily: 'Roboto' }}>{'>>'}</Text>}
                        <Text style={{ fontSize: '11px', fontFamily: 'Roboto' }}>{e.pergunta}</Text>
                      </View>
                      {e.isDemografia && e.perguntas?.map((perg: any) => {
                        return (
                          <View style={{ display: 'flex', gap: '4px', flexDirection: 'row' }}>
                            <View style={{ border: '1px solid #000000', borderRadius: '50%', width: '10px', height: '10px' }}></View>
                            <Text style={{ fontSize: '10px' }}>{perg.pergunta}</Text>
                          </View>
                        )
                      })}
                      {!e.isDemografia && !(e.atributoId === '4fd26649-b408-478d-b4a7-c797163f1a7a' || e.atributoId === 'a92f6984-e374-4bb5-a319-69ac12c3804f') && model.escala?.respostas.map(perg => {
                        return (
                          <View style={{ display: 'flex', gap: '4px', flexDirection: 'row' }}>
                            <View style={{ border: '1px solid #000000', borderRadius: '50%', width: '10px', height: '10px' }}></View>
                            <Text style={{ fontSize: '10px' }}>{perg.resposta}</Text>
                          </View>
                        )
                      })}
                      {!e.isDemografia && (e.atributoId === '4fd26649-b408-478d-b4a7-c797163f1a7a' || e.atributoId === 'a92f6984-e374-4bb5-a319-69ac12c3804f') && <View style={{ display: 'flex', gap: '6px', flexDirection: 'row' }}>
                        {notasEnps.map(nota => {
                          return (
                            <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
                              <View style={{ border: '1px solid #000000', borderRadius: '50%', width: '10px', height: '10px' }}></View>
                              <Text style={{ fontSize: '10px' }}>{nota}</Text>
                            </View>
                          )
                        })}
                      </View>}
                    </View>
                  )
                })}
                {item?.filter(e => e.atributoId === 'ab2d00c8-67db-4477-9460-cf440a6964cb').filter(e => e.pergunta !== '').map(e => {
                  return (
                    <View style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <View style={{ display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center', marginBottom: '4px' }}>
                        <Text style={{ fontSize: '11px', fontFamily: 'Roboto' }}>{'>>'}</Text>
                        <Text style={{ fontSize: '11px', fontFamily: 'Roboto' }}>{e.pergunta}</Text>
                      </View>
                      <View style={{ border: '1px solid #000', width: '100%', height: '120px' }}></View>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
          <Footer nomeEmpresa={model.nomeEmpresa}/>
        </Page>)
      })}
    </Document>
  )
}
