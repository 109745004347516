/* eslint-disable @typescript-eslint/no-unused-vars */
import Modal from 'react-modal'
import { Container, ModalBody, ModalHeader } from './styles'
import { MdClose } from 'react-icons/md'
import { FaCheckCircle, FaPlus } from 'react-icons/fa'
import { Box, Flex } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { iAtributo, iModalPadrao, PerguntaPec } from '../../../../../../interfaces'
import { ModalQuestao } from '../ModalQuestao'
import { Body } from '../../../../../Layouts/Body'

interface IModalProps extends iModalPadrao {
  questoes: PerguntaPec[]
  atributo: iAtributo
  isLoading: boolean
  CanCreate: boolean
  Selecionados: string[]
  SelecionarQuestao: (atributoId: string, questaoId?: string) => void
  CriarQuestao: (atributoId: string, texto: string) => void
}

export const ModalPreVizualizarQuestao: React.FC<IModalProps> = ({ OnCloseModal, isOpen, questoes, atributo, SelecionarQuestao, Selecionados, isLoading, CanCreate, CriarQuestao }) => {
  if (!isOpen) {
    return null
  }
  const [CriarIsOpen, setCriarIsOpen] = useState(false)

  const [Questoes, setQuestoes] = useState<PerguntaPec[]>([])

  useEffect(() => {
    setQuestoes(questoes)
  }, [questoes])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={OnCloseModal}
      overlayClassName='react-modal-overlay'
      className={'react-modal-content-scroll'}
    >
      <ModalQuestao
        competenciaId={atributo.id}
        OnCloseModal={() => setCriarIsOpen(false)}
        duplicar={false}
        isOpen={CriarIsOpen}
        CriarQuestao={CriarQuestao}
        UpdateQuestao={() => {}}
      />
      <Container>
        <Body style={{ padding: 0, margin: '0 !important' }} isLoading={isLoading}>
          <ModalHeader>
            <Flex alignItems={'center'} gap={'.5rem'}>
              <img src={atributo.avatar} />
              <h4>{atributo.nome}</h4>
            </Flex>
            <Box style={{ color: 'var(--Red)' }}>
              <MdClose
                onClick={() => OnCloseModal()}
                cursor={'pointer'}
                size={18}
              />
            </Box>
          </ModalHeader>

          <ModalBody>
            {
              Questoes.map((e: PerguntaPec, i) => {
                if (Selecionados?.find(r => r === e.id) !== undefined) {
                  return (
                    <div onClick={() => SelecionarQuestao(e.atributoId, e.id)} key={i}>
                      <FaCheckCircle color='var(--Azul)' size={18} />
                      <span>{e.pergunta}</span>
                    </div>
                  )
                }
                return (
                  <div onClick={() => SelecionarQuestao(e.atributoId, e.id)} key={i}>
                    <FaCheckCircle color='var(--c7)' size={18} />
                    <span>{e.pergunta}</span>
                  </div>
                )
              })
            }
          </ModalBody>

          <Flex gap={'.5rem'} justifyContent={'end'}>
            {CanCreate && <ButtonCmp VarColor='Rosa' onClick={() => setCriarIsOpen(true)} leftIcon={<FaPlus />}>Criar questão</ButtonCmp>}
            <ButtonCmp VarColor='Green2' onClick={() => OnCloseModal()}>Continuar</ButtonCmp>
          </Flex>
        </Body>
      </Container>
    </Modal>
  )
}
