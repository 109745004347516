import styled from 'styled-components'

export const TableDiv = styled.div`
display: flex;
justify-content: center;
width: 100%;
font-size: 1rem;
line-height: 2.5rem;
table, th, td {
  border: 0.063rem solid var(--c6);
  border-collapse: collapse;
  font-family: 'Roboto','sans-serif';
}

table{
    width: 100%;
}

th,td{
    padding: .25rem .5rem .25rem .5rem;
}

td{
    transition: 2s;
    font-weight: 400;
    text-align: center;
}

th{
    background: var(--Azul);
    color: var(--c1);
    font-weight: 500;
}

tr:nth-child(even) td {
    background-color: var(--c3)
}
`

export const StyledTd = styled.td`
    color: ${props => props.theme};
    font-weight: bold;
`
