import { Flex } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { iDemografiaPEC } from '../../interfaces'
import { ButtonCmp } from '../ButtonCmp'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: () => void
  onDelete?: (id: string) => void
  message: string
  center?: boolean
  Demografia?: iDemografiaPEC
}

export const ModalDelete: React.FC<props> = ({ isOpen, center, onRequestClose, onConfirm, Demografia, message, onDelete }) => {
  Modal.setAppElement('#root')

  function handleDelete(): void {
    if (onDelete) {
      onDelete(Demografia?.id ?? '')
    }
  }
  return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName='react-modal-overlay'
            className='react-modal-content-default'
        >
          <Flex flexDir={'column'} p={'.75rem'} w={'25rem'} gap={'.825rem'}>
            <Flex justifyContent={'space-between'}>
                <div></div>
                <h3 style={{ color: 'var(--Red)' }}>Atenção!</h3>
                <Flex alignItems={'center'} color={'var(--Red)'} _hover={{ color: 'var(--Red-hover)' }}>
                  <MdClose size={19} onClick={onRequestClose} cursor={'pointer'}/>
                </Flex>
            </Flex>

            <Flex flexDir={'column'} gap={'.825rem'} fontSize={'.875rem'} fontWeight={'600'}>
                <span style={{ textAlign: center ? 'center' : 'unset' }}>{message}</span>
                <Flex justifyContent={'center'} gap={'.5rem'}>
                    <ButtonCmp VarColor={'c5'} onClick={onRequestClose}>Cancelar</ButtonCmp>
                    <ButtonCmp VarColor='Green2' onClick={() => {
                      onConfirm()
                      onRequestClose()
                      if (Demografia) {
                        handleDelete()
                      }
                    }}>Continuar</ButtonCmp>
                </Flex>
            </Flex>
          </Flex>
        </Modal>
  )
}
