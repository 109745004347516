/* eslint-disable @typescript-eslint/no-invalid-void-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
import Roboto from '../../../../../../assets/fonts/Roboto-Bold.ttf'

import {
  Document,
  Font,
  G,
  Image as Img,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import React from 'react'
import { ArrayToChunks, formatarData, GroupBy, PropInvalida } from '../../../../../../Utils/Helper'
import { GraficoPEC, IModel } from '../../interfaces'
import { Header } from '../AnaliseEstatisticaPdf/Components/Header'
import { Footer } from '../AnaliseEstatisticaPdf/Components/Footer'
import { iAtributo } from '../../../../../../interfaces'

interface iProps {
  model: IModel
  atributos: iAtributo[]
  graficosPerguntasAtributo: Array<{ avatar: string, grafico: string, nome: string }>
  graficoMediaPerguntas: string
}

export const ComparativoPDF: React.FC<iProps> = ({
  model,
  atributos,
  graficosPerguntasAtributo,
  graficoMediaPerguntas
}) => {
  Font.register({
    family: 'Roboto',
    fonts: [{
      src: Roboto
    }]
  })

  const styles = StyleSheet.create({
    DefaultPage: {
      paddingTop: 16,
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    tableCol: {
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontFamily: 'Roboto',
      fontWeight: 'bold'
    },
    tableHeaderCol: {
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#1961E0'
    },
    tableCell: {
      margin: 'auto',
      fontSize: 10,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold'
    },
    tableCellCompetencia: {
      width: '340px',
      fontSize: 10,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      backgroundColor: '#1961E0',
      borderRight: '1px solid #000',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    tableCellPergunta: {
      width: '340px',
      fontSize: 8,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      backgroundColor: '#1961E015',
      borderRight: '1px solid #000',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    tableCellQuestao: {
      fontSize: 10,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      backgroundColor: '#1961E015',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })

  Font.registerHyphenationCallback((e) => {
    if (e.includes('http') && e.length > 100) {
      const arr: string[] = []
      for (let i = 0; i < e.length; i += 100) {
        arr.push(e.substring(i, i + 100))
      }
      return arr
    }

    if (e.includes('Viewibilidade/Adaptabilidade')) {
      return e.split('/')
    }

    if (e.includes('Autodesenvolvimento')) {
      return ['Autodesenvolvi', 'mento']
    }

    if (e.length === 1) {
      return [e, '']
    }
    return [e]
  })

  function ObterLogo(): string {
    const logo = localStorage.getItem('logo') ?? ''
    if (PropInvalida(logo)) {
      return 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'
    }

    if (logo.includes('https://blob-hml.icecloud.com.br') || logo.includes('https://blob.icecloud.com.br')) {
      return logo.replace('https://blob-hml.icecloud.com.br', 'https://sigah.blob.core.windows.net/sigah').replace('https://blob.icecloud.com.br', 'https://sigah.blob.core.windows.net/sigah')
    } else if (logo?.includes('https://sigah.arquiteturahumana.com.br')) {
      return logo.replace('https://sigah.arquiteturahumana.com.br', 'https://sigah.blob.core.windows.net/sigah')
    }
    return logo
  }

  function GetLength(demografia: GraficoPEC): number {
    let length = 0
    if (demografia.labels) {
      for (let i = 0; i < demografia.labels?.length; i++) {
        if ((demografia.serie && demografia.serie[i] >= 5)) {
          length++
        }
      }
    }
    return length
  }

  return (
    <Document>
      <Page style={{ backgroundColor: '#E9E9E9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
        <View style={{ display: 'flex', minHeight: '180px', maxHeight: '180px', flexDirection: 'column', paddingHorizontal: 42, paddingTop: 24, gap: '12px', alignItems: 'center' }}>
          <Img src={'https://sigah.blob.core.windows.net/onboard/icones/logo_ah_1 2_4b818f42-515f-4165-b395-8ddfa2d4a6e9.png'} style={{ maxWidth: '120px', objectFit: 'contain', maxHeight: '70px' }}/>
          <Text style={{ color: '#66046B', fontSize: 18 }}>Análise comparativa</Text>
          <Text style={{ color: '#66046B', fontSize: 24 }}>{model.pesquisa.descricao}</Text>
        </View>
        <View style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', paddingHorizontal: 42, paddingVertical: 30 }}>
          <Img src={'https://sigah.blob.core.windows.net/onboard/icones/Group 522_619b88f1-5bf2-49f2-9b7a-7c1137c4f43d.png'} style={{ width: '330px', objectFit: 'contain' }}/>
        </View>
        <View style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', position: 'relative', minHeight: '320px', maxHeight: '320px', paddingBottom: '42px', paddingRight: '30px' }}>
          <Img src={'https://sigah.blob.core.windows.net/onboard/icones/Clip path group_b9e6b36d-1d66-4154-b9da-b32c9f301ebe.png'} style={{ width: '100%', objectFit: 'cover', position: 'absolute', zIndex: 1, bottom: 0, right: 0 }}/>
          <Img src={ObterLogo()} style={{ height: '120px', maxWidth: '270px', objectFit: 'contain' }}/>
        </View>
      </Page>
      <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <Text
              style={{
                textAlign: 'left',
                fontFamily: 'Roboto',
                paddingVertical: 32,
                fontSize: 18,
                color: '#203864'
              }}
            >
              Comparativo de atributos em comum
            </Text>
            {atributos && ArrayToChunks(atributos, 4).map((linha, i) => {
              return (<View style={{ display: 'flex', flexDirection: 'row', gap: '12px', marginTop: i !== 0 ? '14px' : '' }}>
                {linha.map(item => {
                  return (
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px', width: '120px' }}>
                      <View style={{ border: '2px dashed #d3d3d3', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Img src={item.avatar} style={{ width: '100%', height: '100%' }}/>
                      </View>
                      <Text style={{ width: '120px', fontSize: '12px', textAlign: 'center' }}>{item.nome}</Text>
                    </View>
                  )
                })}
              </View>)
            })}
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Média dos atributos em comum
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Img src={graficoMediaPerguntas} style={{ minHeight: '330px', maxHeight: '330px', objectFit: 'contain' }}/>
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      {ArrayToChunks(graficosPerguntasAtributo, 2).map((page, i) => {
        return <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Média das perguntas por atributo
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '56px' }}>
            {page.map((e, index) => {
              return <View style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', minWidth: '250px' }}>
                  <View style={{ border: '2px dashed #d3d3d3', borderRadius: '50%', width: '52px', height: '52px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Img src={e.avatar} style={{ width: '100%', height: '100%' }}/>
                  </View>
                  <Text style={{ minWidth: '180px', fontSize: '11px', textAlign: 'center' }}>{e.nome}</Text>
                </View>
                <Img src={e.grafico} style={{ minHeight: '190px', maxHeight: '190px', objectFit: 'contain' }}/>
              </View>
            })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      })}
    </Document>
  )
}
