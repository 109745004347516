/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Menu } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { ModalPreVizualizarQuestao } from '../ModalPreVizualizarQuestoes'
import { ModalBody, ModalFooter, ImgVariant } from './styles'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { ModalBase } from '../../../../../../components/ModalBase'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { iAtributo, PerguntaPec } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { Body } from '../../../../../Layouts/Body'
import { ModalCriarAtributo } from '../ModalCriarCompetencia'

interface iProps {
  OnCloseModal: () => void
  isOpen: boolean
  atributos: iAtributo[]
  id?: string
  atributoId?: string
  personalizados?: boolean
  isLoading: boolean
  Selecionados: string[]
  SelecionarQuestao: (atributoId: string, questaoId?: string) => void
  RemoverAtributo: (atributoId: string) => void
  CriarQuestao: (atributoId: string, texto: string) => void
  CriarAtributo: (nome: string, avatar: string, questao: string) => void
}

interface iModel {
  nome: string
  atributos: iAtributo[]
}

export const ModalTodasPerguntasEngajamento: React.FC<iProps> = ({ OnCloseModal, isOpen, id, personalizados, atributos, Selecionados, SelecionarQuestao, isLoading, RemoverAtributo, atributoId, CriarQuestao, CriarAtributo }) => {
  const [TxtPesquisa, setTxtPesquisa] = useState('')
  const [CriarAtributoIsOpen, setCriarAtributoIsOpen] = useState(false)
  const [Model, setModel] = useState<iModel>({ atributos: [], nome: '' })
  const [Atributo, setAtributo] = useState<iAtributo>()
  const [Questoes, setQuestoes] = useState<PerguntaPec[]>([])

  const [PreviewisOpen, setPreviewisOpen] = useState(false)

  function orderByName(isDesc: boolean): void {
    const arr = [...Model.atributos]
    arr.sort((a, b) => {
      return a.nome.localeCompare(b.nome, 'pt-BR')
    })
    if (isDesc) arr.reverse()
    setModel({ ...Model, atributos: arr })
  }

  function GetModel(Id?: string, personalizados?: boolean): void {
    appApi.get(`PEC/Cluster/Item?pulse=false&id=${Id ?? ''}&personalizados=${personalizados ? 'true' : ''}&atributoId=${atributoId ?? ''}`)
      .then(res => {
        setModel(res.data); if (Atributo) {
          setQuestoes(res.data.atributos.find((e: iAtributo) => e.id === Atributo.id)?.perguntas ?? [])
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetModel(id, personalizados)
  }, [id, personalizados])

  useEffect(() => {
    if (!isLoading) {
      GetModel(id, personalizados)
    }
  }, [isLoading])

  return (
    <ModalBase
      isOpen={isOpen}
      Titulo={Model.nome}
      onClose={OnCloseModal}
      Width='75rem'
    >
      <ModalCriarAtributo
        OnCloseModal={() => setCriarAtributoIsOpen(false)}
        onCreate={CriarAtributo}
        isOpen={CriarAtributoIsOpen}
      />
      {Atributo && PreviewisOpen && <ModalPreVizualizarQuestao
        isOpen={PreviewisOpen}
        questoes={Questoes}
        atributo={Atributo}
        isLoading={isLoading}
        Selecionados={Selecionados}
        SelecionarQuestao={SelecionarQuestao}
        CanCreate={Atributo.nome !== 'NPS' && Atributo.nome !== 'eNPS'}
        OnCloseModal={() => { setQuestoes([]); setAtributo(undefined); setPreviewisOpen(false) }}
        CriarQuestao={(id, txt) => { CriarQuestao(id, txt) }}
      />}

      <Flex justifyContent={'space-between'} alignItems='end' mx={'.5rem'} mb={'.75rem'} mt={'0'}>
        <SelectCmp
          width={'18rem'}
          title='Ordenar por'
          OnChange={(e) => {
            if (e === '0') {
              orderByName(false)
            } else if (e === '1') {
              orderByName(true)
            }
          }}
        >
          <option value={'0'}>A-Z</option>
          <option value={'1'}>Z-A</option>
        </SelectCmp>

        <Flex>
          <InputCmp
            position={'inherit'}
            value={TxtPesquisa}
            OnChange={setTxtPesquisa}
            placeholder='Buscar Atributos'
            width={'17rem'}
          />
        </Flex>
      </Flex>

      <ModalBody>
        <Body isLoading={isLoading}>
          <section>
            {
              Model.atributos?.filter((e: iAtributo) => {
                if (TxtPesquisa === '') {
                  return e
                } else if (e.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                  return e
                }
                return null
              }).map((e: iAtributo, i) => {
                return (
                  <Flex key={i} className='VariantColor' justifyContent={'space-between'}>
                    <Flex>
                      <ImgVariant borderColor={e.empresaId ? 'Azul' : 'AzulOpaco'} src={e.avatar} />
                      <Flex flexDirection={'column'}>
                        <h4>{e.nome}</h4>
                        <small>{e.empresaId ? e.dataCriado : 'Padrão'}</small>
                      </Flex>
                    </Flex>

                    <Flex alignItems={'center'} gap={'.5rem'}>
                      <ButtonCmp
                        OutlineColor={'Rosa'}
                        small
                        onClick={() => { setQuestoes(e.perguntas ?? []); setAtributo(e); setPreviewisOpen(true) }} leftIcon={<FaEye
                          />}>Pré-visualizar questões</ButtonCmp>
                      {
                        atributos.find(r => r.id === e.id) === undefined
                          ? <ButtonCmp w={'5.5rem'} VarColor='Green2' onClick={() => SelecionarQuestao(e.id)}>Adicionar</ButtonCmp>
                          : <ButtonCmp w={'5.5rem'} OutlineColor='Red' onClick={() => RemoverAtributo(e.id)}>Remover</ButtonCmp>
                      }
                      {/* <Flex w={'1.5rem'} justifyContent={'start'}>
                        {
                          (!e.empresaId) && (
                            <Flex justifyContent={'end'}>
                              <Menu>
                                <StyledMenuButton pl={'0'} color={'black'} _hover={{ bg: 'none' }} _active={{ bg: 'none' }} _focus={{ bg: 'none' }}>
                                  <BsThreeDotsVertical size={14}/>
                                </StyledMenuButton>
                                <StyledMenuList>
                                  <StyledMenuItem
                                    onClick={() => {
                                      setEditisOpen(true)
                                      setCompetenciaEditId(e.id)
                                    }}>
                                    <FaEdit style={{ marginRight: '.4rem' }} />
                                    Editar
                                  </StyledMenuItem>
                                  <StyledMenuItem
                                    onClick={(() => { DeletarCompetencia(e.id) })}
                                  >
                                    <FaTrash style={{ marginRight: '.4rem' }} />
                                    Excluir
                                  </StyledMenuItem>
                                </StyledMenuList>
                              </Menu>
                          </Flex>
                          // <Flex position={'relative'} justifyContent={'end'}>
                          //   <Menu>
                          //     <FaEllipsisV />
                          //     <Flex width={'6rem'}>
                          //       <DropMenu>
                          //         <li onClick={() => {
                          //           setEditisOpen(true)
                          //           setCompetenciaEditId(e.id)
                          //         }} style={{ color: 'var(--terc10)' }}><FaEdit /> Editar</li>
                          //         <li onClick={(() => { DeletarCompetencia(e.id) })} style={{ color: 'var(--terc3)' }}><FaTrash /> Excluir</li>
                          //       </DropMenu>
                          //     </Flex>
                          //   </Menu>
                          // </Flex>
                          )
                        }
                      </Flex> */}
                    </Flex>
                  </Flex>
                )
              })
            }
          </section>
        </Body>
      </ModalBody>
      <ModalFooter>
        {Model.nome === 'Atributos personalizados' && <ButtonCmp VarColor='Rosa' onClick={() => setCriarAtributoIsOpen(true)}>Criar atributo</ButtonCmp>}
        <ButtonCmp VarColor='Green2' onClick={() => OnCloseModal()}>Continuar</ButtonCmp>
      </ModalFooter>
    </ModalBase>
  )
}
