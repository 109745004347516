import { Flex, FormControl } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../../components/ModalBase'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { iAtributo, iRespostaEscala, PerguntaPec } from '../../../../../../interfaces'

interface iModalProps {
  isOpen: boolean
  questaoAVincular: string
  jaVinculadas: string[]
  formulario: iAtributo[]
  selecionadas: string[]
  questoesEscala: iRespostaEscala[]
  onClose: () => void
  onConfirm: (questaoId: string, respEscalaId: string) => void
}
export const ModalVinculoQuestao: React.FC<iModalProps> = ({ isOpen, onClose, formulario, selecionadas, questoesEscala, onConfirm, questaoAVincular, jaVinculadas }) => {
  const [QuestaoSelecionada, setQuestaoSelecionada] = useState('')
  const [questoes, setQuestoes] = useState<PerguntaPec[]>([])
  const [RespostaEscalaSelecionada, setRespostaEscalaSelecionada] = useState('')

  useEffect(() => {
    if (formulario && formulario.length > 0) {
      const resp: PerguntaPec[] = []
      formulario.forEach(e => {
        e.perguntas?.filter(p => selecionadas.includes(p.id) && e.id !== 'b067640d-dfe0-475f-9a5c-8c7541efede1' && e.id !== 'a92f6984-e374-4bb5-a319-69ac12c3804f' && e.id !== '4fd26649-b408-478d-b4a7-c797163f1a7a' && e.id !== 'ab2d00c8-67db-4477-9460-cf440a6964cb').forEach(p => {
          if (!p.nomeDemografia && p.id !== questaoAVincular && !jaVinculadas.includes(p.id)) {
            resp.push(p)
          }
        })
      })
      setQuestoes(resp)
    }
  }, [formulario])

  useEffect(() => {
    if (questoes.length) {
      setQuestaoSelecionada(questoes[0].id)
    }
  }, [questoes])

  useEffect(() => {
    if (questoesEscala.length) {
      setRespostaEscalaSelecionada(questoesEscala[0].id)
    }
  }, [questoesEscala])

  return (
        <ModalBase
            Titulo={'Vincular questão'}
            onClose={onClose}
            isOpen={isOpen}
            Width='60rem'
        >
            <Flex flexDirection={'column'} padding={'1rem'} borderBottom={'1px solid var(--c4)'}>
              <FormControl>
                  <SelectCmp OnChange={setQuestaoSelecionada} value={QuestaoSelecionada} title='Selecione a questão'>
                    {questoes.map(e => {
                      return (<option value={e.id}>{e.pergunta}</option>)
                    })}
                  </SelectCmp>
              </FormControl>

              <FormControl mt={'.75rem'}>
                <SelectCmp OnChange={setRespostaEscalaSelecionada} value={RespostaEscalaSelecionada} title='Selecione a resposta. Obs: Todas as respostas abaixo da escolhida também serão atreladas'>
                  {questoesEscala.filter(e => e.peso !== -1).map(e => {
                    return (<option value={e.id}>{e.resposta}</option>)
                  })}
                </SelectCmp>
              </FormControl>
            </Flex>
            <Flex justifyContent={'end'} padding={'1rem'} gap={'.5rem'}>
              <ButtonCmp VarColor='c6' onClick={onClose}>Cancelar</ButtonCmp>
              <ButtonCmp
                  VarColor='Green2'
                  disabled={questoes.length === 0}
                  onClick={() => onConfirm(QuestaoSelecionada, RespostaEscalaSelecionada)}
              >Salvar</ButtonCmp>
            </Flex>
        </ModalBase>
  )
}
