/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'
import { GraficoPEC } from '../../interfaces'

interface IGrafico {
  id: string
  dados: GraficoPEC
}

export const BarChartComBorda: React.FC<IGrafico> = ({ id, dados }) => {
  function GetSeries(): any {
    const resp: any[] = []
    dados.serie?.forEach(e => {
      resp.push(e)
    })

    return [{
      name: 'Média do engajamento',
      data: resp.map((e, i) => {
        return {
          strokeColor: (dados.afetaEngajamento[i]) ? 'rgb(3, 112, 21)' : undefined,
          y: e,
          x: dados.labels?.[i]
        }
      })
    }]
  }
  async function GerarGrafico(): Promise<void> {
    const options = {
      colors: ['rgb(56, 99, 176)', 'rgb(3, 112, 21)', 'rgba(55, 159, 232, 0.5)'],
      series: GetSeries(),
      chart: {
        type: 'bar',
        height: 450,
        width: '100%'
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          if (val === -1) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Média do engajamento', 'Atributos que afetaram o engajamento', 'Média dos demais atributos'],
        position: 'top',
        fontSize: '16px',
        itemMargin: {
          horizontal: 10
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: 10,
        tickAmount: 10,
        show: true
      },
      xaxis: {
        categories: dados.labels,
        labels: {
          hideOverlappingLabels: false,
          trim: true
        }
      },
      fill: {
        opacity: 1,
        colors: dados.serie?.map((e, i) => {
          if (i === 0) {
            return 'rgb(56, 99, 176)'
          } else {
            return 'rgba(55, 159, 232, 0.5)'
          }
        })
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [dados])

  return (
        <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}
