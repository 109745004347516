/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { ModalPreVizualizarQuestao } from './Components/ModalPreVizualizarQuestoes'
import { ModalQuestao } from './Components/ModalQuestao'
// import { ModalCriarCompetencia } from './Components/ModalCriarCompetencia'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ModalDelete } from '../../../../components/ModalDelete'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iAtributo, iClusterPesquisa, iDemografiaPEC, iEscala, PerguntaPec } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { TimelineCriarPEC } from '../Components/Timeline'
import { ClusterCard } from './Components/ClusterCard'
import { FaCheckCircle, FaFolderPlus } from 'react-icons/fa'
import { AtributoEngajamento } from './Components/PerguntaEngajamento'
import { ModalTodasPerguntasEngajamento } from './Components/ModalTodasPerguntasEngajamento'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CompetenciaContent, CompetenciaLine, CompetenciaMenu, Container, ImgText, StyledQuestion } from './Components/PerguntaEngajamento/styles'
import { FindAtributo } from './Components/FindAtributo'
import { ModalVinculoQuestao } from './Components/ModalVinculoQuestao'

export const FormularioPesquisaPEC: React.FC = () => {
  const { pesquisaId } = useParams()
  const { pathname } = useLocation()
  const nav = useNavigate()

  const [Questoes, setQuestoes] = useState<PerguntaPec[]>([])
  const [AtributoId, setAtributoId] = useState<string>()
  const [VincularQuestaoId, setVincularQuestaoId] = useState<string>()
  const [JaVinculdas, setJaVinculdas] = useState<string[]>()
  const [VinculoId, setVinculoId] = useState<string>()
  const [TodosAtributos, setTodosAtributos] = useState<iAtributo[]>([])
  const [QuestaoOpcional, setQuestaoOpcional] = useState<iAtributo>()
  const [QuestaoOpcionalIsOpen, setQuestaoOpcionalIsOpen] = useState(false)
  const [ClusterId, setClusterId] = useState<string>()
  const [Personalizados, setPersonalizados] = useState<boolean>()
  const [PreVisualizarisOpen, setPreVisualizarisOpen] = useState(false)
  const [QuestaoIsOpen, setQuestaoIsOpen] = useState(false)
  const [IsLoadingOutros, setIsLoadingOutros] = useState(false)
  const [ModalVincularIsOpen, setModalVincularIsOpen] = useState(false)
  const [Clusters, setClusters] = useState<iClusterPesquisa[]>([])
  const [PerguntasEngajamento, setPerguntasEngajamento] = useState<iAtributo[]>([])
  const [Selecionadas, setSelecionadas] = useState<string[]>([])
  const [DemografiaArea, setDemografiaArea] = useState<iDemografiaPEC>()
  const [EscalaPesquisa, setEscalaPesquisa] = useState<iEscala>()

  const [ModalAtributosIsOpen, setModalAtributosIsOpen] = useState(false)
  const [CriarCompetisOpen, setCriarCompetisOpen] = useState(false)

  const [DeleteQuestaoIsOpen, setDeleteQuestaoIsOpen] = useState(false)
  const [DeleteCompetenciaIsOpen, setDeleteCompetenciaIsOpen] = useState(false)

  const [CompetenciaId, setCompetenciaId] = useState<string>()
  const [QuestaoId, setQuestaoId] = useState<string>()
  const [Duplicar, setDuplicar] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)

  function DeletarQuestao(): void {
    setIsLoadingOutros(true)
    appApi.delete(`Pec/${pesquisaId}/Atributo/${CompetenciaId ?? ''}/Questao/${QuestaoId ?? ''}`)
      .then(() => { GetFormulario(); GetSelecionadas() })
      .catch(err => console.log(err))
      .finally(() => { setIsLoadingOutros(false); onCloseModalDelete() })
  }

  function DeletarCompetencia(): void {
    setIsLoadingOutros(true)
    appApi.delete(`Competencia/${CompetenciaId ?? ''}`)
      .then(() => {})
      .catch(err => console.log(err))
      .finally(() => setIsLoadingOutros(false))
  }

  function onOpenModalEditCompetencia(id: string): void {
    setCompetenciaId(id)
    setCriarCompetisOpen(true)
  }

  function onOpenModalQuestao(competenciaId: string, questaoId?: string, duplicar?: boolean): void {
    setQuestaoIsOpen(true)
    setCompetenciaId(competenciaId)
    if (questaoId) {
      setQuestaoId(questaoId)
    }

    if (duplicar) {
      setDuplicar(true)
    }
  }

  function GetCluster(): void {
    appApi.get('PEC/Cluster')
      .then((res) => setClusters(res.data))
      .catch((err) => console.log(err))
  }

  function GetSelecionadas(): void {
    appApi.get(`PEC/Formulario/${pesquisaId}/Selecionadas`)
      .then((res) => setSelecionadas(res.data))
      .catch((err) => console.log(err))
  }

  function GetFormulario(): void {
    setIsLoading(true)
    appApi.get(`PEC/Formulario/${pesquisaId}`)
      .then((res) => setPerguntasEngajamento(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function selecionarAtributo(id: string, perguntaId?: string): void {
    appApi.patch(`PEC/Formulario/${pesquisaId}/Atributo/${id}?perguntaId=${perguntaId ?? ''}`)
      .then(() => {
        GetSelecionadas()
        GetFormulario()
      })
      .catch((err) => console.log(err))
  }

  function RemoverAtributo(id: string): void {
    appApi.delete(`PEC/Formulario/${pesquisaId}/Atributo/${id}`)
      .then(() => {
        GetSelecionadas()
        GetFormulario()
      })
      .catch((err) => console.log(err))
  }

  function GetDemografiaArea(): void {
    appApi.get(`PEC/${pesquisaId}/DemografiaArea`)
      .then((res) => setDemografiaArea(res.data))
      .catch(err => console.log(err))
  }

  function VincularDemografia(demografiaId: string, atributoId: string, perguntaId: string): void {
    setIsLoadingOutros(true)
    appApi.put(`PEC/${pesquisaId}/Demografia?demId=${demografiaId}&atributoId=${atributoId}&perguntaId=${perguntaId}`)
      .then(() => GetFormulario())
      .catch(err => { console.log(err) })
      .finally(() => setIsLoadingOutros(false))
  }

  function OnVincularQuestao(QuestaoVinculadaId: string, questaoId: string, respEscalaId: string, vinculoId?: string): void {
    setIsLoadingOutros(true)
    appApi.put(`PEC/${pesquisaId}/VincularQuestao/${QuestaoVinculadaId}?questaoId=${questaoId}&repEscalaId=${respEscalaId}&vinculoId=${vinculoId ?? ''}`)
      .then(() => GetFormulario())
      .catch(err => { console.log(err) })
      .finally(() => { setIsLoadingOutros(false); setVincularQuestaoId(undefined); setVinculoId(undefined) })
  }

  function OnRemoverVinculoQuestao(QuestaoVinculadaId: string): void {
    setIsLoadingOutros(true)
    appApi.delete(`PEC/${pesquisaId}/VincularQuestao/${QuestaoVinculadaId}`)
      .then(() => GetFormulario())
      .catch(err => { console.log(err) })
      .finally(() => { setIsLoadingOutros(false); setVincularQuestaoId(undefined); setVinculoId(undefined) })
  }

  function GetEscalaPesquisa(): void {
    appApi.get(`PEC/${pesquisaId}/Escala`)
      .then((res) => setEscalaPesquisa(res.data))
      .catch((err) => console.log(err))
  }

  function GetMaisUtilizadas(): void {
    setIsLoadingOutros(true)
    appApi.get('PEC/Cluster/MaisUtilizadas')
      .then((res) => setQuestoes(res.data))
      .catch((err) => console.log(err))
      .finally(() => { setIsLoadingOutros(false) })
  }

  function GetTodosAtributos(): void {
    appApi.get('PEC/Cluster/Atributos')
      .then((res) => setTodosAtributos(res.data))
      .catch((err) => console.log(err))
  }

  function onCloseModalQuestao(): void {
    setQuestaoIsOpen(false)
    setCompetenciaId(undefined)
    setQuestaoId(undefined)
    setDuplicar(false)
  }

  function onOpenModalDelete(competenciaId: string, questaoId?: string): void {
    setDeleteQuestaoIsOpen(true)
    setCompetenciaId(competenciaId)
    setQuestaoId(questaoId)
  }

  function CriarQuestao(compId: string, texto: string): void {
    setIsLoadingOutros(true)
    appApi.post(`Pec/${pesquisaId}/Atributo/${compId}`, { texto: texto })
      .then(() => { GetFormulario(); GetSelecionadas() })
      .catch(err => console.log(err))
      .finally(() => { setIsLoadingOutros(false) })
  }

  function OpenQuestoesOpcionais(id: string): void {
    appApi.get(`Pec/Atributo/${id}`)
      .then(res => setQuestaoOpcional(res.data))
      .catch(err => console.log(err))
    setQuestaoOpcionalIsOpen(true)
  }

  function UpdateQuestao(compId: string, perguntaId: string, texto: string): void {
    setIsLoadingOutros(true)
    appApi.patch(`Pec/${pesquisaId}/Atributo/${compId}?perguntaId=${perguntaId}`, { texto: texto })
      .then(() => { GetFormulario(); GetSelecionadas() })
      .catch(err => console.log(err))
      .finally(() => { setIsLoadingOutros(false) })
  }

  function CriarAtributo(nome: string, avatar: string, questao: string): void {
    appApi.post(`PEC/${pesquisaId}/Atributo`, { nome: nome, avatar: avatar, questao: questao })
      .then(() => {
        GetSelecionadas()
        GetFormulario()
      })
      .catch(err => console.log(err))
  }

  function onCloseModalDelete(): void {
    setDeleteQuestaoIsOpen(false)
    setCompetenciaId(undefined)
    setQuestaoId(undefined)
    setDeleteCompetenciaIsOpen(false)
  }

  useEffect(() => {
    GetCluster()
    GetTodosAtributos()
    GetFormulario()
    GetDemografiaArea()
    GetSelecionadas()
    GetEscalaPesquisa()
    GetMaisUtilizadas()
  }, [])

  useEffect(() => {
    if (VincularQuestaoId) {
      setModalVincularIsOpen(true)
    }
  }, [VincularQuestaoId])

  return (
    <Body isLoading={IsLoading || IsLoadingOutros}>
      <ModalDelete
        isOpen={DeleteCompetenciaIsOpen}
        message='Deseja realmente excluir esta competência?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarCompetencia}
      />

      <ModalDelete
        isOpen={DeleteQuestaoIsOpen}
        message='Deseja realmente excluir esta questão?'
        center
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarQuestao}
      />

      {
        (ModalAtributosIsOpen) && (
          <ModalTodasPerguntasEngajamento
            isOpen={ModalAtributosIsOpen}
            OnCloseModal={() => { setModalAtributosIsOpen(false); setClusterId(undefined); setPersonalizados(undefined) }}
            id={ClusterId}
            isLoading={IsLoading}
            personalizados={Personalizados}
            atributoId={AtributoId}
            RemoverAtributo={RemoverAtributo}
            Selecionados={Selecionadas}
            CriarAtributo={CriarAtributo}
            SelecionarQuestao={selecionarAtributo}
            atributos={PerguntasEngajamento}
            CriarQuestao={CriarQuestao}
          />
        )
      }

      <ModalPreVizualizarQuestao
        questoes={Questoes}
        atributo={{ avatar: 'https://sigah.app/img/icone_cem_mais_utilizadas.png', dataCriado: '', nome: 'Questões mais utilizadas', pulse: false, id: '' }}
        Selecionados={Selecionadas}
        isLoading={IsLoading}
        SelecionarQuestao={selecionarAtributo}
        CanCreate={false}
        OnCloseModal={() => setPreVisualizarisOpen(false)}
        CriarQuestao={() => {}}
        isOpen={PreVisualizarisOpen}
      />

      {QuestaoOpcional && QuestaoOpcionalIsOpen && <ModalPreVizualizarQuestao
        questoes={QuestaoOpcional?.perguntas ?? []}
        atributo={QuestaoOpcional}
        Selecionados={Selecionadas}
        isLoading={IsLoading}
        SelecionarQuestao={selecionarAtributo}
        CanCreate={false}
        OnCloseModal={() => { setQuestaoOpcionalIsOpen(false); setQuestaoOpcional(undefined) }}
        CriarQuestao={() => {}}
        isOpen={QuestaoOpcionalIsOpen}
      />}

      <ModalQuestao
        competenciaId={CompetenciaId ?? ''}
        isOpen={QuestaoIsOpen}
        OnCloseModal={onCloseModalQuestao}
        questaoId={QuestaoId}
        duplicar={Duplicar}
        CriarQuestao={CriarQuestao}
        UpdateQuestao={UpdateQuestao}
      />

      {/* <ModalCriarCompetencia
        OnCloseModal={onCloseModalCreateCompetencia}
        isOpen={CriarCompetisOpen}
        CompetenciaId={CompetenciaId}
      /> */}
      {VincularQuestaoId && JaVinculdas && <ModalVinculoQuestao
        isOpen={ModalVincularIsOpen}
        questaoAVincular={VincularQuestaoId}
        selecionadas={Selecionadas}
        jaVinculadas={JaVinculdas}
        onConfirm={(questaoId, respEscalaId) => { OnVincularQuestao(VincularQuestaoId, questaoId, respEscalaId, VinculoId) }}
        onClose={() => { setModalVincularIsOpen(false); setVincularQuestaoId(undefined); setVinculoId(undefined) }}
        formulario={PerguntasEngajamento}
        questoesEscala={EscalaPesquisa?.respostas ?? []}
      />}

      <TimelineCriarPEC color={['var(--Azul)', 'var(--Azul)', '.', '.']}/>

      <Flex justifyContent={'space-between'} alignItems={'end'}>
        <Flex flexDir={'column'}>
          <Flex alignItems={'center'} flexWrap={'wrap'}>
            <h2>Selecione os atributos</h2>
          </Flex>
          <span style={{ fontWeight: 'bold', fontSize: '.813rem', color: 'var(--c7)' }}>Escolha os atributos que deseja, relacionados aos temas abaixo e obtenha as questões mais adequadas</span>
        </Flex>
        <div>
          <FindAtributo
            lista={TodosAtributos}
            placeholder='Buscar atributo'
            minW={'15rem'}
            onRemove={(e) => RemoverAtributo(e)}
            onChoice={(e) => selecionarAtributo(e)}
            selecionados={PerguntasEngajamento}
          />
        </div>
      </Flex>

      <Grid gridTemplateColumns={'1fr 1fr 1fr'} w={'100%'} gap={'1rem'} my={'1rem'} position='relative'>
        {Clusters.map((e, i) => {
          return (
            <ClusterCard
              OnAction={() => { setModalAtributosIsOpen(true); setClusterId(e.id); setAtributoId(undefined) }}
              color={i === 0 ? 'Green2' : i === 1 ? 'Azul' : 'Orange'}
              model={e}
            />
          )
        })}
        <GridItem colSpan={3} display={'grid'} gridTemplateColumns={'1fr 1fr'} w={'100%'} gap={'1rem'} position='relative'>
          <ClusterCard
            OnAction={() => { setModalAtributosIsOpen(true); setClusterId(undefined); setPersonalizados(true); setAtributoId(undefined) }}
            color='Roxo'
            customName='Atributos personalizados'
            model={{ avatar: 'https://sigah.app/img/icone_atb_personalizado.png', nome: 'Atributos personalizados', pulse: false }}
          />
          <ClusterCard
            OnAction={() => { setModalAtributosIsOpen(true); setClusterId(undefined); setPersonalizados(undefined); setAtributoId(undefined) }}
            color='Rosa'
            customName='Lista com todos os atributos'
            model={{ avatar: 'https://sigah.app/img/icone_atb_personalizado.png', nome: 'Atributos personalizados', pulse: false }}
          />
        </GridItem>
      </Grid>
      <Flex justifyContent={'end'}>
        <ButtonCmp VarColor='Green2' leftIcon={<FaFolderPlus/>} onClick={() => setPreVisualizarisOpen(true)}>Visualizar as 100 questões mais utilizadas</ButtonCmp>
      </Flex>

      {/* <WhiteContainer margin='.75rem 0rem .75rem 0rem'>
        <HeadBox>
          <h4 style={{ fontWeight: 700 }}>Competências mais utilizadas em sua organização</h4>
          <Flex flexWrap={'wrap'} gap={'.75rem'}>
            <ButtonCmp
              onClick={() => setModalAtributosIsOpen(true)} VarColor={'Rosa'} leftIcon={<FaFolderOpen/>}>Todas as competências</ButtonCmp>
            <ButtonCmp
              onClick={() => setCriarCompetisOpen(true)} VarColor='Green2' leftIcon={<FaPlus
              />}>Criar nova competência</ButtonCmp>
          </Flex>
        </HeadBox>
        <Grid templateColumns={'repeat(7,1fr)'} gap={'.5rem'}>
          {
            PerguntasEngajamento.sort((a, b) => { return (b.quantidadeUsos ?? 0) - (a.quantidadeUsos ?? 0) }).filter(e => !e.discursiva).map((e: iAtributo, i) => {
              if (i > 6) return null
              return (
                <CardCompetencia
                  key={i}
                  Competencia={e}
                  Selecionado={PerguntasEngajamento.find(c => c.id === e.id) !== undefined}
                  Edit={() => { setCompetenciaId(e.id); setCriarCompetisOpen(true) }}
                  onDeleteCompetencia={onOpendModalDeleteCompetencia}
                  onClickPreVisualizar={() => {
                    setCompetenciaId(e.id)
                    setPreVisualizarisOpen(true)
                  }}
                />
              )
            })
          }
        </Grid>
      </WhiteContainer> */}
      {
        (PerguntasEngajamento?.filter(e => e.id !== 'b067640d-dfe0-475f-9a5c-8c7541efede1' && e.id !== 'a92f6984-e374-4bb5-a319-69ac12c3804f' && e.id !== '4fd26649-b408-478d-b4a7-c797163f1a7a' && e.id !== 'ab2d00c8-67db-4477-9460-cf440a6964cb'))?.length > 0 && (
          <>
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              <h3>Questões selecionadas</h3>
            </Flex>
            <WhiteContainer margin='.75rem 0'>
              <Flex flexDir={'column'} gap={'.5rem'}>
                {
                  PerguntasEngajamento?.filter(e => e.id !== 'b067640d-dfe0-475f-9a5c-8c7541efede1' && e.id !== 'a92f6984-e374-4bb5-a319-69ac12c3804f' && e.id !== '4fd26649-b408-478d-b4a7-c797163f1a7a' && e.id !== 'ab2d00c8-67db-4477-9460-cf440a6964cb').map((e: iAtributo, i) => {
                    return (
                      <AtributoEngajamento
                        key={i}
                        atributo={e}
                        selecionadas={Selecionadas}
                        RemoverAtributo={RemoverAtributo}
                        onModalQuestao={onOpenModalQuestao}
                        onOpenModalDelete={onOpenModalDelete}
                        onVincularDemografia={VincularDemografia}
                        onVincularQuestao={(qId, jaVinculadas, vinculoId) => { setVincularQuestaoId(qId); setJaVinculdas(jaVinculadas); setVinculoId(vinculoId) }}
                        SelecionarQuestao={selecionarAtributo}
                        demografiaArea={DemografiaArea}
                        RemoverQuestaoVinculada={OnRemoverVinculoQuestao}
                        Edit={() => onOpenModalEditCompetencia(e.id)}
                      />
                    )
                  })
                }
              </Flex>
            </WhiteContainer>
          </>
        )
      }

      <Flex flexDir={'column'} gap={'1rem'} my={'1rem'}>
        <Flex flexDir={'column'} gap='.125rem'>
          <h2>Outras questões (opcional)</h2>
          <span style={{ fontWeight: 'bold', fontSize: '.813rem', color: 'var(--c7)' }}>Você pode adicionar qustões discursivas e/ou NPS. A questão utilizada de eNPS já está contemplada como uma das questões de engajamento da pesquisa.</span>
        </Flex>
        <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'1rem'}>
          <ClusterCard
              OnAction={() => { OpenQuestoesOpcionais('ab2d00c8-67db-4477-9460-cf440a6964cb') }}
              color='Red'
              customName='Questões discursivas'
              model={{ avatar: 'https://sigah.app/img/icone_modal_perguntas.png', nome: 'Questões discursivas', pulse: false }}
            />
          <ClusterCard
            OnAction={() => { OpenQuestoesOpcionais('4fd26649-b408-478d-b4a7-c797163f1a7a') }}
            color='Green1'
            customName='NPS - probabilidade de recomendar nossa marca/produto/serviço'
            model={{ avatar: 'https://sigah.app/img/icone_modal_perguntas.png', nome: 'NPS', pulse: false }}
          />
          <ClusterCard
            OnAction={() => { OpenQuestoesOpcionais('a92f6984-e374-4bb5-a319-69ac12c3804f') }}
            color='Green3'
            customName='eNPS - probabilidade de recomendar nossa empresa como local de trabalho'
            model={{ avatar: 'https://sigah.app/img/icone_modal_perguntas.png', nome: 'eNPS', pulse: false }}
          />
        </Grid>
      </Flex>

      {
        (PerguntasEngajamento?.filter(e => e.id === 'a92f6984-e374-4bb5-a319-69ac12c3804f' || e.id === '4fd26649-b408-478d-b4a7-c797163f1a7a' || e.id === 'ab2d00c8-67db-4477-9460-cf440a6964cb'))?.length > 0 && (
          <>
            <WhiteContainer margin='.75rem 0'>
              <Flex flexDir={'column'} gap={'.5rem'}>
                {
                  PerguntasEngajamento?.filter(e => e.id === 'a92f6984-e374-4bb5-a319-69ac12c3804f' || e.id === '4fd26649-b408-478d-b4a7-c797163f1a7a' || e.id === 'ab2d00c8-67db-4477-9460-cf440a6964cb').map((e: iAtributo, i) => {
                    return (
                      <AtributoEngajamento
                        key={i}
                        atributo={e}
                        selecionadas={Selecionadas}
                        RemoverAtributo={RemoverAtributo}
                        onModalQuestao={onOpenModalQuestao}
                        onOpenModalDelete={onOpenModalDelete}
                        onVincularDemografia={VincularDemografia}
                        onVincularQuestao={(qId, jaVinculadas, vinculoId) => { setVincularQuestaoId(qId); setJaVinculdas(jaVinculadas); setVinculoId(vinculoId) }}
                        SelecionarQuestao={selecionarAtributo}
                        demografiaArea={DemografiaArea}
                        RemoverQuestaoVinculada={OnRemoverVinculoQuestao}
                        Edit={() => onOpenModalEditCompetencia(e.id)}
                      />
                    )
                  })
                }
              </Flex>
            </WhiteContainer>
          </>
        )
      }

      {PerguntasEngajamento.filter(e => e.id === 'b067640d-dfe0-475f-9a5c-8c7541efede1').length > 0 && <><Flex mb={'1rem'} alignItems={'center'} justifyContent={'space-between'} mt={'1rem'}>
          <h3>Questões para medir o engajamento (Item obrigatório)</h3>
        </Flex>
        <WhiteContainer>
          <Container>
            <CompetenciaMenu>
              <ImgText>
                <img src={PerguntasEngajamento?.find(e => e.id === 'b067640d-dfe0-475f-9a5c-8c7541efede1')?.avatar} />
                <span>{PerguntasEngajamento?.find(e => e.id === 'b067640d-dfe0-475f-9a5c-8c7541efede1')?.nome}</span>
              </ImgText>
            </CompetenciaMenu>
            <Flex flexDirection={'column'} width='100%' justifyContent={'space-between'} px={'.5rem'}>
              <CompetenciaContent>
                {PerguntasEngajamento?.find(e => e.id === 'b067640d-dfe0-475f-9a5c-8c7541efede1')?.perguntas?.filter((e: PerguntaPec) => {
                  if (Selecionadas?.find(r => r === e.id) !== undefined) {
                    return e
                  }
                  return null
                }).map((e: PerguntaPec, i) => {
                  return (<CompetenciaLine key={i}>
                    <StyledQuestion blockHover>
                      <FaCheckCircle size={20} color='var(--Azul)' />
                      <span>{e.pergunta}</span>
                    </StyledQuestion>
                  </CompetenciaLine>)
                })}
              </CompetenciaContent>
            </Flex>
          </Container>
        </WhiteContainer></>}
        <Flex justifyContent={'end'} gap={'.5rem'} mt={'1.5rem'}>
          <ButtonCmp VarColor='c6' onClick={() => nav(pathname.replace('Formulario', 'Demografia'))}>Voltar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={() => nav(pathname.replace('Formulario', 'VisualizarFormulario'))}>Continuar</ButtonCmp>
        </Flex>
    </Body>
  )
}
