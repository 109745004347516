import styled from 'styled-components'
import { Column } from '../../../../../../styles/styledGlobal'

interface iProps {
  color: string
}

export const PreViewParticipante = styled.div<iProps>`
display: flex;
position: relative;
justify-content: space-between;
background: var(--c1);
border-radius: 0.375rem;
box-shadow: 0rem 0.1rem 0.25rem var(--c3);
padding: .75rem .5rem .75rem 1.5rem;
max-height: 10rem;
border-left: 3px solid ${props => props.color};
// border-right: 1px solid ${props => props.color};

.OpacityEfect{
    transition: all .5s ease;
    opacity: 100%;
    pointer-events: inherit;
}

.disable{
    cursor: not-allowed;
    li:not(:nth-child(6),:nth-child(7)){
        opacity: 50%;
        pointer-events: none;
    }
}
`

export const ColumnText = styled(Column)`
align-items: flex-start;
justify-content: center;
margin-right:.5rem;
h4 {
    font-size: .813rem;
    line-height: 0.813rem;
    font-weight: 400;
}
h3 {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
}
span {
    font-size: .925rem;
    font-weight: 700;
}
`

export const GridContainer = styled.div`
display: grid;
grid-template-columns: 2fr 1fr 1fr 1fr 1.25fr;
gap: 0rem .5rem;
width: 100%;
`
