import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
export const BaseCsv: React.FC = () => {
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const [IsLoading, setIsLoading] = useState(false)

  function getBase(): void {
    window.location.href = `${process.env.REACT_APP_URL_API}/ResultadoPec/${pesquisaId}/BaseCsv`
    setIsLoading(true)
    appApi.get(`ResultadoPec/${pesquisaId}/BaseCsv`)
      .then(res => {
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getBase()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <Flex h={'90vh'} alignItems={'center'}>
        <Flex justifyContent={'center'} alignItems='center' margin={'auto'} px={'4rem'} py={'2rem'} fontWeight={'bold'} fontSize={'18px'} bg={'var(--c3)'} borderRadius={'.25rem'} boxShadow={'var(--SombraBackground)'}>
          {IsLoading ? 'Gerando csv de dados da base...' : 'Csv de dados da base gerados com sucesso!'}
        </Flex>
      </Flex>
    </Body>
  )
}
