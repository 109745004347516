/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { FaPlus, FaRegStopCircle } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { iAtributo } from '../../../../../../interfaces'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { ClusterNome, Container, Nome } from './styles'

interface iFindProps extends InputProps {
  onChoice: (id: string) => void
  onRemove: (id: string) => void
  lista: iAtributo[]
  selecionados: iAtributo[]
  minW?: string
  onBlur?: () => void
}

export const FindAtributo: React.FC<iFindProps> = ({ lista, placeholder, isInvalid, isReadOnly, isDisabled, onBlur, onChoice, minW, selecionados, onRemove }) => {
  const [Selecionado, setSelecionado] = useState<string>()
  const [IsActive, setIsActive] = useState(false)
  const [Texto, setTexto] = useState('')

  useEffect(() => {
  }, [lista])

  function Clean(): void {
    setTexto('')
    setSelecionado(undefined)
    onChoice('')
  }

  return (
    <Flex position={'relative'} flexDir={'column'} width={'100%'}>
      <Flex flexDir={'column'} width='100%'>
        <InputGroup width={'100%'}>
          {
            (!isDisabled) && (
              <InputRightElement
                h={'1.975rem'}
                cursor={'pointer'}
                children={Selecionado ? <MdClose onClick={Clean} color={'var(--Red)'} /> : <></>}
              />
            )
          }
          <InputCmp
            value={Texto}
            borderColor={'var(--Gray4)'}
            placeholder={placeholder}
            isDisabled={isDisabled ?? !PropInvalida(Selecionado)}
            isReadOnly={isReadOnly}
            isInvalid={isInvalid}
            minW={minW}
            onBlur={() => { if (onBlur) { onBlur() }; setTimeout(() => { setIsActive(false) }, 4000) }}
            onFocus={() => { setTimeout(() => { setIsActive(true) }, 200) }}
            OnChange={(e) => {
              setTexto(e)
            }}
          />
        </InputGroup>
      </Flex>
        {lista.length > 0 &&
          <Flex position={'absolute'} bg={'white'} zIndex={999} top={'32px'} w={'100%'} flexDir={'column'} gap={'.25rem'}>
            {
              lista.filter((e) => {
                if (isDisabled) {
                  return null
                } else if (!IsActive) {
                  return null
                } else if (Texto === '' || Selecionado) {
                  return null
                } else if (e.nome.toLocaleLowerCase().includes(Texto.toLocaleLowerCase())) {
                  return e
                }
                return null
              }).slice(0, 3).map((e, i) => {
                return (
                  <Container key={i} background='var(--c3)'>
                    <Flex flexDir={'column'}>
                      <Nome>{e.nome}</Nome>
                      <ClusterNome>{e.clusterNome ?? 'Atributo personalizado'}</ClusterNome>
                    </Flex>
                    {selecionados.some(a => a.id === e.id) ? <ButtonCmp small onClick={() => onRemove(e.id)} VarColor='Red'><FaRegStopCircle/></ButtonCmp> : <ButtonCmp VarColor='Green2' small onClick={() => onChoice(e.id)}><FaPlus/></ButtonCmp>}
                  </Container>
                )
              })
            }
          </Flex>
        }
    </Flex>
  )
}
