import { Flex, Divider, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FaComment, FaFileCsv, FaPlus } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import LoadingContainer from '../../../../../../components/LoadingContainer'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { GroupBy } from '../../../../../../Utils/Helper'
import { IModel } from '../../interfaces'

interface iProps {
  Model: IModel
  onGetGrupo: (val: string) => void
  isLoading: boolean
}

export const TabComentarios: React.FC<iProps> = ({ Model, onGetGrupo, isLoading }) => {
  const [GrupoSelecionado, setGrupoSelecionado] = useState('')

  function OnChangeGrupo(val: string): void {
    onGetGrupo(val)
    setGrupoSelecionado(val)
  }

  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'end'}>
        <SelectCmp title='Grupo de análise' value={GrupoSelecionado} OnChange={OnChangeGrupo} width={'14rem'}>
          <option value={''}>Todas</option>
          {Model.demografiasDDL.map(e => {
            return (
              <option value={e.itemDemografiaId}>{e.pergunta} - {e.nome}</option>
            )
          })}
        </SelectCmp>
        <ButtonCmp leftIcon={<FaFileCsv/>} isDisabled={!Model.comentarios || !(Model.comentarios?.length > 0)} onClick={() => { window.location.href = `${process.env.REACT_APP_URL_API}/ResultadoPec/${Model.pesquisa.id}/ComentariosCsv?itemDemografiaId=${GrupoSelecionado}` }} VarColor={'Green2'}>Exportar Csv com comentários</ButtonCmp>
      </Flex>
      <LoadingContainer linhas={18} loading={isLoading}>
        {Model?.comentarios && Model?.comentarios?.length > 0
          ? <Flex flexDir={'column'} gap={'1.5rem'}>
          <Divider borderColor={'var(--AzulOpaco)'} my={'1rem'} opacity={'1'}/>
          <Flex gap={'.75rem'} alignItems='center'>
            <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
              <FaComment fontSize={'2rem'} color={'var(--Azul)'}/>
            </Flex>
            <h3>Comentários e perguntas discursivas</h3>
          </Flex>
          {GroupBy(Model.comentarios, (item) => item.pesquisaXQuestoesDemografiaId).toList().map(item => {
            return (
              <Flex border={'1px dashed var(--AzulOpaco)'} flexDir={'column'} p={'1.5rem'} gap={'2rem'}>
                <Flex flexDir={'column'}>
                  <Text fontWeight={700} color={'var(--Azul)'}>Questão</Text>
                  <Text fontWeight={400}>{item.data[0].pergunta}</Text>
                </Flex>
                <Flex flexDir={'column'} gap={'.75rem'}>
                  <Text fontWeight={700} color={'var(--Azul)'}>Respostas: {item.data.length}</Text>
                {item.data.map(e => {
                  return (
                    <Flex alignItems={'center'} p={'1rem'} background={'var(--AzulOpacoBg)'} border={'1px solid var(--c3)'}>{e.respostaDiscursiva}</Flex>
                  )
                })}
                </Flex>
              </Flex>
            )
          })}
        </Flex>
          : <Flex alignItems={'center'} justifyContent={'center'} fontWeight={700} padding={'3rem'}>
              Não foram realizados comentários nessa pesquisa
          </Flex>}
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.25rem'} alignItems={'center'} flexDir={'column'} maxW={'66rem'} margin={'0 auto'} justifyContent={'center'}>
        <h3 style={{ textAlign: 'center' }}>Agora você pode adicionar planos de ação para trabalhar as questões apontadas em suapesquisa de engajamento!</h3>
        <span style={{ textAlign: 'center' }}>Recomendamos que foque principalmente nas questões que afetam o engajamento na suaorganização, ou seja, as questões que os respondentes realmente consideram importantes.</span>
        <ButtonCmp leftIcon={<FaPlus/>} VarColor='Green2' w={'12rem'} my={'.75rem'} onClick={() => { window.location.href = `${process.env.REACT_APP_URL_SIGAH}/Home/Plano` }}>Adicionar plano de ação</ButtonCmp>
        </Flex>
      </LoadingContainer>
    </>
  )
}
