import { Flex, Text } from '@chakra-ui/layout'

interface CardProps {
  titulo: string
  descricao: string
  color: string
  icon: any
  onSelect: () => void
}

export const CardModelo: React.FC<CardProps> = ({ descricao, icon, titulo, onSelect, color }) => {
  return (
    <Flex background={`var(--${color})`} borderRadius={'.325rem'} w={'100%'}>
        <Flex border={'1px solid var(--c1)'} bg={'var(--c1)'} onClick={() => onSelect()} flexDir={'column'} p={'1rem'} gap={'.75rem'} w='100%' borderRadius={'.25rem'} cursor={'pointer'} boxShadow={'var(--SombraBtns)'} _hover={{
          transform: 'translateY(-2px) translateX(-2px)',
          transition: 'transform 0.3s, filter 0.3s',
          filter: 'brightness(1.03)',
          border: `.5px solid var(--${color})`
        }}>
            <Flex gap={'.5rem'} fontSize={'1rem'} alignItems='center'>
                <Flex justifyContent={'center'} alignItems={'center'} borderRadius={'50%'} background={'var(--c1)'} w={'2rem'} h={'2rem'} padding={'.325rem'} backgroundColor={`var(--${color})`} color={'var(--c1)'}>{icon}</Flex>
                <Text fontWeight='700' fontSize={'1.125rem'} color={`var(--${color})`}>{titulo}</Text>
            </Flex>
            <Text fontSize={'.875rem'} fontWeight='400'>{descricao}</Text>
        </Flex>
    </Flex>
  )
}
