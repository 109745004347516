/* eslint-disable @typescript-eslint/no-invalid-void-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
import Roboto from '../../../../../../assets/fonts/Roboto-Bold.ttf'

import {
  Document,
  Font,
  G,
  Image as Img,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import React from 'react'
import { Header } from './Components/Header'
import { Footer } from './Components/Footer'
import { ArrayToChunks, formatarData, GroupBy, PropInvalida } from '../../../../../../Utils/Helper'
import { GraficoPEC, IModel } from '../../interfaces'

interface iProps {
  model: IModel
  graficosDemografia: Array<{ grafico: string, nome: string }>
  graficosPerguntasAtributo: Array<{ avatar: string, grafico: string, nome: string }>
  jpgBonequinhos: Array<{ nome: string, grafico: string }>
  scatterGrafico: string
  dadosGraficoScatter?: Array<{name: string, data: number[][]}>
  graficoMediaPerguntas: string
  graficoMediaAtributos: string
}

export const ResultadoPDF: React.FC<iProps> = ({
  model,
  graficosDemografia,
  graficosPerguntasAtributo,
  jpgBonequinhos,
  scatterGrafico,
  graficoMediaPerguntas,
  graficoMediaAtributos,
  dadosGraficoScatter
}) => {
  Font.register({
    family: 'Roboto',
    fonts: [{
      src: Roboto
    }]
  })

  function GetAdesao(): string {
    if (model.pesquisa.pesqusiaManual) {
      return model.adesaoManual?.toFixed(2) + '%'
    } else {
      return Math.floor(model.participantes / model.pesquisa.convites * 100).toFixed(2) + '%'
    }
  }

  function QuebrarTexto(text: string): string {
    let newText = ''
    text.replaceAll('/', ' / ').split(' ').forEach(str => {
      if (str.length > 12) {
        newText += str.slice(0, 12) + '- '
        newText += str.slice(12) + ' '
      } else {
        newText += str + ' '
      }
    })

    return newText
  }

  const styles = StyleSheet.create({
    DefaultPage: {
      paddingTop: 16,
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    tableCol: {
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontFamily: 'Roboto',
      fontWeight: 'bold'
    },
    tableHeaderCol: {
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#1961E0'
    },
    tableCell: {
      margin: 'auto',
      fontSize: 10,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold'
    },
    tableCellCompetencia: {
      width: '340px',
      fontSize: 10,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      backgroundColor: '#1961E0',
      borderRight: '1px solid #000',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    tableCellPergunta: {
      width: '340px',
      fontSize: 8,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      backgroundColor: '#1961E015',
      borderRight: '1px solid #000',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    tableCellQuestao: {
      fontSize: 10,
      padding: 5,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      backgroundColor: '#1961E015',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })

  Font.registerHyphenationCallback((e) => {
    if (e.includes('http') && e.length > 100) {
      const arr: string[] = []
      for (let i = 0; i < e.length; i += 100) {
        arr.push(e.substring(i, i + 100))
      }
      return arr
    }

    if (e.includes('Viewibilidade/Adaptabilidade')) {
      return e.split('/')
    }

    if (e.includes('Autodesenvolvimento')) {
      return ['Autodesenvolvi', 'mento']
    }

    if (e.length === 1) {
      return [e, '']
    }
    return [e]
  })

  function ObterLogo(): string {
    const logo = localStorage.getItem('logo') ?? ''
    if (PropInvalida(logo)) {
      return 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'
    }

    if (logo.includes('https://blob-hml.icecloud.com.br') || logo.includes('https://blob.icecloud.com.br')) {
      return logo.replace('https://blob-hml.icecloud.com.br', 'https://sigah.blob.core.windows.net/sigah').replace('https://blob.icecloud.com.br', 'https://sigah.blob.core.windows.net/sigah')
    } else if (logo?.includes('https://sigah.arquiteturahumana.com.br')) {
      return logo.replace('https://sigah.arquiteturahumana.com.br', 'https://sigah.blob.core.windows.net/sigah')
    }
    return logo
  }

  function GetLength(demografia: GraficoPEC): number {
    let length = 0
    if (demografia.labels) {
      for (let i = 0; i < demografia.labels?.length; i++) {
        if ((demografia.serie && demografia.serie[i] >= 5)) {
          length++
        }
      }
    }
    return length
  }

  return (
    <Document>
      <Page style={{ backgroundColor: '#E9E9E9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
        <View style={{ display: 'flex', minHeight: '180px', maxHeight: '180px', flexDirection: 'column', paddingHorizontal: 42, paddingTop: 24, gap: '12px', alignItems: 'center' }}>
          <Img src={'https://sigah.blob.core.windows.net/onboard/icones/logo_ah_1 2_4b818f42-515f-4165-b395-8ddfa2d4a6e9.png'} style={{ maxWidth: '120px', objectFit: 'contain', maxHeight: '70px' }}/>
          <Text style={{ color: '#66046B', fontSize: 18 }}>Resultados pesquisa de engajamento</Text>
          <Text style={{ color: '#66046B', fontSize: 24 }}>{model.pesquisa.descricao}</Text>
        </View>
        <View style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', paddingHorizontal: 42, paddingVertical: 30 }}>
          <Img src={'https://sigah.blob.core.windows.net/onboard/icones/Group 522_619b88f1-5bf2-49f2-9b7a-7c1137c4f43d.png'} style={{ width: '330px', objectFit: 'contain' }}/>
        </View>
        <View style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'flex-end', position: 'relative', minHeight: '320px', maxHeight: '320px', paddingBottom: '42px', paddingRight: '30px' }}>
          <Img src={'https://sigah.blob.core.windows.net/onboard/icones/Clip path group_b9e6b36d-1d66-4154-b9da-b32c9f301ebe.png'} style={{ width: '100%', objectFit: 'cover', position: 'absolute', zIndex: 1, bottom: 0, right: 0 }}/>
          <Img src={ObterLogo()} style={{ height: '120px', maxWidth: '270px', objectFit: 'contain' }}/>
        </View>
      </Page>
      <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <Text
              style={{
                textAlign: 'left',
                fontFamily: 'Roboto',
                paddingVertical: 32,
                fontSize: 18,
                color: '#203864'
              }}
            >
              Contexto
            </Text>
            <View>
              <Text style={{ fontSize: '14px', fontWeight: 'thin', textAlign: 'justify', paddingVertical: 32 }}>
                Após a aplicação da Pesquisa de Engajamento e Clima - {model.nomeEmpresa}
                realizada do dia {formatarData(model.pesquisa.dataInicio ?? '')} a {formatarData(model.pesquisa.dataFim ?? '')}, com a participação de {model.participantes} colaboradores e {GetAdesao()} de adesão, foram realizados estudos estatísticos com os
                atributos correlacionados com o engajamento.
                Identificamos quais atributos afetam o engajamento de forma significativa e qual
                a importância (alavancagem) de cada um.
                Sugerimos que esses atributos sejam priorizados no plano de ação de curto e
                médio prazo.
                Os resultados são uma fonte importante de informação estratégica para a
                empresa.
              </Text>
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <Text
              style={{
                textAlign: 'left',
                fontFamily: 'Roboto',
                paddingVertical: 32,
                fontSize: 18,
                color: '#203864'
              }}
            >
              Atributos com maior impacto no engajamento
            </Text>
            {ArrayToChunks(model.atributosQueAfetamMaisEngajamento, 4).map((linha, i) => {
              return (<View style={{ display: 'flex', flexDirection: 'row', gap: '12px', marginTop: i !== 0 ? '14px' : '' }}>
                {linha.map(item => {
                  return (
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px', width: '120px' }}>
                      <View style={{ border: '2px dashed #d3d3d3', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Img src={item.avatar} style={{ width: '100%', height: '100%' }}/>
                      </View>
                      <Text style={{ width: '120px', fontSize: '12px', textAlign: 'center' }}>{item.nome}</Text>
                    </View>
                  )
                })}
              </View>)
            })}
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      {ArrayToChunks(graficosDemografia, 2).map(page => {
        return <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Perfil dos participantes
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 13,
                  color: '#203864'
                }}
              >
                A distribuição dos respondentes desta pesquisa para as demografias definidas
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '56px' }}>
            {page.map(e => {
              return <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
                <Text style={{
                  textAlign: 'center',
                  fontFamily: 'Roboto',
                  fontSize: 14
                }}>{e.nome}</Text>
                <Img src={e.grafico} style={{ minHeight: '210px', maxHeight: '210px', objectFit: 'contain' }}/>
              </View>
            })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      })}
      {jpgBonequinhos && <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Qual é o nível de engajamento dos participantes
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 13,
                  color: '#203864'
                }}
              >
                Distribuição das notas paras as 4 perguntas de engajamento e a distribuição geral das
                notas de engajamento, sendo: favorável (10 ou 8), neutro (6 ou 4) e desfavorável (2 ou 0)
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '38px' }}>
                {ArrayToChunks(jpgBonequinhos, 3).map(linha => {
                  return (<View style={{ display: 'flex', flexDirection: 'row', gap: '34px', alignItems: 'center', justifyContent: 'center' }}>
                    {linha.map((item, i) => {
                      return (<View style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '110px' }}>
                        <Img src={item.grafico}/>
                        <Text style={{ textAlign: 'center', fontSize: '11px', color: '#6c757d' }}>{item.nome}</Text>
                      </View>)
                    })}
                  </View>)
                })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>}
      <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Priorização das questões de engajamento
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 13,
                  color: '#203864'
                }}
              >
                Apresentação das questões priorizadas após a análise, com as suas médias e alavancagens
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Img src={scatterGrafico} style={{ minHeight: '330px', maxHeight: '330px', objectFit: 'contain' }}/>
              <Text style={{ fontSize: '12px' }}>Perguntas que afetam o engajamento de forma significativa</Text>
              {dadosGraficoScatter?.slice(0, 9).map((e, i) => {
                return <Text style={{ fontSize: '9px' }}>{e.name} ( Engajamento: {e.data[0][1].toFixed(2)}, Alavancagem: {e.data[0][0].toFixed(2)} )</Text>
              })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      {dadosGraficoScatter && model.graficoDispersaoAfetamEngajamento?.labels && model.graficoDispersaoAfetamEngajamento?.labels?.length > 7 && ArrayToChunks(dadosGraficoScatter?.filter((e, i) => i > 8), 20).map(page => {
        return <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ padding: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {page.map(e => {
                return <Text style={{ fontSize: '12px' }}>{e.name} ( Engajamento: {e.data[0][1].toFixed(2)}, Alavancagem: {e.data[0][0].toFixed(2)} )</Text>
              })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      })}
      <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Média das perguntas que afetam o engajamento
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Img src={graficoMediaPerguntas} style={{ minHeight: '330px', maxHeight: '330px', objectFit: 'contain' }}/>
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Média dos atributos
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Img src={graficoMediaAtributos} style={{ minHeight: '330px', maxHeight: '330px', objectFit: 'contain' }}/>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 18 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Atributos com maior impacto no engajamento
              </Text>
            </View>
            {ArrayToChunks(model.atributosQueAfetamMaisEngajamento, 5).map((linha, i) => {
              return (<View style={{ display: 'flex', flexDirection: 'row', gap: '12px', marginTop: i !== 0 ? '12px' : '' }}>
                {linha.map(item => {
                  return (
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '12px', width: '110px' }}>
                      <View style={{ border: '2px dashed #d3d3d3', borderRadius: '50%', width: '52px', height: '52px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Img src={item.avatar} style={{ width: '100%', height: '100%' }}/>
                      </View>
                      <Text style={{ width: '120px', fontSize: '11px', textAlign: 'center' }}>{item.nome}</Text>
                    </View>
                  )
                })}
              </View>)
            })}
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      {ArrayToChunks(graficosPerguntasAtributo, 2).map((page, i) => {
        return <Page style={styles.DefaultPage}>
        <View>
          <Header
            texto={'Relatório geral'}
            titulo={`${model?.pesquisa.descricao}`}
          />
          <View style={{ paddingLeft: 32, paddingRight: 32 }}>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 18,
                  color: '#203864'
                }}
              >
                Média das perguntas por atributo
              </Text>
              <Text
                style={{
                  textAlign: 'left',
                  fontFamily: 'Roboto',
                  fontSize: 13,
                  color: '#203864'
                }}
              >
                A distribuição dos respondentes desta pesquisa para as demografias definidas
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: '56px' }}>
            {page.map((e, index) => {
              return <View style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', minWidth: '250px' }}>
                  <View style={{ border: '2px dashed #d3d3d3', borderRadius: '50%', width: '52px', height: '52px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Img src={e.avatar} style={{ width: '100%', height: '100%' }}/>
                  </View>
                  <Text style={{ minWidth: '180px', fontSize: '11px', textAlign: 'center' }}>{e.nome}</Text>
                </View>
                <Img src={e.grafico} style={{ minHeight: '190px', maxHeight: '190px', objectFit: 'contain' }}/>
              </View>
            })}
            </View>
          </View>
        </View>
        <Footer nomeEmpresa={model.nomeEmpresa}/>
      </Page>
      })}
      {(model.demografiasSemFiltro && model.maozinhas && model.perguntasMaiorAlavancagem) &&
        model.demografiasSemFiltro.map((demografia, i) => {
          return (
            <Page style={styles.DefaultPage}>
              <View>
                <Header
                  texto={'Relatório geral'}
                  titulo={`${model?.pesquisa.descricao}`}
                />
                <View style={{ paddingLeft: 32, paddingRight: 32 }}>
                  <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingVertical: 32 }}>
                    <Text
                      style={{
                        textAlign: 'left',
                        fontFamily: 'Roboto',
                        fontSize: 18,
                        color: '#203864'
                      }}
                    >
                      Análise das médias dos grupos - As demografias abaixo apresentam diferença estatística
                    </Text>
                    <Text
                      style={{
                        textAlign: 'left',
                        fontFamily: 'Roboto',
                        fontSize: 12,
                        color: '#203864'
                      }}
                    >
                      Comparação estatística entre as respostas de diferentes categorias de uma demografia,identificando as categorias
                      identificando as categorias que avaliaram melhor , as categorias que avaliaram pior .
                      Os espaços em branco são respostas que não apresentaram diferença estatística em sua comparação com os
                      demais
                    </Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'column', gap: '56px' }}>
                  <View style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
                      <View style={{ display: 'flex', flexDirection: 'column' }}>
                        <View style={{ display: 'flex', gap: '12px', justifyContent: 'center' }}>
                          <Text style={{ fontSize: '18px', fontFamily: 'Roboto', color: '#203864' }}>{demografia.titulo}</Text>
                        </View>
                      </View>
                      <View>
                        <View>
                          <View style={styles.table}>
                            <View style={styles.tableHeaderCol}>
                              <View style={styles.tableRow}>
                                <View style={styles.tableCellCompetencia}>
                                  <Text style={{ textAlign: 'center' }}>Questão</Text>
                                </View>
                                {demografia.labels?.map((e, i) => {
                                  if (demografia.serie && demografia.serie[i] >= 5) {
                                    return <View style={{ ...styles.tableCellQuestao, minWidth: `${190 / GetLength(demografia)}px`, maxWidth: `${190 / GetLength(demografia)}px`, borderRight: (i + 1) === demografia.labels?.length ? '' : '1px solid #000' }}><Text style={{ fontSize: 8 }}>{QuebrarTexto(e)}</Text></View>
                                  }
                                  return null
                                })}
                              </View>
                            </View>
                            <View style={styles.tableCol}>
                              {model.perguntasMaiorAlavancagem?.map((perg, i) => {
                                return (
                                  <View style={styles.tableRow} key={i}>
                                    <View style={styles.tableCellPergunta}>
                                      <Text>{perg.pergunta}</Text>
                                    </View>
                                    {demografia.numeroPergunta?.map((e, i) => {
                                      if (demografia.serie && demografia.serie[i] >= 5) {
                                        const maozinha = model.maozinhas.find(m => m.perguntaId === perg.id)?.demografias.find(d => d.demografiaId === demografia.id && d.grafico.some(item => item.itemDemografiaId === e))
                                        return (
                                          <View style={{ ...styles.tableCellQuestao, minWidth: `${190 / GetLength(demografia)}px`, maxWidth: `${190 / GetLength(demografia)}px`, borderRight: (i + 1) === demografia.labels?.length ? '' : '1px solid #000' }}>
                                            {maozinha
                                              ? maozinha.grafico.find(g => g.itemDemografiaId === e)?.praCima
                                                ? <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Svg stroke="#27AE60" fill="#27AE60" stroke-width="0" viewBox="0 0 512 512" color="#27AE60" height="15px" width="15px"><Path d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"></Path></Svg></View>
                                                : <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Svg stroke="#F2994A" fill="#F2994A" stroke-width="0" viewBox="0 0 512 512" color="#F2994A" height="15px" width="15px"><Path d="M0 56v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56zm40 200c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24zm272 256c-20.183 0-29.485-39.293-33.931-57.795-5.206-21.666-10.589-44.07-25.393-58.902-32.469-32.524-49.503-73.967-89.117-113.111a11.98 11.98 0 0 1-3.558-8.521V59.901c0-6.541 5.243-11.878 11.783-11.998 15.831-.29 36.694-9.079 52.651-16.178C256.189 17.598 295.709.017 343.995 0h2.844c42.777 0 93.363.413 113.774 29.737 8.392 12.057 10.446 27.034 6.148 44.632 16.312 17.053 25.063 48.863 16.382 74.757 17.544 23.432 19.143 56.132 9.308 79.469l.11.11c11.893 11.949 19.523 31.259 19.439 49.197-.156 30.352-26.157 58.098-59.553 58.098H350.723C358.03 364.34 384 388.132 384 430.548 384 504 336 512 312 512z"></Path></Svg></View>
                                              : <Text style={{ textAlign: 'center' }}>-</Text>}
                                          </View>
                                        )
                                      }
                                      return null
                                    })}
                                  </View>
                                )
                              })}
                            </View>

                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <Footer nomeEmpresa={model.nomeEmpresa}/>
            </Page>
          )
        })}
    </Document>
  )
}
