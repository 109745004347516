import { Flex } from '@chakra-ui/react'
import styled from 'styled-components'

export const Nome = styled.span`
margin-bottom: .125rem;
font-size: .875rem;
line-height: .9125rem;
`

export const ClusterNome = styled.span`
margin-bottom: .125rem;
font-size: .813rem;
line-height: .875rem;
color: var(--Azul);
`

export const Container = styled(Flex)`
border-radius: 0.25rem;
padding: .75rem;
display: flex;
font-size: .875rem;
justify-content: space-between;
align-items: center;
color: black;
`
