/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
import Roboto from '../../../../../assets/fonts/Roboto-Bold.ttf'

import {
  Document,
  Font,
  Image,
  Image as PDFImage,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import { iCartaConvite } from '../../../../CartaConvite'

interface iProps {
  model: string[]
}

export const RelatorioPDF: React.FC<iProps> = ({
  model
}) => {
  return (
    <Document>
      {model.map(item => {
        return (
            <Page>
              <View style={{ display: 'flex', justifyContent: 'center', marginVertical: 32 }}>
                <Image src={item}/>
              </View>
            </Page>
        )
      })
      }
    </Document>
  )
}
