import { Alert, AlertDescription, AlertIcon, Flex } from '@chakra-ui/react'
import { HTMLAttributes } from 'react'
import { FaSignal } from 'react-icons/fa'
import { iEscala, iRespostaEscala } from '../../../../../../interfaces'
import { parseJwt } from '../../../../../../services/token'
import { Container } from './styles'
interface iBoxProps extends HTMLAttributes<HTMLDivElement>{
  Selecionado?: boolean
  Escala: iEscala
  AdviceMessage: string
}

export const BoxEscala: React.FC<iBoxProps> = ({ Selecionado, Escala, AdviceMessage, onClick }) => {
  return (
        <Container
            active={Selecionado}
            onClick={onClick}
        >
            <Flex gap={'.125rem'} alignItems='center'>
              <FaSignal size={32} />
              <h3>{Escala.nome}</h3>
            </Flex>
            <Flex flexDir={'column'} gap={'.25rem'} alignItems={'center'}>
              {parseJwt().eid !== '02e3591d-d8c0-42e9-8aba-2c0cdcc3e034' &&
              <ul>
                  {
                    Escala.respostas.map((e: iRespostaEscala, i) => {
                      return (
                        <li key={i}>{e.resposta} {i !== Escala.respostas.length - 1 ? `( ${e.peso} )` : ''}</li>
                      )
                    })
                  }
              </ul>}

              {parseJwt().eid === '02e3591d-d8c0-42e9-8aba-2c0cdcc3e034' &&
              <ul>
                  {
                    Escala.respostas.map((e: iRespostaEscala, i) => {
                      return (
                        <li key={i}>{e.resposta} {`( ${e.peso} )`}</li>
                      )
                    })
                  }
              </ul>}
              <Alert borderRadius={'.25rem'} justifyContent='space-between' status='info' px={'.75rem'} mt={'1rem'} py={'.75rem'}>
                <Flex flexDir={'column'} fontSize={'.75rem'}>
                  <Flex alignItems={'center'}>
                    <AlertIcon color={'var(--Azul)'} mr={'0.325rem'} h={'1.325rem'}/>
                    <AlertDescription lineHeight={'1rem'}>{AdviceMessage}</AlertDescription>
                  </Flex>
                </Flex>
              </Alert>
            </Flex>
        </Container>
  )
}
