/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
import Roboto from '../../../../../assets/fonts/Roboto-Bold.ttf'

import {
  Document,
  Font,
  Image as Img,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import React from 'react'
import { iAtributo, iDemografiaPEC, iEscala, PerguntaPec } from '../../../../../interfaces'
import { Header } from './Components/Header'
import { Footer } from './Components/Footer'
import { ArrayToChunks } from '../../../../../Utils/Helper'

interface iModel {
  atributos: iAtributo[]
  demografias: iDemografiaPEC[]
  escala?: iEscala
  nomePesquisa: string
  nomeEmpresa: string
}

interface iProps {
  model: iModel
}

export const ExportarQuestoesPDF: React.FC<iProps> = ({
  model
}) => {
  Font.register({
    family: 'Roboto',
    fonts: [{
      src: Roboto
    }]
  })

  const styles = StyleSheet.create({
    DefaultPage: {
      paddingTop: 16,
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  })

  Font.registerHyphenationCallback((e) => {
    if (e.includes('http') && e.length > 100) {
      const arr: string[] = []
      for (let i = 0; i < e.length; i += 100) {
        arr.push(e.substring(i, i + 100))
      }
      return arr
    }

    if (e.includes('Flexibilidade/Adaptabilidade')) {
      return e.split('/')
    }

    if (e.includes('Autodesenvolvimento')) {
      return ['Autodesenvolvi', 'mento']
    }

    if (e.length === 1) {
      return [e, '']
    }
    return [e]
  })

  function GetPergunta(e: PerguntaPec, isAtrelada: boolean): any {
    return (
      <View style={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingLeft: '12px' }}>
        <View style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
          {isAtrelada && <Svg stroke="#1961E0" fill="#1961E0" stroke-width="0" viewBox="0 0 512 512" color="#1961E0" height="9px" width="9px"><Path d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z"></Path></Svg>}
          <Text style={{ fontSize: '9px' }}>{isAtrelada ? '' : '•' } {e.pergunta}</Text>
        </View>
        {e.perguntaAtrelada && <View style={{ paddingLeft: '12px' }}>{GetPergunta(e.perguntaAtrelada, true)}</View>}
      </View>
    )
  }

  return (
    <Document>
      {ArrayToChunks(model.atributos, 24).map(item => {
        return (
        <Page style={styles.DefaultPage}>
          <View>
            <Header
              texto={'Questões da pesquisa'}
              titulo={`${model?.nomePesquisa}`}
            />
            <View style={{ paddingLeft: 32, paddingRight: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  paddingVertical: 32,
                  fontSize: 16,
                  color: '#203864'
                }}
              >
                Atributos observados
              </Text>
              <View style={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
                {ArrayToChunks(item, 4).map(e => {
                  return (
                  <View style={{ display: 'flex', gap: '16px', flexWrap: 'nowrap', flexDirection: 'row' }}>
                    {e.map(att => {
                      return <View style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '120px', alignItems: 'center', justifyContent: 'center' }}>
                        <View style={{ borderRadius: '50%', border: '2px dashed #E3E3E3', width: '42px', height: '42px' }}><Img src={att.avatar}/></View>
                        <Text style={{ fontFamily: 'Roboto', fontSize: '10px', textAlign: 'center', height: '40px' }}>{att.nome}</Text>
                      </View>
                    })}
                  </View>)
                })}
              </View>
            </View>
          </View>
          <Footer nomeEmpresa={model.nomeEmpresa}/>
        </Page>)
      })}
      {ArrayToChunks(model.demografias, 3).map(item => {
        return (
        <Page style={styles.DefaultPage}>
          <View>
            <Header
              texto={'Questões da pesquisa'}
              titulo={`${model?.nomePesquisa}`}
            />
            <View style={{ paddingLeft: 32, paddingRight: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  paddingVertical: 32,
                  fontSize: 16,
                  color: '#203864'
                }}
              >
                Grupos de análise selecionados
              </Text>
              <View style={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
                <View style={{ display: 'flex', gap: '24px', flexWrap: 'nowrap', flexDirection: 'column' }}>
                  {item.map(demo => {
                    return (<View style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <View style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
                        <Svg stroke="#1961E0" fill="#1961E0" stroke-width="0" viewBox="0 0 544 512" color="#1961E0" height="14px" width="14px"><Path d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z"></Path>
                        </Svg>
                        <Text style={{ fontFamily: 'Roboto', fontSize: '12px', textAlign: 'center' }}>{demo.pergunta}</Text>
                      </View>
                      {demo.demografias && demo.demografias?.length > 9
                        ? <View style={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>{ArrayToChunks(demo.demografias, 2).map(itemDemo => {
                          return (
                          <View style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                            {itemDemo.map(e => {
                              return (
                                <View><Text style={{ fontSize: '10px' }}>• {e.nome}</Text></View>
                              )
                            })}
                          </View>
                          )
                        })}</View>
                        : <View style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        {demo.demografias?.map(e => {
                          return (
                            <View><Text style={{ fontSize: '10px' }}>• {e.nome}</Text></View>
                          )
                        })}
                      </View>}
                    </View>)
                  })}
                </View>
                {item.length <= 2 && <View style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: (item.length >= 1) ? '24px' : '' }}>
                  <Text style={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: 'bold' }}>{'>> '}{model.escala?.nome}</Text>
                  {model.escala?.respostas.map(e => {
                    return (
                      <Text style={{ fontSize: '10px' }}>• {e.resposta}</Text>
                    )
                  })}
                </View>}
              </View>
            </View>
          </View>
          <Footer nomeEmpresa={model.nomeEmpresa}/>
        </Page>)
      })}
      {ArrayToChunks(model.atributos.filter(e => e.perguntas && e.perguntas?.length < 8), model.atributos.some(e => e.perguntas && e.perguntas?.length === 7) ? 2 : model.atributos.some(e => e.perguntas && e.perguntas?.length > 4) ? 3 : 4).map(item => {
        return (
        <Page style={styles.DefaultPage}>
          <View>
            <Header
              texto={'Questões da pesquisa'}
              titulo={`${model?.nomePesquisa}`}
            />
            <View style={{ paddingLeft: 32, paddingRight: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  paddingVertical: 32,
                  fontSize: 16,
                  color: '#203864'
                }}
              >
                Questões
              </Text>
              <View style={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
                <View style={{ display: 'flex', gap: '16px', flexWrap: 'nowrap', flexDirection: 'column' }}>
                  {item.map(att => {
                    return <View style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                          <View style={{ borderRadius: '50%', border: '2px dashed #E3E3E3', width: '56px', height: '56px' }}><Img src={att.avatar}/></View>
                          <Text style={{ fontFamily: 'Roboto', fontSize: '12px' }}>{att.nome}</Text>
                        </View>
                      <Text style={{ fontSize: '12px' }}>Número de questões: {att.perguntas?.length}</Text>
                      {att.perguntas?.map(e => {
                        return (
                          GetPergunta(e, false)
                        )
                      })}
                    </View>
                  })}
                </View>
              </View>
            </View>
          </View>
          <Footer nomeEmpresa={model.nomeEmpresa}/>
        </Page>)
      })}
      {ArrayToChunks(model.atributos.filter(e => e.perguntas && e.perguntas?.length >= 8), 1).map(item => {
        return (
        <Page style={styles.DefaultPage}>
          <View>
            <Header
              texto={'Questões da pesquisa'}
              titulo={`${model?.nomePesquisa}`}
            />
            <View style={{ paddingLeft: 32, paddingRight: 32 }}>
              <Text
                style={{
                  textAlign: 'left',
                  paddingVertical: 32,
                  fontSize: 16,
                  color: '#203864'
                }}
              >
                Questões
              </Text>
              <View style={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
                <View style={{ display: 'flex', gap: '16px', flexWrap: 'nowrap', flexDirection: 'column' }}>
                  {item.map(att => {
                    return <View style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                          <View style={{ borderRadius: '50%', border: '2px dashed #E3E3E3', width: '56px', height: '56px' }}><Img src={att.avatar}/></View>
                          <Text style={{ fontFamily: 'Roboto', fontSize: '12px' }}>{att.nome}</Text>
                        </View>
                      <Text style={{ fontSize: '12px' }}>Número de questões: {att.perguntas?.length}</Text>
                      {att.perguntas?.map(e => {
                        return (
                          GetPergunta(e, false)
                        )
                      })}
                    </View>
                  })}
                </View>
              </View>
            </View>
          </View>
          <Footer nomeEmpresa={model.nomeEmpresa}/>
        </Page>)
      })}
    </Document>
  )
}
