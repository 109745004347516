import { Box, CircularProgress, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { ModalPoliticaPrivacidade } from '../../../../components/ModalPoliticaPrivacidade'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { appApi } from '../../../../services/appApi'
import { formatarData, FormatarMensagemEditorDeTexto, useQuery } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { Container, HeaderContainer } from './styles'

interface iAberturaPesquisa {
  id: string
  nomePesquisa: string
  msgAbertura?: string
  respondido: boolean
  encerrada: boolean
  naoIniciada: boolean
  dataInicio: string
  dataFim: string
  logoEmpresa: string
  token?: string
}

export const AberturaPesquisaPec: React.FC = () => {
  const param = useParams<{ tokenId: string, codigo: string }>()
  const nav = useNavigate()
  const query = useQuery()
  const { pathname } = useLocation()
  const [ModelPrivacidadeIsOpen, setModelPrivacidadeIsOpen] = useState(false)
  const [IsAccepted, setIsAccepted] = useState(false)
  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [Pesquisa, setPesquisa] = useState<iAberturaPesquisa>({
    id: '',
    nomePesquisa: '',
    msgAbertura: '',
    encerrada: false,
    respondido: false,
    logoEmpresa: '',
    naoIniciada: false,
    dataFim: '',
    dataInicio: ''
  })

  function getPesquisa(): void {
    setIsLoading(true)
    appApi.get(`ResponderPec/${param.codigo ? 'OpenLink' : 'Abertura'}/${param.tokenId ?? param.codigo}${pathname.includes('Previsualizar') ? '/Previsualizar' : ''}`)
      .then(res => {
        setPesquisa(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function ObterData(data: string): string {
    const newData = data.split('T')
    const dia = formatarData(newData[0])
    return `${dia} às ${newData[1].replace(':00', '')}`
  }

  useEffect(() => {
    getPesquisa()
  }, [])

  return (
    <Body>
      <ModalPoliticaPrivacidade
        isOpen={ModelPrivacidadeIsOpen}
        onClose={() => setModelPrivacidadeIsOpen(false)}
      />
      <WhiteContainer padding='0'>
        {isLoading && (
          <HeaderContainer>
            <Flex justifyContent={'center'} w={'full'} my={'8rem'} alignItems={'center'} gap={'.75rem'}>
              <CircularProgress size={38} isIndeterminate color='var(--Azul)' />
              <h3>
                Carregando sua pesquisa
              </h3>
            </Flex>

            <img alt='Logo da empresa' src={Pesquisa.logoEmpresa ?? 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'} />
          </HeaderContainer>)}

        {!isLoading && (
          <>
            <HeaderContainer>
              <h1>{Pesquisa.nomePesquisa}</h1>
              <img alt='Logo da empresa' src={Pesquisa.logoEmpresa ?? 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'} />
            </HeaderContainer>
            <Box bg='var(--DegradeAzul)' className={'gradient-blue'} h={'3px'} w={'full'}></Box>
          </>)}
        {!isLoading && (
          <Container>
            <Box mt={'.75rem'}>
              {!Pesquisa.encerrada && !Pesquisa.respondido && !Pesquisa.naoIniciada && (
                <>
                  <main>
                    <section>
                      <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(Pesquisa.msgAbertura ?? '') }}></span>
                      <br />
                      <br />
                    </section>
                    <p>
                      {'Essa pesquisa estará disponível em'} <strong>{ObterData(Pesquisa.dataInicio ?? '')}
                        {' e se encerrará em'} {ObterData(Pesquisa.dataFim ?? '')}.</strong>
                    </p>
                    <br />
                    <br />
                    <Flex flexDir={'column'} gap={'.75rem'} fontSize={'.875rem'} lineHeight={'1rem'} color={'var(--Gray3)'} fontWeight={'400'}>
                      <Text>
                        Após o envio de sua resposta, todas as informações que você nos disponibilizou serão registradas em nosso sistema como dados anonimizados, ou seja, que não poderão ser identificadas, nos termos do Art. 5º, incisos III e XI da Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018). Desta forma, não será possível vincular ou associar a sua resposta ao seu e-mail pessoal, usuário do app ou ao seu cadastro.
                      </Text>
                      <Text>
                        Cumpre ressaltar, ainda, que a LGPD dispõe em seu Art. 12 que todos os dados fornecidos pelo usuário e que são considerados anonimizados, não serão considerados, para todos os fins da LGPD, como dados pessoais, desde que o dado fornecido não permita, via meios técnicos e outros, que seja feito o processo de reconstrução e reversão do dado, de maneira que possibilite a identificação do usuário que disponibilizou a informação. Caso haja a identificação por algum meio, esse dado será considerdo como umdado pessoal e ficará sujeito às regras da LGPD.
                      </Text>
                    </Flex>
                  </main>
                  <Flex marginTop={'3.5rem'} direction={'column'}>

                    <Flex flexDir={'column'} gap={'.325rem'} alignItems={'center'} justifyContent={'center'} w={'full'}>
                      {!pathname.includes('Previsualizar') && <>
                      <Flex alignItems={'center'} mb={'.5rem'}>
                        <CheckboxCmp
                          fontSize='1rem'
                          OnChange={(e) => setIsAccepted(e)}
                        >{'Aceito os termos e política de privacidade'}
                        </CheckboxCmp>
                      </Flex>
                      <ButtonCmp
                        VarColor='DegradeAzul'
                        w={'32rem'}
                        isDisabled={!IsAccepted}
                        onClick={() => nav(query.get('m') ? `/Engajamento/Pec/${param.tokenId ?? Pesquisa.token}/ResponderPesquisa?m=q` : `/Engajamento/Pec/${param.tokenId ?? Pesquisa.token}/ResponderPesquisa`)}
                      >Responder pesquisa</ButtonCmp>
                      <Text fontSize={'.813rem'} fontWeight={'700'} color={'var(--Gray3)'} _hover={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setModelPrivacidadeIsOpen(true)}>{'Você precisa aceitar os termos e política de privacidade para continuar.'}</Text></>}

                      {pathname.includes('Previsualizar') && <ButtonCmp
                        VarColor='DegradeAzul'
                        w={'32rem'}
                        onClick={() => nav(-1)}
                      >Finalizar pré-visualização</ButtonCmp>}
                    </Flex>
                  </Flex>
                </>
              )}

              {(!Pesquisa.respondido && Pesquisa.naoIniciada) && (/// Não iniciada
                <main>
                  <section>
                    <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(Pesquisa.msgAbertura ?? '') }} ></span>
                    <br />
                    <br />
                  </section>
                  <p>
                    {'Essa pesquisa estará disponível em'} <strong>{ObterData(Pesquisa.dataInicio ?? '')}
                      {' e se encerrará em'} {ObterData(Pesquisa.dataFim ?? '')}.</strong>
                  </p>
                  <p>{'A partir da data de início, você poderá responder neste mesmo link.'}

                  </p>
                  <p>{'Te vejo em breve'}</p>
                </main>
              )}

              {!Pesquisa.respondido && Pesquisa.encerrada && !Pesquisa.naoIniciada && (
                <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                  <h1>{'Pesquisa já encerrada'}</h1>
                </Flex>
              )}

              {Pesquisa.respondido && (
                <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} my={'2rem'}>
                  <h2>Você já respondeu essa pesquisa!</h2>
                  <h3>Estamos aguardando os demais participantes responderem!</h3>
                </Flex>
              )}
            </Box>
          </Container>)}
      </WhiteContainer>
    </Body>
  )
}
