/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaCog, FaEye, FaFileCsv, FaPaperPlane, FaPlus } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { SearchCmp } from '../../../../components/SearchCmp'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iColaboradorBase, iDemografia, iDemografiaPEC } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { BoxCount } from './Components/BoxCount'
import { CircleText } from './Components/CircleText'
import { ModalCadastroEmMassa } from './Components/ModalCadastroEmMassa'
import { ModalCartaConvite } from './Components/ModalCartaConvite'
import { ModalConviteUsuario } from './Components/ModalConviteUsuario'
import { ModalLinkAberto } from './Components/ModalLinkAberto'
import { TableConvites } from './Components/Tabela'

interface ParticipantesResponse {
  participantes: Participante[]
  tokens: Token[]
  form: Participante
  demografias: iDemografia[]
  itemDemografiaId: string
  arquivo: File
  arquivo2: File
  msgCartaConvite: string
  codigo: string
  usuariosConvite: UsuarioGestaoConviteDto[]
  opcaoEncerrarPesquisa: number
  hInicio: string // TimeSpan can be represented as a string in "HH:mm:ss" format
  hFim: string // TimeSpan can be represented as a string in "HH:mm:ss" format
  usuarios: UsuariosConviteResponse
  listaConcatenada: ConcatenaLista[]
  ativo: boolean
  respostasPorLinkAberto: number
  tipoTabela?: number
}

interface UsuariosConviteResponse {
  areas: iDemografiaPEC[]
  colaboradores: iColaboradorBase[]
  usuariosRespondentes: UsuarioRespondente[]
  itemDemografiaId: string
  arquivo: File
  empresaId: string
  tipoUsuario: number
  email: string
}

export interface UsuarioRespondente {
  id: string
  nome: string
  email: string
  setor: string
  itemDemografiaId?: string
  cargo: number
  empresaId?: string
  nomeItemDemografia?: string
  tipoDeErro?: string
}

interface Token {
  id: string
  email: string
  celular: string
  codigoA: string
  codigoB: string
  whatsapp: boolean
  respondido: boolean
  linkAberto: boolean
  dataResposta: Date
  pesquisaPecId: string
  ativo: boolean
  removido: boolean
  dataCriacao: Date
  dataAtualizacao?: Date
}

export interface UsuarioGestaoConviteDto {
  participanteId: string
  tokenId: string
  itemDemografiaId?: string
  nome: string
  email: string
  tipoConvite: number
  nomeArea: string
  status: number
  selecionado: boolean
}

interface ConcatenaLista {
  usuarioId: string
  usuarioIdApp: number
  nome: string
  email: string
  nomeItemDemografia: string
  itemDemografiaId?: string
}

export interface Participante {
  id: string
  nome: string
  email: string
  celular: string
  whatsapp: boolean
  conviteEnviado: boolean
  tokenId?: string
  token?: Token
  ativo: boolean
  removido: boolean
  dataCriacao: Date
  dataAtualizacao?: Date
}

type iParamProps = {
  pesquisaId: string
}

export const ConvitesPec: React.FC = () => {
  const param = useParams<iParamProps>()
  const nav = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()

  const [ModalUsuarioIsOpen, setModalUsuarioIsOpen] = useState(false)
  const [ModalCartaIsOpen, setModalCartaIsOpen] = useState(false)
  const [CadastroEmMassaIsOpen, setCadastroEmMassaIsOpen] = useState(false)
  const [ModalLinkAbertoIsOpen, setModalLinkAbertoIsOpen] = useState(false)

  const [Model, setModel] = useState<ParticipantesResponse>()
  const [TxtPesquisa, setTxtPesquisa] = useState('')

  function getConvites(): void {
    appApi.get(`Pec/${param.pesquisaId as string}/Participantes`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function EnviarConvite(email: string): void {
    appApi.post(`Pec/${param.pesquisaId as string}/EnviarConvitesEmail`, { list: [email] })
      .then(() => {
        getConvites()
        if (Model?.participantes.find(e => e.email === email)?.conviteEnviado === true) {
          toast({
            title: 'Convite enviado com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        }
      })
      .catch(err => console.log(err))
  }

  function EnviarTodosNovos(): void {
    appApi.post(`Pec/${param.pesquisaId as string}/EnviarConvitesEmail`, { list: Model?.usuariosConvite.map(e => { if (e.email && e.status === 3) { return e.email } }).filter(e => { if (e !== null) { return e } }) })
      .then(() => {
        getConvites()
        toast({
          title: 'Convite enviado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
  }

  function UpdateCartaConvite(url?: string, msgConvite?: string): void {
    appApi.put(`Pec/${param.pesquisaId}/CartaConviteInfos`, { url: url, msgConvite: msgConvite })
      .then(() => {
        getConvites()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getConvites()
  }, [])

  return (
        <Body>
            {CadastroEmMassaIsOpen &&
              <ModalCadastroEmMassa
                pesquisaId={param.pesquisaId}
                isOpen={CadastroEmMassaIsOpen}
                onRequestClose={() => setCadastroEmMassaIsOpen(false)}
                reload={getConvites}
              />
            }
            {
                (Model) && (
                    <>
                        <ModalConviteUsuario
                            isOpen={ModalUsuarioIsOpen}
                            rows={Model.usuariosConvite.filter(e => e.tipoConvite !== 1)}
                            paramId={param.pesquisaId as string}
                            onClose={() => setModalUsuarioIsOpen(false)}
                            Reload={getConvites}
                        />
                        <ModalCartaConvite
                            isOpen={ModalCartaIsOpen}
                            paramId={param.pesquisaId as string}
                            onClose={() => setModalCartaIsOpen(false)}
                            onUpdate={UpdateCartaConvite}
                            Reload={getConvites}
                        />
                    </>
                )
            }

            <ModalLinkAberto
                codigo={Model?.codigo ?? ''}
                isOpen={ModalLinkAbertoIsOpen}
                onClose={() => setModalLinkAbertoIsOpen(false)}
            />

            <WhiteContainer>
                <Flex justifyContent={'space-between'}>
                    <h1>Gestão de convites</h1>
                    {
                        (Model) && (
                            <Flex gap={'0rem 1rem'} justifyContent={'end'} flexDir={'row'}>

                                <CircleText
                                    titulo={Model.usuariosConvite.filter(e => e.status === 0).length}
                                    fontsize='14px'
                                    varColorCircle='Rosa'
                                    varColorText='Preto'
                                >Convites enviados</CircleText>

                                <CircleText
                                    titulo={Model.usuariosConvite.filter(e => e.status !== 0 && e.status !== 2).length}
                                    fontsize='14px'
                                    varColorCircle='c5'
                                    varColorText='Preto'
                                >Novos convites a enviar</CircleText>

                                <CircleText
                                    titulo={Model.usuariosConvite.filter(e => e.status === 2).length}
                                    fontsize='14px'
                                    varColorCircle='secundario5'
                                    varColorText='Preto'
                                >Convites com erro</CircleText>
                            </Flex>
                        )
                    }
                    <ButtonCmp leftIcon={<FaFileCsv/>} onClick={() => setCadastroEmMassaIsOpen(true)} VarColor='Green2'>Subir convidados em massa</ButtonCmp>
                </Flex>
                <Flex flexDirection={'column'} margin={'1rem 0 1.5rem 0'}>
                    {
                        (Model) && (
                            <Flex gap={'0rem 1rem'}>
                                <BoxCount
                                    usuariosConvite={Model.usuariosConvite}
                                    tipo='usuario'
                                >
                                    <ButtonCmp
                                        VarColor='Green2'
                                        leftIcon={<FaPlus />}
                                        onClick={() => setModalUsuarioIsOpen(true)}
                                    >Adicionar convite</ButtonCmp>
                                </BoxCount>

                                <BoxCount
                                    usuariosConvite={Model.usuariosConvite}
                                    tipo='carta'
                                >
                                    <ButtonCmp
                                        VarColor='Green2'
                                        leftIcon={<FaPlus />}
                                        onClick={() => setModalCartaIsOpen(true)}
                                    >Criar carta</ButtonCmp>
                                </BoxCount>

                                <BoxCount
                                    usuariosConvite={[]}
                                    linkAberto={Model.respostasPorLinkAberto}
                                    tipo='qrcode'
                                >
                                    <ButtonCmp onClick={() => setModalLinkAbertoIsOpen(true)}
                                        VarColor={'Rosa'}
                                        leftIcon={<FaEye color='white' />}
                                    >Visualizar link</ButtonCmp>
                                </BoxCount>
                            </Flex>
                        )
                    }
                </Flex>

                {
                    (Model && Model.usuariosConvite?.length > 0) && (
                        <>
                            <Flex borderTop={'1px solid var(--c5)'} justifyContent={'space-between'} p={'1.5rem 0'}>
                                <h3>Lista de convidados</h3>
                                <SearchCmp
                                    EnterAction={() => {}}
                                    OnChange={setTxtPesquisa}
                                    placeholder='Buscar participante'
                                    width='18rem'
                                />
                            </Flex>
                            <TableConvites
                                rows={Model.usuariosConvite.filter((e) => {
                                  if (TxtPesquisa === '') return e
                                  else if (e?.nome?.toLowerCase().includes(TxtPesquisa.toLowerCase())) return e
                                  return null
                                })}
                                onCheck={() => { }}
                                onSend={EnviarConvite}
                                pesquisaId={param.pesquisaId}
                            />
                        </>
                    )
                }
                <Flex flexDirection={'row'} justifyContent={'end'} marginTop={'1.5rem'} gap='.5rem' >
                    <ButtonCmp onClick={() => nav(-1)} leftIcon={<FaAngleLeft />}VarColor='c6'>Voltar</ButtonCmp>
                    <ButtonCmp leftIcon={<FaCog />}VarColor='Rosa'
                        onClick={() => nav(pathname.replace('Convites', 'ConfigurarEnvio'))}>Configuração da pesquisa</ButtonCmp>
                    <ButtonCmp
                        leftIcon={<FaPaperPlane />}VarColor='Green2' onClick={() => EnviarTodosNovos()}>Disparar novos convites</ButtonCmp>
                </Flex>
            </WhiteContainer>
        </Body>
  )
}
