/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'
import { GraficoPEC } from '../../interfaces'

interface IGrafico {
  id: string
  dados: GraficoPEC
  legendas?: string[]
  questoesAfetam?: boolean
  alavancagem?: boolean
}

export const BarChart: React.FC<IGrafico> = ({ id, dados, legendas, questoesAfetam, alavancagem }) => {
  function GetSeries(): any {
    const resp: any[] = []
    if (dados.serie) {
      for (let i = 0; i < dados.serie?.length; i++) {
        if (alavancagem && dados.serieX && dados.serieX[i]) {
          resp.push(dados.serieX[i])
        } else {
          resp.push(dados.serie[i])
        }
      }
    }

    return [{ name: alavancagem ? 'Alavancagem' : 'Média', data: resp }]
  }
  async function GerarGrafico(): Promise<void> {
    const options = {
      colors: questoesAfetam ? ['#da84d5', 'rgb(123, 229, 194)'] : alavancagem ? ['rgb(209, 255, 117)'] : ['rgba(0, 180, 90, 0.9)'],
      series: GetSeries(),
      chart: {
        type: 'bar',
        height: 450,
        width: '100%'
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          if (val === -1) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          },
          distributed: true
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: alavancagem ? ['Alavancagem'] : legendas ?? ['Média'],
        position: 'top',
        fontSize: '16px',
        itemMargin: {
          horizontal: 10
        },
        markers: {
          size: 11,
          offsetX: -3,
          background: 'rgba(0, 180, 90, 0.9)'
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: alavancagem ? dados.serieX?.some(e => e && e > 1.4) ? 1.6 : dados.serieX?.some(e => e && e > 1) ? 1.4 : dados.serieX?.some(e => e && e > 0.6) ? 1 : 0.6 : 10,
        tickAmount: 10,
        show: true,
        labels: {
          formatter: function(val: string) {
            return parseFloat(val).toFixed(1)
          }
        }
      },
      xaxis: {
        categories: dados.labels,
        labels: {
          rotate: 0,
          hideOverlappingLabels: false,
          trim: true
        }
      },
      fill: {
        opacity: 1,
        colors: questoesAfetam ? dados.afetaEngajamento.map(e => e ? 'rgb(123, 229, 194)' : '#da84d5') : alavancagem ? ['rgb(209, 255, 117)'] : ['rgba(0, 180, 90, 0.9)']
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [dados])

  return (
        <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}
