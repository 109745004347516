/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { ModalBase } from '../../../../../../components/ModalBase'
import { iModalPadrao } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { ModalBody } from './styles'

interface IModal extends iModalPadrao {
  onCreate: (nome: string, avatar: string, questao: string) => void
}

export const ModalCriarAtributo: React.FC<IModal> = ({ OnCloseModal, isOpen, onCreate }) => {
  if (!isOpen) {
    return null
  }
  const toast = useToast()

  const [Nome, setNome] = useState('')
  const [Questao, setQuestao] = useState('')

  const [Imagens, setImagens] = useState<string[]>([])
  const [Avatar, setAvatar] = useState('')

  function getImgs(): void {
    appApi.get('Assets').then(response => {
      setImagens((response.data as string[]).filter(e => e.includes('svg')))
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getImgs()
  }, [])

  return (
    <ModalBase
      Titulo='Criar competência'
      isOpen={isOpen}
      onClose={OnCloseModal}
      Width='50rem'
    >
      <ModalBody>
        <h4 style={{ marginBottom: '.25rem' }}>Selecione um ícone</h4>
        <section>
          {
            Imagens.map((e: string, i) => {
              if (e === Avatar) {
                return (
                  <img style={{ border: '3px solid var(--Rosa)' }} key={i} src={e} />
                )
              } else {
                return (
                  <img onClick={() => setAvatar(e)} key={i} src={e} />
                )
              }
            })
          }
        </section>

        <Flex margin={'.5rem 0'} flexDir={'column'} gap={'.75rem'}>
          <InputCmp
            label='Nome do atributo'
            value={Nome}
            placeholder='Digite o nome do atributo'
            OnChange={setNome}
          />
          <InputCmp
            label='Questão'
            value={Questao}
            placeholder='Digite a questão'
            OnChange={setQuestao}
          />
        </Flex>
      </ModalBody>
      <Flex justifyContent={'end'} px={'1rem'} py={'.5rem'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={() => OnCloseModal()}>Fechar</ButtonCmp>
        <ButtonCmp VarColor='Green2' onClick={() => {
          if (Questao !== '' && Avatar !== '' && Nome !== '') {
            onCreate(Nome, Avatar, Questao)
            OnCloseModal()
          } else {
            toast({
              title: 'Preencha os campos corretamente!',
              status: 'error',
              duration: 4000
            })
          }
        }}>Criar competência</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}
