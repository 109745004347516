import { Flex } from '@chakra-ui/react'
import { Container } from './styles'
interface iProps {
  onSelect: () => void
  text: string
  icon: any
  color: string
}

export const CardCultura: React.FC<iProps> = ({ color, icon, onSelect, text }) => {
  return (
        <Container
            color={color}
            onClick={onSelect}
        >
            <Flex w={'6rem'} borderRadius={'8px 0 0 8px'} background={`var(--${color})`} color={'var(--c1)'} justifyContent={'center'} alignItems={'center'} p={'1rem'} fontSize={'2rem'}>
              {icon}
            </Flex>
            <Flex w={'100%'} background={'var(--c3)'} borderRadius={'0 8px 8px 0'} fontWeight={600} padding={'0 2rem'} alignItems='center'>
              {text}
            </Flex>
        </Container>
  )
}
