import { Flex } from '@chakra-ui/react'
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa'
import { PerguntaPec } from '../../../../../../interfaces'
import { GraficoPEC, ResultadoAnovaPergunta } from '../../interfaces'
import { TableDiv } from './styles'

interface iProps {
  perguntas?: PerguntaPec[]
  demografia: GraficoPEC
  maozinhas: ResultadoAnovaPergunta[]
}

export const TabelaMaozinha: React.FC<iProps> = ({ maozinhas, demografia, perguntas }) => {
  return (
    <Flex width={'100%'}>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>Questão</th>
              {demografia.labels?.map((e, i) => {
                if (demografia.serie && demografia.serie[i] >= 5) {
                  return <th>{e}</th>
                }
                return null
              })}
            </tr>
          </thead>
          <tbody>
            {perguntas?.map((perg, i) => {
              return (
                <tr key={i}>
                  <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {perg.pergunta}
                  </td>
                  {demografia.numeroPergunta?.map((e, i) => {
                    if (demografia.serie && demografia.serie[i] >= 5) {
                      const maozinha = maozinhas.find(m => m.perguntaId === perg.id)?.demografias.find(d => d.demografiaId === demografia.id && d.grafico.some(item => item.itemDemografiaId === e))
                      return (
                        <td>
                          {maozinha
                            ? maozinha.grafico.find(g => g.itemDemografiaId === e)?.praCima
                              ? <Flex justifyContent={'center'}><FaThumbsUp color='var(--Green2)' size={'1.5rem'}/></Flex>
                              : <Flex justifyContent={'center'}><FaThumbsDown color='var(--Orange)' size={'1.5rem'}/></Flex>
                            : '-'}
                        </td>
                      )
                    }
                    return null
                  })}
                </tr>
              )
            })}
          </tbody>

        </table>
      </TableDiv>
    </Flex>
  )
}
