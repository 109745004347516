/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, FormControl, Img, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text, useToast } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { FaDownload, FaKey, FaSave, FaTrash } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { appApi } from '../../../../../../services/appApi'
import { ModalBase } from '../../../../../../components/ModalBase'
import { FormatarMensagemCartaConvite, PropInvalida, UpdateElementByPosition } from '../../../../../../Utils/Helper'
import { UsuarioGestaoConviteDto } from '../..'
import { FileInput } from '../ModalComunicacao/styles'
import { EditorTexto } from '../../../../../../components/EditorTexto/Index'
import { TableCartaConvite } from '../TabelaCartaConvite'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RelatorioPDF } from './RelatorioPDF'
import { iCartaConvite } from '../../../CartaConvite'
import html2canvas from 'html2canvas'
import { WhiteContainer } from '../../../../../../components/WhiteContainer'

interface iModalProps {
  isOpen: boolean
  paramId: string
  Reload: () => void
  onUpdate: (Url?: string, msgConvite?: string) => void
  onClose: () => void
}

interface iModel {
  convites: UsuarioGestaoConviteDto[]
  msgConvite: string
  urlImagem: string
}

export const ModalCartaConvite: React.FC<iModalProps> = ({ isOpen, paramId, onClose, Reload, onUpdate }) => {
  const containersRef = useRef<HTMLDivElement[]>([])
  const [imageBase64, setImageBase64] = useState<string[]>([])
  const toast = useToast()

  const [IsLoading, setIsLoading] = useState(false)
  const [Selecionados, setSelecionados] = useState<string[]>([])
  const [Model, setModel] = useState<iModel>({ convites: [], msgConvite: '', urlImagem: '' })
  const [ModelSelecionadas, setModelSelecionadas] = useState<iCartaConvite[]>()
  const [qtdCartas, setQtdCartas] = useState(0)
  const [BaixarRelatorio, SetBaixarRelatorio] = useState(false)

  const [Url, setUrl] = useState('')
  const [Name, setName] = useState('')

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    if (Url !== undefined && Url.length > 0) {
      appApi.post(`UploadFile?url=${Url}`, formdata).then(resposnse => {
        setUrl(resposnse.data.uri)
        toast({
          title: 'Arquivo atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      }).catch(e => console.log(e))
    } else {
      appApi.post('UploadFile', formdata).then(resposnse => {
        setUrl(resposnse.data.uri)
        setName(resposnse.data.name)
        toast({
          title: 'Arquivo cadastrado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      }).catch(e => console.log(e))
    }
  }

  function onCheck(tokenId: string, all?: boolean): void {
    if (!PropInvalida(all)) {
      if (all) {
        setSelecionados(Selecionados.concat(Model.convites.filter(r => !Selecionados.some(t => t === r.tokenId)).map((e) => { return e.tokenId })))
      } else {
        setSelecionados([])
      }
    } else {
      const copy = [...Selecionados]
      if (copy.find(e => e === tokenId) === undefined) {
        copy.push(tokenId)
        setSelecionados(copy)
      } else {
        setSelecionados([])
        const filtrado = copy.filter(e => e !== tokenId)
        setSelecionados(filtrado)
      }
    }
  }

  function GerarCartasConvite(): void {
    appApi.post(`Pec/${paramId}/GerarCartasConvite?qtd=${qtdCartas ?? 0}`)
      .then(() => {
        Reload()
        GetCartaConvites()
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function GetCartaConvites(): void {
    setIsLoading(true)
    appApi.get(`Pec/${paramId}/CartasConvite`)
      .then(res => {
        const resp: iModel = res.data
        if (!resp.convites) resp.convites = []
        if (!resp.msgConvite) resp.msgConvite = ''
        if (!resp.urlImagem) resp.urlImagem = ''
        setModel(resp)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function onDeleteConvites(): void {
    appApi.post(`Pec/${paramId}/DeletarCartaConvites`, { list: Selecionados })
      .then(() => {
        Reload()
        GetCartaConvites()
        setSelecionados([])
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetCartaConvites()
  }, [])

  useEffect(() => {
    if (BaixarRelatorio) {
      appApi.post(`Pec/${paramId}/CartasConvite`, { list: Selecionados })
        .then(res => setModelSelecionadas(res.data))
        .catch(err => console.log(err))
    } else {
      setModelSelecionadas(undefined)
    }
  }, [BaixarRelatorio])

  const handleGenerateImages = async () => {
    const generatedImages: string[] = []
    setTimeout(async () => {
      for (const container of containersRef.current) {
        if (container !== null) {
          try {
            const canvas = await html2canvas(container)
            const dataUrl = canvas.toDataURL('image/png')
            generatedImages.push(dataUrl)
          } catch (error) {
            console.error('Error generating image:', error)
          }
        }
      }
      console.log(generatedImages)
      setImageBase64(generatedImages)
    }, 3000)
  }

  useEffect(() => {
    if (imageBase64.length > 0) {
      setTimeout(() => {
        const botao: any = document.getElementById('botaoBaixarRelatorio')
        const link = document.createElement('a')
        link.href = botao.href
        link.download = 'Carta_Convite_Pesquisa_de_Engajamento.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        SetBaixarRelatorio(false)
      }, 3000)
    } else {
      SetBaixarRelatorio(false)
      setModelSelecionadas(undefined)
    }
  }, [imageBase64])

  return (
    <ModalBase
      Titulo="Carta convite"
      Subtitulo="Selecione a quantidade de chaves que deseja gerar, assim cada carta convite terá uma chave de acesso diferente, garantindo assim que cada colaborador consiga responder apenas uma vez."
      isOpen={isOpen}
      onClose={onClose}
      Width='56rem'
    >
      {ModelSelecionadas?.map((model, index) => (
        <Flex pos={'absolute'} left={-5000 * (index + 1)}>
          <WhiteContainer key={index}>
            <Flex ref={(el) => { if (el !== null) { containersRef.current[index] = el } }} minH={'60rem'} maxH={'100rem'} padding={'3rem'} gap={'2rem'} flexDir='column'>
              <Flex flexDir={'column'} gap={'1rem'} alignItems={'center'}>
                <Text fontSize={'36px'} fontWeight={700}>PESQUISA DE ENGAJAMENTO</Text>
                <Box bg='var(--DegradeAzul)' className={'gradient-blue'} h={'14px'} w={'full'}></Box>
              </Flex>
              <Flex>
                <Flex flexDir={'column'} gap={'1rem'} maxW={'20%'} minW={'20%'}>
                  <Img src={model.logoEmpresa} objectFit={'contain'} minH={'16rem'} maxH={'22rem'} />
                  {model.urlImagem && <Img src={model.urlImagem} objectFit={'contain'} minH={'16rem'} maxH={'22rem'} />}
                  <Img src={model.qrCode} objectFit={'contain'} minW={'20rem'} maxW={'20rem'} minH={'20rem'} maxH={'20rem'} />
                </Flex>
                <Flex flexDir={'column'} gap={'1rem'} maxW={'70%'} minW={'70%'}>
                  <span dangerouslySetInnerHTML={{ __html: FormatarMensagemCartaConvite(model.msgConvite ?? '') }}></span>
                </Flex>
              </Flex>
            </Flex>
          </WhiteContainer>
        </Flex>
      ))}
      <Flex padding={'0 1rem 1rem 1rem'} flexDir={'column'}>
        <FormControl as={Flex} alignItems={'center'} gap={'.75rem'} mb={'1rem'}>
          <Text fontWeight={700} fontSize={'14px'} minWidth={'13.5rem'} maxWidth={'13.5rem'}>Quantidade de chaves para gerar:</Text>
          <NumberInput min={0} h={'1.95rem'} w={'100%'} value={qtdCartas} onChange={(e) => setQtdCartas(parseInt(e))}>
            <NumberInputField h={'1.95rem'} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <ButtonCmp VarColor='Green2' w={'10rem'} onClick={GerarCartasConvite} leftIcon={<FaKey/>} isDisabled={qtdCartas <= 0 || !qtdCartas}>Gerar chaves</ButtonCmp>
        </FormControl>

        <Flex boxShadow={'var(--SombraBackground)'} flexDir={'column'} marginBottom={'1.25rem'} bg={'var(--c2)'} padding={'1rem'} borderRadius={'.25rem'} fontSize={'.875rem'}>
          {Model.urlImagem && <Flex flexDir={'column'} gap={'.325rem'} mb={'1rem'}>
            <Text fontSize={'14px'} fontWeight={700} lineHeight={'14px'}>Foto para carta convite</Text>
            <Img src={Model.urlImagem} maxH={'13rem'} maxW={'15rem'} objectFit='contain' border={'2px solid var(--c4)'}/>
          </Flex>}
          <span>Selecione um arquivo de imagem (*.jpg ou *.png) ou de vídeo(*.mp4) com o vídeo de sensibilização (tamanho max. 30MB)</span>
          <FileInput>
            <input
              id='i-Com'
              type={'file'}
              onChange={AtualizarArquivo}
              style={{ display: Url.length > 0 ? 'none' : 'inherit', borderRadius: '.25rem' }}
            />
            {Url.length > 0 &&
              <Flex alignItems={'center'}>
                <label htmlFor='i-Com'>Trocar arquivo</label>
                <span>Arquivo selecionado: <strong>{Name}</strong></span>
              </Flex>
            }
          </FileInput>
          <Flex justifyContent={'end'} mt={'1rem'}>
            <ButtonCmp VarColor='Green2' leftIcon={<FaSave/>} isDisabled={!Url} onClick={() => { onUpdate(Url); setTimeout(() => { GetCartaConvites() }, 1300) }}>Salvar imagem</ButtonCmp>
          </Flex>
        </Flex>

        <Flex boxShadow={'var(--SombraBackground)'} flexDir={'column'} marginBottom={'1rem'} bg={'var(--c2)'} padding={'1rem'} borderRadius={'.25rem'} fontSize={'.875rem'}>
          <Flex justifyContent={'space-between'}>
            <Flex flexDir={'column'}>
              <h4><strong>Texto da carta convite</strong></h4>
              <Text
                fontWeight={'500'}
                fontSize='0.813rem'
                style={{ marginBottom: '.5rem' }}
              >Esse texto aparecerá na carta convite, você pode editá-lo da maneira que mais agradar sua organização. Recomendamos que não altere os campos onde aparecem o LINK e a SENHA. Para ver exatamente como irá ficar, você pode pré-visualizar clicando no botão abaixo da caixa de edição de texto.</Text>
            </Flex>
          </Flex>
          <EditorTexto isLoading={IsLoading} initialValue={Model.msgConvite ?? ''}
            onChange={(e: string) => setModel({ ...Model, msgConvite: e })}
          />
          <Flex justifyContent={'end'} mt={'1rem'}>
            <ButtonCmp VarColor='Green2' leftIcon={<FaSave/>} onClick={() => { onUpdate(undefined, Model.msgConvite); setTimeout(() => { GetCartaConvites() }, 1300) }}>Salvar texto</ButtonCmp>
          </Flex>

        </Flex>
        {
          (Model.convites !== undefined && Model.convites.length > 0) && (
            <>
              <Flex marginBottom={'1rem'} fontWeight={'400'} justifyContent={'space-between'} alignItems={'center'}>
                <h3>Convites criados</h3>
                <Flex gap={'.5rem'}>
                <ButtonCmp isLoading={IsLoading || BaixarRelatorio} VarColor='Green2' leftIcon={<FaDownload/>} onClick={() => { SetBaixarRelatorio(true); void handleGenerateImages() }} isDisabled={Selecionados.length === 0}>Baixar cartas selecionadas</ButtonCmp>
                {BaixarRelatorio && ModelSelecionadas && imageBase64.length > 0 && (
                  <PDFDownloadLink
                    document={
                      <RelatorioPDF
                        model={imageBase64}
                      />
                    }
                    id='botaoBaixarRelatorio'
                  >
                    {() =>
                      <Box pos={'absolute'}></Box>
                    }
                  </PDFDownloadLink>
                )}
                  <ButtonCmp leftIcon={<FaTrash />} VarColor={'terc3'} onClick={onDeleteConvites}>Excluir convites selecionados</ButtonCmp>
                </Flex>
              </Flex>
              <TableCartaConvite
                rows={Model.convites}
                onCheck={onCheck}
                selecionados={Selecionados}
                pesquisaId={paramId}
              />
            </>
          )
        }
      </Flex>
      <Flex padding={'0 1rem 1rem 0'} justifyContent={'end'} gap={'.5rem'}>
        <ButtonCmp VarColor='c5' onClick={onClose}>Voltar</ButtonCmp>
        <ButtonCmp VarColor='Green2' onClick={onClose}>Continuar</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}
