/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Flex, Text } from '@chakra-ui/react'
import { FaChartLine, FaClipboardList, FaCogs, FaEye, FaHeart, FaTasks } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { iClusterPesquisa } from '../../../../../../interfaces'

interface iProps {
  model: iClusterPesquisa
  customName?: string
  color: string
  OnAction: (name: string) => void
}

export const ClusterCard: React.FC<iProps> = ({ model, color, OnAction, customName }) => {
  const getIconByName = (name: string): any => {
    switch (customName ?? name) {
      case 'Estratégia e execução':
        return <FaTasks color={`var(--${color})`} size={28}/>
      case 'Função e desenvolvimento':
        return <FaChartLine color={`var(--${color})`} size={28}/>
      case 'Relacionamento e sentimento':
        return <FaHeart color={`var(--${color})`} size={28}/>
      case 'Atributos personalizados':
        return <FaCogs color={`var(--${color})`} size={28}/>
      default:
        return <FaClipboardList color={`var(--${color})`} size={28}/>
    }
  }

  return (
    <Flex background={`var(--${color})`} borderRadius={'.325rem'} w={'100%'}>
      <Flex border={`1px solid var(--${color})`} alignItems={'center'} justifyContent={'center'} gap={'.5rem'} bg={'var(--c1)'} onClick={() => OnAction(model.nome)} p={'.75rem .5rem'} w='100%' borderRadius={'.25rem'} cursor={'pointer'} flexDir='column' boxShadow={'var(--SombraBtns)'} _hover={{
        transform: 'translateY(-2px) translateX(-2px)',
        transition: 'transform 0.3s, filter 0.3s',
        filter: 'brightness(1.03)',
        border: `1px solid var(--${color}-hover)`
      }}>
        <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'center'} w='full'>
          <Flex alignItems={'center'} justifyContent='center' maxW={'3.5rem'} minW={'3.5rem'} h='3.5rem' borderRadius={'50%'} border={`2px dashed var(--${color})`}>
            {getIconByName(model.nome)}
          </Flex>
          <Text fontSize={'16px'} w='fit-content' fontWeight={'700'} color={`var(--${color})`}>{customName ?? `Atributos de ${model.nome}`}</Text>
        </Flex>
        <ButtonCmp small VarColor={color} leftIcon={<FaEye/>}>Visualizar atributos</ButtonCmp>
      </Flex>
    </Flex>
  )
}
