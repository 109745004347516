import { Flex, Text } from '@chakra-ui/react'
import { FaEnvelopeOpenText, FaMobile, FaQrcode } from 'react-icons/fa'
import { UsuarioGestaoConviteDto } from '../..'
import { CircleText } from '../CircleText'

interface iBoxProps {
  usuariosConvite: UsuarioGestaoConviteDto[]
  linkAberto?: number
  tipo: 'carta' | 'qrcode' | 'usuario'
}

export const BoxCount: React.FC<iBoxProps> = ({ usuariosConvite, tipo, linkAberto, children }) => {
  return (
        <Flex width={'50%'} justifyContent='space-between' padding={'1rem'} flexDir={'column'} border={'1px solid var(--c5)'} borderRadius={'4px'} alignItems={'center'} minH={'200px'} maxHeight={'200px'} fontSize={'.875rem'}>
            <Flex marginBottom={'1rem'} justifyContent={'center'} alignItems={'center'} height='100%' gap={'.75rem'}>
                        <Flex color={'black'} flexDir={'column'} alignItems={'center'} maxHeight={'100px'}>
                    {
                        tipo === 'carta'
                          ? <FaEnvelopeOpenText size={40} />
                          : tipo === 'qrcode'
                            ? <FaQrcode size={40} />
                            : <FaMobile size={40} />
                    }
                    <Text fontWeight={'bold'} style={{ marginTop: '.5rem' }}>{tipo === 'carta'
                      ? <Flex flexDir={'column'} alignItems={'center'}>
                        <Text fontWeight={'900'}>Carta convite</Text>
                        <CircleText
                            titulo={usuariosConvite.filter(e => e.tipoConvite === 1).length}
                            fontsize='14px'
                            varColorCircle='Rosa'
                            varColorText='Preto'
                        >Cartas convite geradas</CircleText>
                      </Flex>
                      : tipo === 'qrcode'
                        ? <Flex flexDir={'column'} alignItems={'center'}>
                    <Text fontWeight={'900'}>Link aberto</Text>
                    <CircleText
                        fontsize='14px'
                        varColorCircle='Green3'
                        varColorText='Gray1'
                        titulo={linkAberto}
                    >Respostas por link aberto</CircleText>
                    </Flex>
                        : <Flex flexDir={'column'} alignItems={'center'}>
                        <Text fontWeight={'900'}>Usuários</Text>
                        <CircleText
                            titulo={usuariosConvite.filter(e => e.status === 0 && (e.tipoConvite === 0 || e.tipoConvite === 2)).length}
                            fontsize='14px'
                            varColorCircle='Rosa'
                            varColorText='Preto'
                        >Convites enviados</CircleText>

                        <CircleText
                            titulo={usuariosConvite.filter(e => e.status !== 0 && e.status !== 2 && (e.tipoConvite === 0 || e.tipoConvite === 2)).length}
                            fontsize='14px'
                            varColorCircle='c5'
                            varColorText='Preto'
                        >Novos convites a enviar</CircleText>
                        </Flex>}</Text>
                </Flex>
            </Flex>
            <Flex justifyContent={'center'} height='fit-content' alignItems={'end'}>
                {children}
            </Flex>
        </Flex>
  )
}
