import { Box, Flex, Img } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { appApi } from '../../../../services/appApi'
import { FormatarMensagemEditorDeTexto } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'

export interface iCartaConvite {
  msgConvite: string
  urlImagem?: string
  qrCode: string
  logoEmpresa: string
}

export const CartaConvite: React.FC = () => {
  const { pesquisaId, id } = useParams<{ pesquisaId: string, id: string }>()
  const [Model, setModel] = useState<iCartaConvite>({
    msgConvite: '',
    urlImagem: '',
    qrCode: '',
    logoEmpresa: ''
  })

  function getCartaConvite(): void {
    appApi.get(`Pec/${pesquisaId as string}/CartaConvite/${id}`)
      .then(res => {
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getCartaConvite()
  }, [])

  return (
    <Body>
      <WhiteContainer>
        <Flex gap={'2rem'}>
          <Flex flexDir={'column'} gap={'1rem'} w={'20%'}>
            <Img src={Model.logoEmpresa} objectFit={'contain'} h={'11rem'}/>
            {Model.urlImagem && <Img src={Model.urlImagem} objectFit={'contain'} h={'11rem'}/>}
            <Img src={Model.qrCode} objectFit={'contain'} h={'11rem'}/>
          </Flex>
          <Flex flexDir={'column'} gap={'1rem'} w={'80%'}>
            <Flex flexDir={'column'} gap={'.5rem'} alignItems={'center'}>
              <h2>PESQUISA DE ENGAJAMENTO</h2>
              <Box bg='var(--DegradeAzul)' className={'gradient-blue'} h={'8px'} w={'full'}></Box>
            </Flex>
            <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(Model.msgConvite ?? '') }}></span>
          </Flex>
        </Flex>
      </WhiteContainer>
    </Body>
  )
}
