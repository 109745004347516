import styled from 'styled-components'

export const InfoBox = styled.div`
    padding: 1rem;
    background: var(--Azul);
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .75rem;
    color: #fff;
    border-radius: .125rem;
    svg {
        width: 1.75rem;
        height: 1.75rem;
    }
    span {
        font-size: .95rem;
    }
    p {
        font-weight: 700;
    }
`
