/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { useEffect, useState } from 'react'
import { GraficoPEC } from '../../interfaces'

interface iProps {
  id: string
  dados: GraficoPEC[]
  legendas?: string[]
  questoesAfetam?: boolean
  colorsAlternative?: string[]
  atributosComparativo?: boolean
  onSetGrafico?: (str: string) => void
}

export const ComparativoChart: React.FC<iProps> = ({ id, dados, legendas, questoesAfetam, colorsAlternative, atributosComparativo, onSetGrafico }) => {
  const [Position, setPosition] = useState('')
  const [OffsetY, setOffsetY] = useState(0)
  const [Orientation, setOrientation] = useState('')

  function getSeries(): any[] {
    const response: any[] = []
    if (dados[0]?.labels && dados[0]?.serie && dados[1]?.serie) {
      if (dados[0]?.labels?.length < 12) {
        setPosition('top')
        setOrientation('horizontal')
        setOffsetY(-20)
      } else {
        setPosition('top')
        setOffsetY(-40)
        setOrientation('vertical')
      }
      response.push({
        name: dados[0].labels[0],
        data: dados[1].serie.map((e, i) => {
          return {
            y: e,
            x: dados[0].labels?.[i],
            strokeColor: ((dados[1].afetaEngajamento?.[i] && questoesAfetam) && !(atributosComparativo && i === 0)) ? 'rgb(3, 112, 21)' : undefined,
            fillColor: (atributosComparativo && i === 0) ? 'rgb(56, 70, 176)' : undefined
          }
        }),
        afetam: dados[1].afetaEngajamento
      })
      response.push({
        name: dados[0].labels[0],
        data: dados[0].serie.map((e, i) => {
          return {
            strokeColor: (dados[0].afetaEngajamento?.[i] && questoesAfetam && !(atributosComparativo && i === 0)) ? 'rgb(3, 112, 21)' : undefined,
            fillColor: (atributosComparativo && i === 0) ? 'rgb(56, 99, 176)' : undefined,
            y: e,
            x: dados[0].labels?.[i]
          }
        }),
        afetam: dados[0].afetaEngajamento
      })
    }

    return response
  }

  const colors = [
    '#fea611',
    'rgb(56, 99, 176)',
    '#ffb347',
    '#ffcc66',
    '#366ed8',
    '#285bac',
    '#fff'
  ]

  function GetColors(): string[] {
    if (questoesAfetam) {
      return ['#7bbde3', '#da84d5']
    }

    return colors
  }

  async function GerarGrafico(): Promise<void> {
    const options = {
      colors: GetColors(),
      series: getSeries(),
      labels: dados[0].labels,
      chart: {
        type: 'bar',
        height: '450px',
        width: '100%'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'rounded',
          dataLabels: {
            position: Position,
            orientation: Orientation
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: OffsetY,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          if (val === -1 || val === null) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: legendas ?? ['Média dos atributos', 'Média do engajamento'],
        position: 'top',
        fontSize: '16px',
        itemMargin: {
          horizontal: 10
        },
        markers: {
          strokeDashArray: 2,
          fillColors: colorsAlternative ? colorsAlternative : questoesAfetam ? ['rgb(3, 112, 21)', '#7bbde3', '#da84d5'] : undefined
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: dados[0].labels,
        labels: {
          formatter: function (value: any) {
            return value.length > 24 ? value.substring(0, 24) + '...' : value
          }
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: 10,
        show: true,
        labels: {
          formatter: function (value: any) {
            return value.toFixed(1)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          show: false,
          formatter: function (val: number) {
            if (val === -0.01) return ''
            return val.toFixed(2)
          },
          title: {
            formatter: function (val: number, { series, seriesIndex }: { series: any, seriesIndex: any }) {
              return seriesIndex === 0 ? 'Média dos atributos' : 'Média do engajamento'
            }
          }
        },
        x: {
          formatter: function (val: any, { series, seriesIndex, w }: { series: any, seriesIndex: any, w: any }) {
            return val
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()

    chart.updateSeries(getSeries())

    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })

    if (onSetGrafico) {
      onSetGrafico(dataURL.imgURI)
    }
  }

  useEffect(() => {
    GerarGrafico()
  }, [dados])

  return (
        <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}
