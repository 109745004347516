/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Grid, GridItem, Menu, MenuList, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ModalDelete } from '../../../../components/ModalDelete'
import { StyledMenuButton } from '../../../../components/NavBar/Components/StyledMenuButton'
import { StyledMenuItem } from '../../../../components/NavBar/Components/StyledMenuItem'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iDemografiaPEC, iPostDemografiaPEC } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { TimelineCriarPEC } from '../Components/Timeline'
import { ModalCreateDemografia } from './Components/ModalCreateDemografia'
import { ModalUpdateDemografia } from './Components/ModalUpdateDemografia'
import { Li } from './styles'

export const PECDemografia: React.FC = () => {
  const nav = useNavigate()
  const path = useLocation().pathname
  const toast = useToast()
  const { pesquisaId } = useParams()

  const [ModalAddDemografia, setModalAddDemografia] = useState(false)
  const [ModalUpeDemografia, setModalUpDemografia] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [Selecionados, setSelecionados] = useState<string[]>([])
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

  const [DemografiaEdit, setDemografiaEdit] = useState<iDemografiaPEC>()
  const [DemografiaDelete, setDemografiaDelete] = useState<iDemografiaPEC>()
  const [Demografias, setDemografias] = useState<iDemografiaPEC[]>([])
  const [MouseIn, setMouseIn] = useState<string>()
  const [DemografiasAdd, setDemografiasAdd] = useState<string[]>([])

  function onAddDemografia(e: iDemografiaPEC): void {
    if (DemografiasAdd?.some(a => a === e.id)) {
      setDemografiasAdd(DemografiasAdd?.filter(a => a !== e.id))
    } else {
      setDemografiasAdd([...DemografiasAdd, e.id])
    }
    setIsLoading(true)
    appApi.patch(`PEC/${pesquisaId}/Demografia/${e.id}`)
      .then()
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function getDemografias(): void {
    setIsLoading(true)
    appApi.get('PEC/Demografias')
      .then(res => setDemografias(res.data))
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function AlterarSelecaoDemografia(demId: string): void {
  }

  function PostDemografia(form: iPostDemografiaPEC): void {
    appApi.post('PEC/Demografia', form)
      .then(res => {
        setModalAddDemografia(false)
        getDemografias()
        toast({
          title: 'Demografia adicionada com sucesso',
          position: 'top',
          duration: 4000,
          status: 'success'
        })
      })
      .catch(err => console.log(err))
  }

  function PutDemografia(form: iDemografiaPEC): void {
    form.demografias?.forEach((e: any) => {
      if (e.id === '' || !e.id) {
        e.id = undefined
      }
    })
    setIsLoading(true)
    appApi.put('PEC/Demografia', form)
      .then(() => {
        setModalUpDemografia(false)
        getDemografias()
        toast({
          title: 'Demografia atualizada com sucesso',
          duration: 4000,
          status: 'success',
          position: 'top'
        })
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function onOpenEditDemografia(dem: iDemografiaPEC): void {
    setModalUpDemografia(true)
    setDemografiaEdit(dem)
  }

  function onCloseEditDemografia(): void {
    setModalUpDemografia(false)
    setDemografiaEdit(undefined)
  }

  function onOpenDeleteDemografia(dem: iDemografiaPEC): void {
    setModalDeleteIsOpen(true)
    setDemografiaDelete(dem)
  }

  function onCloseDeleteDemografia(): void {
    setModalDeleteIsOpen(false)
    setDemografiaDelete(undefined)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
  }

  function DeleteDemografia(Id: string): void {
    setIsLoading(true)
    appApi.delete(`PEC/Demografia/${Id}`)
      .then(() => {
        getDemografias()
        toast({
          title: 'Demografia removida com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top'
        })
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function getDemografiasVinculadas(): void {
    appApi.get(`PEC/${pesquisaId}/DemografiasVinculadas`)
      .then(res => setDemografiasAdd(res.data))
      .catch(err => console.log(err))
  }

  function handleContinuar(): void {
    setIsLoading(true)
    appApi.put(`PesquisaIndicativa/${pesquisaId as string}/Demografia`, DemografiasAdd).then(() => {
      nav(`${path.replace('Demografia', 'Formulario')}`)
    }).catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getDemografias()
    getDemografiasVinculadas()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      {
        (ModalAddDemografia) && (
          <ModalCreateDemografia
            isOpen={ModalAddDemografia}
            onClose={() => setModalAddDemografia(false)}
            onCreate={PostDemografia}
          />
        )
      }

      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir esta demografia?'
        onConfirm={onCloseModalDelete}
        center
        onRequestClose={onCloseDeleteDemografia}
        Demografia={DemografiaDelete}
        onDelete={DeleteDemografia}
      />

      <ModalUpdateDemografia
        isOpen={ModalUpeDemografia}
        Demografia={DemografiaEdit}
        isSelecionado={Selecionados.find(r => r === DemografiaEdit?.id) !== undefined}
        onClose={onCloseEditDemografia}
        onUpdate={PutDemografia}
        onAlterSelecao={AlterarSelecaoDemografia}
      />
      <TimelineCriarPEC color={['var(--Azul)', 'var(--Azul)', '.']}/>
      <WhiteContainer styles={{ marginTop: '2rem' }}>
        <Flex justifyContent={'space-between'} mb={'.75rem'} alignItems='center'>
          <h1>Adicionar Demografia</h1>
          <ButtonCmp VarColor='Green2' w={'13.7rem'} onClick={() => setModalAddDemografia(true)}>Adicionar demografia</ButtonCmp>
        </Flex>
        <Text mb={'1rem'} fontSize='.875rem'>
          Identificação utilizada para dividir a população em grupos de afinidade, exemplos: escolaridade, tempo de empresa. Esses grupos nos ajudam a entender comportamentos específicos para ações de desenvolvimento.
        </Text>
        <Box px={'2rem'} py={'2rem'} borderRadius={'.25rem'} border={'1px solid var(--Azul)'}>
          <Grid templateColumns={'repeat(4, 1fr)'} gap={'2rem'}>
            {Demografias?.map(e => {
              return (
                <GridItem justifyContent={'space-between'} minH={'16rem'} key={e.id} borderRadius={'.325rem'} border={'1px solid var(--c6)'} p={'.75rem'} display={'flex'} flexDirection={'column'} alignItems={'center'} fontSize='.875rem'>
                  <Grid templateColumns={'1fr auto 1fr'} mb={'1rem'} gap={'.5rem'}>
                    <span></span>
                    <Text fontWeight={'700'} color={'var(--Azul)'} fontSize='1rem'>{e.pergunta}</Text>
                    {!e.area && <Menu>
                      <StyledMenuButton
                        color={'var(--Rosa)'}
                        _hover={{ bg: 'var(--Rosa)', color: 'var(--c1)' }}
                        _focus={{ bg: 'var(--Rosa)', color: 'var(--c1)' }}
                        _active={{ bg: 'var(--Rosa)', color: 'var(--c1)' }}
                      >
                        <BiDotsHorizontalRounded size={19}/>
                      </StyledMenuButton>
                      <MenuList border={'1px solid var(--c5)'}>
                        <StyledMenuItem gap={'.5rem'} onClick={() => onOpenEditDemografia(e)}><FaEdit style={{ marginRight: '.4rem' }} /> Editar</StyledMenuItem>
                        <StyledMenuItem gap={'.5rem'} onClick={() => onOpenDeleteDemografia(e)} color={'var(--Red)'}><FaTrash style={{ marginRight: '.4rem' }} /> Excluir</StyledMenuItem>
                      </MenuList>
                    </Menu>}
                  </Grid>
                  <Box flex={'1'}>
                    {e?.demografias?.map(grupo => {
                      return (
                        <Li key={grupo.id}><span>{grupo.nome}</span></Li>
                      )
                    })}
                  </Box>
                  {/* {e.obtidoAutomaticamente && (
                    <Box mt={'1rem'} color={'var(--Red)'} fontSize={'14px'} mb={'1.5rem'} textAlign={'center'}>Obtido automaticamente para usuários do sistema</Box>
                  )} */}
                  <ButtonCmp mt={'.75rem'} VarColor={DemografiasAdd?.some(a => a === e.id) ? 'Rosa' : 'Green2'} onMouseEnter={() => setMouseIn(e.id)} onMouseLeave={() => setMouseIn(undefined)} onClick={() => onAddDemografia(e)}>
                    {DemografiasAdd?.some(a => a === e.id) ? MouseIn !== e.id ? 'Adicionado' : 'Remover' : 'Adicionar'}
                  </ButtonCmp>
                </GridItem>
              )
            })}
          </Grid>
        </Box>
      </WhiteContainer>
      <Flex justifyContent={'end'} mt={'1.5rem'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={() => nav(-1)}>
          Voltar
        </ButtonCmp>
        <ButtonCmp
          onClick={() => handleContinuar()}
          VarColor={'Green2'}
        >Continuar</ButtonCmp>
      </Flex>
    </Body>
  )
}
