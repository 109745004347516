/* eslint-disable no-tabs */
import { Box, Flex, Img, Text } from '@chakra-ui/react'
import { FaPeopleGroup } from 'react-icons/fa6'
import UserPlus from './Assets/userPlus.svg'
import Engrenagem from './Assets/engrenagens.svg'
import Clipboard from './Assets/clipboardCheck.svg'
import { FaClipboardCheck, FaClipboardList } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

interface iProps {
  color: string[]
  formularioAprovado?: boolean
}

export const TimelineCriarPEC: React.FC<iProps> = ({ color, formularioAprovado }) => {
  const nav = useNavigate()
  const { pathname } = useLocation()
  function GetLink(inicioUrl: string): void {
    const urlSplit = pathname.split('/')
    nav(inicioUrl + `/${urlSplit[urlSplit.length - 1]}`)
  }
  return (
		<Flex alignItems={'center'} mb={'3.5rem'} ml={'.7rem'} justifyContent='center'>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={'var(--Azul)'} border={'2px solid var(--Azul)'}>
					<Img src={Engrenagem} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'-9px'} textAlign={'center'}>Selecionando o modelo</Text>
			</Flex>
			<Box bgColor={color[0] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[1] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[1] ? color[1] : '#BBBBBB'} border={color[1] ? `2px solid ${color[1]}` : 'none' }>
					{/* <Img src={Pastas} w={'44px'} h={'35px'}/> */}
					<Img src={UserPlus} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'-2px'} textAlign={'center'}>Criando pesquisa</Text>
			</Flex>
			<Box bgColor={color[1] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[2] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} onClick={() => { if (!formularioAprovado && color[2]) { GetLink('/Engajamento/PEC/Demografia') } }} _hover={(color[2] && !formularioAprovado) ? { borderColor: 'var(--Azul)', cursor: 'pointer', bgColor: 'var(--Green2-hover)' } : {}} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[2] ? formularioAprovado ? 'var(--Azul)' : 'var(--Green2)' : '#BBBBBB'} border={color[2] ? formularioAprovado ? '2px solid var(--Azul)' : '2px solid var(--Green2)' : 'none' }>
					<FaPeopleGroup size={42} color='var(--c1)'/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'-5px'} textAlign={'center'}>Adicionar Demografia</Text>
			</Flex>
			<Box bgColor={color[2] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[3] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} onClick={() => { if (!formularioAprovado && color[3]) { GetLink('/Engajamento/PEC/Formulario') } }} _hover={(color[3] && !formularioAprovado) ? { borderColor: 'var(--Azul)', cursor: 'pointer', bgColor: 'var(--Green2-hover)' } : {}} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[3] ? formularioAprovado ? 'var(--Azul)' : 'var(--Green2)' : '#BBBBBB'} border={color[3] ? formularioAprovado ? '2px solid var(--Azul)' : '2px solid var(--Green2)' : 'none' }>
					<FaClipboardList size={38} color='var(--c1)'/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'0'} textAlign={'center'}>Formulário</Text>
			</Flex>
			<Box bgColor={color[3] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[4] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} onClick={() => { if (color[4]) { GetLink('/Engajamento/PEC/VisualizarFormulario') } }} _hover={color[4] ? { borderColor: 'var(--Azul)', cursor: 'pointer', bgColor: 'var(--Green2-hover)' } : {}} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[4] ? 'var(--Green2)' : '#BBBBBB'} border={color[4] ? '2px solid var(--Green2)' : 'none' }>
					<FaClipboardCheck size={38} color='var(--c1)'/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'0'} textAlign={'center'}>Visualizar Formulário</Text>
			</Flex>
			<Box bgColor={color[4] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[5] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[5] ? 'var(--Green2)' : '#BBBBBB'} border={color[5] ? '2px solid var(--Green2)' : 'none' }>
					<Img src={Clipboard} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'0px'} textAlign={'center'}>Configurar envio</Text>
			</Flex>
		</Flex>
  )
}
