/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp, FaPaperPlane } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { UsuarioGestaoConviteDto } from '../..'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../../../components/CheckboxCmp'
import { TableDiv } from './styles'

interface iTabelaprops {
  rows: UsuarioGestaoConviteDto[]
  selecionados?: string[]
  pesquisaId: string
  onCheck: (id: string, all?: boolean) => void
}

export const TableCartaConvite: React.FC<iTabelaprops> = ({ rows, selecionados, pesquisaId, onCheck }) => {
  const [Rows, setRows] = useState<UsuarioGestaoConviteDto[]>([])
  const nav = useNavigate()

  useEffect(() => {
    setRows(rows)
  }, [rows])

  const [Ordenacao, setOrdenacao] = useState(false)
  const [UltimoFiltro, setUltimoFiltro] = useState<number>()

  function SelecionarTodos(check: boolean): void {
    onCheck('', check)
  }

  function OrdenarTabela(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
  }

  useEffect(() => {
    const copy = [...rows]
    if (UltimoFiltro === 0) {
      copy.sort((a, b) => {
        if (!Ordenacao && a.tipoConvite && b.tipoConvite) {
          return b.tipoConvite - a.tipoConvite
        } else if (Ordenacao && a.tipoConvite && b.tipoConvite) {
          return a.tipoConvite - b.tipoConvite
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 1) {
      copy.sort((a, b) => {
        if (!Ordenacao && a.nome && b.nome) {
          return b.nome.localeCompare(a.nome)
        } else if (Ordenacao && a.nome && b.nome) {
          return a.nome.localeCompare(b.nome)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 2) {
      copy.sort((a, b) => {
        if (!Ordenacao && a.email && b.email) {
          return b.email.localeCompare(a.email)
        } else if (Ordenacao && a.email && b.email) {
          return a.email.localeCompare(b.email)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 3) {
      copy.sort((a, b) => {
        if (!Ordenacao && a.nomeArea && b.nomeArea) {
          return b.nomeArea.localeCompare(a.nomeArea)
        } else if (Ordenacao && a.nomeArea && b.nomeArea) {
          return a.nomeArea.localeCompare(b.nomeArea)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 4) {
      copy.sort((a, b) => {
        if (!Ordenacao && a.status && b.status) {
          return b.status - a.status
        } else if (Ordenacao && a.status && b.status) {
          return a.status - b.status
        } else {
          return 0
        }
      })
    }
    setRows(copy)
  }, [Ordenacao, UltimoFiltro, rows])

  function ObterCor(e: UsuarioGestaoConviteDto): string {
    return e.status === 1 ? 'Green2' : 'c5'
  }

  return (
    <TableDiv>
      <table>
        <thead>
          <tr>
            <th>
              <Flex gap={'0.50rem'}>
                <span>Selecionar todos </span>
                <CheckboxCmp OnChange={SelecionarTodos}/>
              </Flex>
            </th>
            <th>Data de criação</th>
            <th onClick={() => OrdenarTabela(4)}>
              <div>Status {UltimoFiltro === 4 && !Ordenacao ? <FaChevronUp/> : <FaChevronDown/>}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {
            Rows.map((e, i) => {
              return (
                <tr key={i}>
                  <td>
                    <CheckboxCmp isChecked={selecionados?.find(r => r === e.tokenId) !== undefined} OnChange={() => onCheck(e.tokenId)}/>
                  </td>
                  <td>{e.nome}</td>
                  <td>
                    <Flex justifyContent={'center'} gap={'1rem'} flexWrap={'wrap'}>
                      <Flex
                        gap={'.325rem'}
                        alignItems={'center'}
                      >
                        <Flex
                            width={'11px'}
                            bg={`var(--${ObterCor(e)})`}
                            height={'11px'}
                            borderRadius={'full'}
                            marginRight='.5rem'
                            alignItems={'center'}
                        />
                        {e.status === 4 ? 'A responder' : 'Respondida'}
                      </Flex>
                      {
                        (e.status === 4) && (
                          <ButtonCmp
                            onClick={() => nav(`/Engajamento/Pec/${pesquisaId}/CartaConvite/${e.tokenId}`)}
                            leftIcon={<FaPaperPlane />}
                            VarColor='Green2'
                            small
                          >Visualizar</ButtonCmp>
                        )
                      }
                    </Flex>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </TableDiv>
  )
}
