/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'
import { GraficoPEC } from '../../interfaces'

interface iChartProps{
  id: string
  dados: GraficoPEC
}

export const ScatterChart: React.FC<iChartProps> = ({ id, dados }) => {
  const [Series, setSeries] = useState<Array<{name: string, data: number[][]}>>()
  function ObterLabel(): Array<{name: string, data: number[][]}> {
    const dataChart: any[] = []

    if (dados != null) {
      dados.labels?.forEach((e, i) => {
        const newValor = [dados.serieX ? dados.serieX[i].toFixed(2) : 0, dados.serie ? dados.serie[i].toFixed(2) : 0]

        dataChart.push({ name: e, data: [newValor] })
      })
    }

    return dataChart
  }

  useEffect(() => {
    setSeries(ObterLabel())
  }, [dados])

  function GerarGrafico(): void {
    const options = {
      series: Series,
      colors: ['rgba(255,80,80,0.6)'],
      annotations: {
        points: Series?.map(e => {
          return {
            x: e.data[0][0],
            y: e.data[0][1],
            marker: {
              size: 0
            },
            label: {
              offsetY: -5,
              borderColor: 'transparent',
              style: {
                background: 'transparent',
                fontSize: '14px',
                fontWeight: 700
              },
              text: e.name.split('-')[0]
            }
          }
        })
      },
      chart: {
        height: 350,
        type: 'scatter',
        zoom: {
          enabled: false
        }
      },
      xaxis: {
        title: {
          text: 'Importância relativa (alavancagem)',
          style: {
            fontSize: '15px'
          }
        },
        labels: {
          formatter: function(val: string) {
            return parseFloat(val).toFixed(1)
          }
        },
        min: 0,
        max: 2
      },
      yaxis: {
        title: {
          text: 'Percepção média dos respondentes',
          style: {
            fontSize: '15px'
          }
        },
        tickAmount: 10,
        min: 0,
        max: 10,
        labels: {
          formatter: function(val: string) {
            return parseFloat(val).toFixed(1)
          }
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Perguntas que afetam significativamente o engajamento'],
        position: 'top',
        fontSize: '17px',
        markers: {
          size: 11,
          offsetX: -3
        }
      },
      markers: {
        size: 8
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          formatter: function(value: any, { series, seriesIndex, dataPointIndex, w }: any) {
            return Series ? `${Series[seriesIndex].name}: ( Alavancagem: ${Series[seriesIndex].data[0][0]}, Engajamento: ${Series[seriesIndex].data[0][1]})` : ''
          },
          title: {
            formatter: () => ''
          }
        }
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    if (Series) {
      GerarGrafico()
    }
  }, [Series])

  return (
        <div id={id}></div>
  )
}
