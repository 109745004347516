/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-unmodified-loop-condition */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex, Img, Divider, Grid, GridItem, Text, Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaFilePdf, FaCalendar, FaTachometerAlt, FaChartPie, FaChartLine, FaChartArea, FaThumbsUp, FaThumbsDown, FaPlus, FaChartBar } from 'react-icons/fa'
import { FaPeopleGroup } from 'react-icons/fa6'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { Icone } from '../../../../../../components/TimeLineOnboard/styles'
import { formatarData } from '../../../../../../Utils/Helper'
import { BarChart } from '../../Components/BarChart'
import { BarChartComBorda } from '../../Components/BarComBordaChart'
import { PieChart } from '../../Components/PieChart'
import { ScatterChart } from '../../Components/ScatterChart/scatter-chart'
import { TabelaMaozinha } from '../../Components/TabelaMaozinha/TabelaMaozinha'
import { GraficoPEC, IModel } from '../../interfaces'
import { InfoBox } from '../../styles'
import IconeBranco from '../../../../../../assets/Icone-Engajamento-Branco.svg'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ResultadoPDF } from '../../Components/ResultadoPDF'
import { PieChartPDF } from '../../Components/ResultadoPDF/Components/PieChartPdf'
import { ScatterChartPDF } from '../../Components/ResultadoPDF/Components/ScatterChartPdf'
import { BarChartPDF } from '../../Components/ResultadoPDF/Components/BarChartPdf'
import { BarChartBordaPDF } from '../../Components/ResultadoPDF/Components/BarChartBordaPdf'
import { SwitchCmp } from '../../../../../../components/SwitchCmp'
import LoadingContainer from '../../../../../../components/LoadingContainer'
import { GoGraph } from 'react-icons/go'

interface iProps {
  isLoading: boolean
  onGetGrupo: (id: string) => void
  Model: IModel
  tabAtiva: boolean
}

export const TabRelatorioGeral: React.FC<iProps> = ({ Model, isLoading, onGetGrupo, tabAtiva }) => {
  const [GrupoSelecionado, setGrupoSelecionado] = useState('')
  const [isLoadingBonecos, setisLoadingBonecos] = useState(true)
  const [DadosGraficoScatter, setDadosGraficoScatter] = useState<Array<{name: string, data: number[][]}>>()
  const [GraficosDemografia, setGraficosDemografia] = useState<Array<{ grafico: string, nome: string }>>([])
  const [GraficosgraficosPerguntasAtributo, setGraficosgraficosPerguntasAtributo] = useState<Array<{ avatar: string, grafico: string, nome: string }>>([])
  const [ScatterGrafico, setScatterGrafico] = useState<string>('')
  const [GraficoMediaPerguntas, setGraficoMediaPerguntas] = useState<string>('')
  const [GraficoMediaAtributos, setGraficoMediaAtributos] = useState<string>('')
  const [JpgBonequinhos, setJpgBonequinhos] = useState<Array<{ grafico: string, nome: string }>>([])
  const [TodosAtributos, setTodosAtributos] = useState(false)
  const [LoadingAfetam, setLoadingAfetam] = useState(false)

  function GetAdesao(): string {
    if (Model.pesquisa.pesqusiaManual) {
      return Model.adesaoManual?.toFixed(2) + '%'
    } else {
      return Math.floor(Model.participantes / Model.pesquisa.convites * 100).toFixed(2) + '%'
    }
  }

  function GetGraficosDemografia(): any {
    const arr: Array<{ nome: string, grafico: string }> = []
    return Model.demografias.map((e, i) => {
      return (
        <Flex pos={'absolute'} top={-1000 * (i + 1)} left={-1000 * (i + 1)}>
          <PieChartPDF
            id={`pie-chart-demografia-pdf-${i}`}
            grafico={e}
            setGrafico={(str) => { arr.push({ grafico: str, nome: e.titulo }); if (arr.length === Model.demografias.length) { setGraficosDemografia(arr) } }}
          />
        </Flex>
      )
    })
  }

  function GetGraficoMediaAtributoPergunta(): any {
    const arr: Array<{ avatar: string, grafico: string, nome: string }> = []
    return Model.graficoPerguntas.map((e, i) => {
      return (
        <Flex pos={'absolute'} top={-1000 * (i + 1)} left={-1000 * (i + 1)}>
          <BarChartPDF
            id={`mediaPerguntas-atributos-pdf-${i}`}
            grafico={e}
            legendas={['Média das perguntas com pouco ou nenhum impacto no engajamento', 'Média das perguntas com maior impacto no engajamento']}
            setGrafico={(str) => { arr.push({ avatar: e.numeroPergunta?.[0] ?? '', nome: e.titulo, grafico: str }); if (arr.length === Model.graficoPerguntas.length) { setGraficosgraficosPerguntasAtributo(arr) } }}
            questoesAfetam
          />
        </Flex>
      )
    })
  }

  function gerarBonequinhoBase64(amarelo: number, vermelho: number, verde: number, txtVerde: string, txtAmarelo: string, txtVermelho: string): string {
    function uint8ToBase64(uint8Array: any): any {
      let binary = ''
      const len = uint8Array.byteLength
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(uint8Array[i])
      }
      return window.btoa(binary)
    }
    const bonequinho = `
      <svg width="183" height="238" viewBox="0 0 183 238" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="183" height="238" fill="white"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="85" y="18" width="76" height="196">
          <path d="M120.328 18.2345C115.897 19.0269 112.016 21.587 109.556 25.29C107.508 28.3988 106.668 31.7665 106.958 35.6677C107.416 41.5498 111.481 46.9901 117.028 49.1692C119.213 50.0226 120.267 50.2055 122.972 50.2207C125.539 50.2207 126.165 50.1293 128.167 49.5045C132.186 48.2397 136.037 44.7957 137.794 40.9403C139.689 36.7801 139.765 32.0865 137.992 27.8959C136.877 25.2443 134.463 22.288 132.216 20.8098C128.656 18.4631 124.271 17.5183 120.328 18.2345Z" fill="black"/>
          <path d="M103.413 54.0301C103.291 54.0606 102.664 54.1673 102.038 54.2587C100.418 54.5178 97.9883 55.3407 96.3686 56.1788C90.3481 59.2875 86.3905 64.8039 85.1986 71.7833C85.0458 72.6824 85 78.5646 85 99.4417V125.957L85.3362 127.039C86.4211 130.437 90.0883 132.449 93.6792 131.611C96.1393 131.032 97.7591 129.645 98.6912 127.329L99.0579 126.414L99.1037 102.749L99.1343 79.0979H100.815H102.496L102.527 142.369L102.572 205.656L102.893 206.586C103.795 209.161 105.246 210.913 107.401 211.965C110.304 213.382 113.391 213.382 116.355 211.965C118.678 210.852 120.466 208.612 121.153 205.961C121.306 205.351 121.367 197.793 121.398 168.291L121.444 131.367H123.048H124.652V168.047C124.652 199.743 124.683 204.894 124.882 205.808C125.814 210.273 130.092 213.336 134.89 212.986C138.863 212.712 141.873 210.472 143.111 206.875L143.524 205.656L143.569 142.369L143.6 79.0827L145.25 79.1284L146.885 79.1741L146.962 102.87C147.023 119.648 147.084 126.704 147.206 127.024C148.352 130.133 150.751 131.824 154.036 131.824C157.612 131.824 160.347 129.416 160.867 125.805C160.989 124.921 161.02 117.561 160.989 98.8322C160.943 74.633 160.913 72.9872 160.653 71.6309C158.972 62.9143 153.013 56.514 144.67 54.4416L143.065 54.0454L123.354 54.0149C112.52 53.9996 103.535 53.9996 103.413 54.0301Z" fill="black"/>
        </mask>
        <g mask="url(#mask0)">
          <rect x="85" y="18" width="76" height="${vermelho}" fill="#CB1313"/>
          <rect x="85" y="18" width="76" height="${amarelo}" fill="#ECE42D"/>
          <rect x="85" y="18" width="76" height="${verde}" fill="#5CB85C"/>
        </g>
        <text x="15" y="75" fill="#5CB85C" font-weight="bold">${txtVerde}</text>
        <text x="15" y="95" fill="#ECE42D" font-weight="bold">${txtAmarelo}</text>
        <text x="15" y="115" fill="#D9534F" font-weight="bold">${txtVermelho}</text>
      </svg>
    `

    // Valores padrão
    if (vermelho === 0 && amarelo === 0 && verde === 0) {
      vermelho = 195
      amarelo = 183
      verde = 176
    }

    // Converte para base64
    const encoder = new TextEncoder()
    const byteArray = encoder.encode(bonequinho)
    const base64String = uint8ToBase64(byteArray)

    return `data:image/svg+xml;base64,${base64String}`
  }

  async function convertSvgBase64ToJpg(bonecos: GraficoPEC[]): Promise<any> {
    // Cria um novo objeto de imagem
    const copy: Array<{ grafico: string, nome: string }> = []
    const response: Array<{ grafico: string, nome: string }> = []

    bonecos.forEach(item => {
      if (item.serie && item.serie.length >= 3) {
        copy.push({
          grafico: gerarBonequinhoBase64(
            (2 * item.serie[1]) + (2 * item.serie[0]),
            200,
            (2 * item.serie[0]),
            item.serie[0].toFixed(2) + '%',
            item.serie[1].toFixed(2) + '%',
            item.serie[2].toFixed(2) + '%'
          ),
          nome: item.titulo
        })
      }
    })

    if (copy.length !== bonecos.length) {
      return
    }

    // Utiliza uma promessa para garantir que todas as imagens sejam processadas antes de prosseguir
    await Promise.all(
      copy.map(async (base64Svg, i) => {
        return await new Promise<void>((resolve, reject) => {
          const img = new Image()
          img.src = base64Svg.grafico

          img.onload = () => {
            const canvas: HTMLCanvasElement | null = document.getElementById(`canvas-${i}`) as HTMLCanvasElement
            if (canvas === null) return resolve() // Verifica se o canvas existe
            const ctx = canvas.getContext('2d')
            if (!ctx) return resolve() // Verifica se o contexto do canvas foi obtido

            canvas.width = img.width
            canvas.height = img.height

            ctx.drawImage(img, 0, 0)

            const jpgBase64 = canvas.toDataURL('image/jpeg', 1.0)
            response.push({ grafico: jpgBase64, nome: base64Svg.nome })
            resolve()
          }

          img.onerror = () => {
            console.error('Falha ao carregar a imagem base64 SVG.')
          }
        })
      })
    )

    if (response.length === copy.length) {
      setJpgBonequinhos(response)
      setisLoadingBonecos(false)
    }
  }

  function ObterLabel(dados: GraficoPEC): Array<{name: string, data: number[][]}> {
    const dataChart: any[] = []

    if (dados != null) {
      dados.labels?.forEach((e, i) => {
        const newValor = [dados.serieX ? dados.serieX[i] : 0, dados.serie ? dados.serie[i] : 0]

        dataChart.push({ name: e, data: [newValor] })
      })
    }

    return dataChart
  }

  useEffect(() => {
    if (Model.graficoDispersaoAfetamEngajamento) {
      setDadosGraficoScatter(ObterLabel(Model.graficoDispersaoAfetamEngajamento))
    }
  }, [Model.graficoDispersaoAfetamEngajamento])

  useEffect(() => {
    if (!isLoading && JpgBonequinhos.length === 0) {
      convertSvgBase64ToJpg(Model.bonecos)
    }
  }, [isLoading])

  useEffect(() => {
    convertSvgBase64ToJpg(Model.bonecos)
  }, [])

  return (
    <LoadingContainer linhas={52} loading={isLoading}>
      {tabAtiva && <>
      <Flex pos={'absolute'} left={-99999}>
        <ScatterChartPDF
          id='scatter-chart'
          dados={Model.graficoDispersaoAfetamEngajamento}
          setGrafico={setScatterGrafico}
        />
      </Flex>
      <Flex pos={'absolute'} left={-199999}>
        <BarChartPDF
          id='questoes-afetam-pdf'
          grafico={Model.graficoDispersaoAfetamEngajamento}
          setGrafico={setGraficoMediaPerguntas}
        />
      </Flex>
      <Flex pos={'absolute'} left={-299999}>
        <BarChartBordaPDF
          id='media-atributos-pdf'
          dados={Model.graficoAtributos}
          setGrafico={setGraficoMediaAtributos}
        />
      </Flex>
      {Model.bonecos.map((e, i) => {
        return <canvas id={`canvas-${i}`} style={{ display: 'none' }}></canvas>
      })}
      {GetGraficosDemografia()}
      {GetGraficoMediaAtributoPergunta()}
      <Flex justifyContent={'space-between'} alignItems={'end'}>
        <SelectCmp title='Grupo de análise' value={GrupoSelecionado} OnChange={(e) => {
          setJpgBonequinhos([])
          onGetGrupo(e)
          setGrupoSelecionado(e)
        }} width={'14rem'}>
          <option value={''}>Todas</option>
          {Model.demografiasDDL.map(e => {
            return (
              <option value={e.itemDemografiaId}>{e.pergunta} - {e.nome}</option>
            )
          })}
        </SelectCmp>
        <PDFDownloadLink
          document={
            <ResultadoPDF
              model={Model}
              graficosDemografia={GraficosDemografia}
              graficosPerguntasAtributo={GraficosgraficosPerguntasAtributo}
              jpgBonequinhos={JpgBonequinhos}
              graficoMediaPerguntas={GraficoMediaPerguntas}
              graficoMediaAtributos={GraficoMediaAtributos}
              scatterGrafico={ScatterGrafico}
              dadosGraficoScatter={DadosGraficoScatter}
            />
          }
          fileName={'exportar_relatorio_pec.pdf'}
        >
          {({ loading }) =>
            (loading || isLoadingBonecos || GraficoMediaPerguntas === '' || ScatterGrafico === '' || JpgBonequinhos.length !== Model.bonecos.length)
              ? (
                <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'} isLoading>Relatório PDF</ButtonCmp>
                )
              : <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>} w={'19rem'}>Relatório PDF</ButtonCmp>

          }
        </PDFDownloadLink>
      </Flex>
      </>}
      <Grid gridTemplateColumns={'repeat(6, 1fr)'} gap={'1rem'} my={'1rem'}>
        <InfoBox>
          <FaCalendar/>
          <Flex flexDir={'column'}>
            <span>Início</span>
            <p>{formatarData(Model.pesquisa.dataInicio ?? '')}</p>
          </Flex>
        </InfoBox>
        <InfoBox>
          <FaCalendar/>
          <Flex flexDir={'column'}>
            <span>Fim</span>
            <p>{formatarData(Model.pesquisa.dataFim ?? '')}</p>
          </Flex>
        </InfoBox>
        <InfoBox>
          <FaPeopleGroup/>
          <Flex flexDir={'column'}>
            <span>Participantes</span>
            <p>{Model.participantes}</p>
          </Flex>
        </InfoBox>
        <InfoBox>
          <FaTachometerAlt/>
          <Flex flexDir={'column'}>
            <span>Adesão</span>
            <p>{GetAdesao()}</p>
          </Flex>
        </InfoBox>
        <InfoBox>
          <GoGraph/>
          <Flex flexDir={'column'}>
            <span>Atributos</span>
            <p>{Model.clima.toFixed(2)}</p>
          </Flex>
        </InfoBox>
        <InfoBox>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'3rem'} h={'4rem'}>
            <Img src={IconeBranco} w={'6rem'} h={'5rem'}/>
          </Flex>
          <Flex flexDir={'column'}>
            <span>Engajamento</span>
            <p>{(Model.mediaEngajamentoGeral * 10).toFixed(2)}%</p>
          </Flex>
        </InfoBox>
      </Grid>
      {Model.demografias.length > 0 && <Flex flexDir={'column'} gap={'1.5rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'1rem'} opacity={'1'}/>
        <h3>Perfil dos participantes</h3>
        {Model.demografias.map((e, i) => {
          return (
            <Flex flexDir={'column'} justifyContent={'center'} alignItems={'center'} p={'1rem 0'} borderRadius={'.25rem'} border={'1px solid var(--AzulOpaco)'} gap={'1rem'}>
              <Text fontSize={'18px'} fontWeight={700} textAlign={'center'} color={'var(--Azul)'}>{e.titulo}</Text>
              <PieChart
                id={`pie-chart-demografia-${i}`}
                dados={e}
              />
            </Flex>
          )
        })}
      </Flex>}
      <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'}>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'4rem'} h={'4rem'}>
            <Img src={Icone} w={'6.25rem'} h={'6.25rem'}/>
          </Flex>
          <h3>Distribuição dos níveis de engajamento entre os participantes da pesquisa</h3>
        </Flex>
        <Flex justifyContent={'center'} gap={'5rem'}>
          <Flex gap={'.5rem'} alignItems={'center'}>
            <Box borderRadius={'50%'} width={'16px'} h={'16px'} bg={'var(--Green2)'}></Box>
            Favorável
          </Flex>
          <Flex gap={'.5rem'} alignItems={'center'}>
            <Box borderRadius={'50%'} width={'16px'} h={'16px'} bg={'var(--Yellow)'}></Box>
            Neutro
          </Flex>
          <Flex gap={'.5rem'} alignItems={'center'}>
            <Box borderRadius={'50%'} width={'16px'} h={'16px'} bg={'var(--Red)'}></Box>
            Desfavorável
          </Flex>
        </Flex>
        <Flex justifyContent={'center'} gap={'3.25rem'}>
          {Model.bonecos.map((item) => {
            if (item.serie && item.serie.length >= 3) {
              return <Flex flexDir={'column'}>
              <Img w={'11rem'} src={gerarBonequinhoBase64((2 * item.serie[1]) + (2 * item.serie[0]), 200, (2 * item.serie[0]), item.serie[0].toFixed(2) + '%', item.serie[1].toFixed(2) + '%', item.serie[2].toFixed(2) + '%'
              )}/>
              <Text fontSize={'14px'} color={'var(--c6)'} w={'10rem'} textAlign={'center'}>{item.titulo}</Text>
            </Flex>
            } else {
              return <></>
            }
          })}
        </Flex>
      </Flex>
      {Model.graficoDispersaoAfetamEngajamento && <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'}>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'4rem'} h={'4rem'}>
            <Img src={Icone} w={'6.25rem'} h={'6.25rem'}/>
          </Flex>
          <h3>Questões que afetam significativamente o engajamento</h3>
        </Flex>
        <ScatterChart
          id='scatter-chart'
          dados={Model.graficoDispersaoAfetamEngajamento}
        />
        <Flex flexDir={'column'} gap={'.75rem'} px={'1rem'}>
          {DadosGraficoScatter?.map(e => {
            return (
              <Flex fontSize={'14px'}>{e.name}: ( Engajamento: {e.data[0][1].toFixed(2)}, Alavancagem: {e.data[0][0].toFixed(2)} )</Flex>
            )
          })}
        </Flex>
        <Flex justifyContent={'center'} alignItems={'center'} border={'1px dashed var(--Azul)'} borderRadius={'.25rem'} background={'var(--AzulOpacoBg)'} flexDir='column' gap={'1rem'} p={'1rem 1.5rem'} mt={'.5rem'}>
          <Text fontSize={'18px'} fontWeight={'700'} lineHeight={'1.25rem'}>O que é alavancagem?</Text>
          <Text fontSize={'16px'} fontWeight={'400'} lineHeight={'1rem'} textAlign={'justify'}>A alavancagem mostra o quanto a nota de uma questão afeta o engajamento, ou seja, a importância que os participantes da pesquisa têm em relação aquela questão. Por exemplo, uma questão com alavancagem 0,3 significa que a cada 1 ponto de variação dessa questão para cima ou para baixo afetará o engajamento global em 0,3 para cima ou para baixo.</Text>
        </Flex>
      </Flex>}
      {Model.graficoDispersaoAfetamEngajamento && <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'}>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
            <FaChartPie fontSize={'2rem'} color={'var(--Azul)'}/>
          </Flex>
          <h3>Média das questões que afetam engajamento</h3>
        </Flex>
        <BarChart
          id='questoesAfetam'
          dados={Model.graficoDispersaoAfetamEngajamento}
        />
      </Flex>}

      {Model.graficoAtributos && <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'}>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
            <FaChartPie fontSize={'2rem'} color={'var(--Azul)'}/>
          </Flex>
          <h3>Média dos atributos</h3>
        </Flex>
        <BarChartComBorda
          id='mediaAtributos'
          dados={Model.graficoAtributos}
        />
      </Flex>}

      {Model.atributosQueAfetamMaisEngajamento?.length > 0 && <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--Orange)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.75rem'} alignItems={'center'} color={'var(--Orange)'}>
          <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
            <FaChartLine fontSize={'2rem'}/>
          </Flex>
          <h3>Atributos com maior impacto no engajamento</h3>
        </Flex>
        <Text>Esses são os atributos que, estatisticamente possuem o maior impacto no engajamento, ou seja, o sentimento de um colaborador relacionado a esse atributo afeta o engajamento dentro da organização.</Text>
        <Grid gridTemplateColumns={'repeat(5, 1fr)'} gap='2rem 0rem' px={'2rem'}>
          {Model.atributosQueAfetamMaisEngajamento?.map(e => {
            return (
              <GridItem display={'flex'} flexDir='column' gap={'.75rem'} w={'8.5rem'} alignItems={'center'}>
                <Flex w={'8rem'} h={'8rem'} borderRadius={'50%'} alignItems={'center'} justifyContent={'center'} p={'.325rem'} border={'2px dashed var(--c6)'}>
                  <Img w={'7rem'} h={'7rem'} borderRadius={'50%'} src={e.avatar}/>
                </Flex>
                <Text color={'var(--Gray2)'} fontWeight={700} wordBreak={'break-word'} textAlign='center'>{e.nome}</Text>
              </GridItem>
            )
          })}
        </Grid>
        <Divider borderColor={'var(--Orange)'} my={'2rem'} opacity={'1'}/>
      </Flex>}
      <>
      {Model && <Flex flexDir={'column'} gap={'1.325rem'}>
        {!(Model.atributosQueAfetamMaisEngajamento?.length > 0) && <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>}
        <Flex gap={'.75rem'} alignItems={'center'} justifyContent={'space-between'}>
          <Flex flexDir={'column'} w={'50%'} gap={'.325rem'}>
            <Flex gap={'.75rem'} alignItems='center'>
              <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
                <FaChartBar fontSize={'2rem'} color={'var(--Azul)'}/>
              </Flex>
              <h3>Média das questões por atributo</h3>
            </Flex>
            <Text>Você pode utilizar o filtro ao lado para visualizar apenas os atributos que contém questões que afetam o engajamento.</Text>
          </Flex>
          {Model.graficoDispersaoAfetamEngajamento.serie && Model.graficoDispersaoAfetamEngajamento.serie?.length > 0 && <SwitchCmp
            OnChange={(e) => {
              setTodosAtributos(e)
              setLoadingAfetam(true)
              setTimeout(() => {
                setLoadingAfetam(false)
              }, 1000)
            }}
            value={TodosAtributos}
            legendaEsquerda={'Atributos que afetam o engajamento'}
            legendaDireita={'Todos os atributos'}
          />}
        </Flex>
        <LoadingContainer linhas={32} loading={LoadingAfetam}>
          {Model.graficoPerguntas.filter(e => {
            if (!TodosAtributos) {
              if (e.afetaEngajamento.some(a => a)) {
                return e
              } else {
                return null
              }
            } else {
              return e
            }
          }).map((e, i) => {
            return (
              <Flex flexDir={'column'} gap={'.75rem'}>
                <Flex gap={'1.25rem'} alignItems={'center'}>
                  <Flex w={'6rem'} h={'6rem'} borderRadius={'50%'} alignItems={'center'} justifyContent={'center'} p={'.325rem'} border={'2px dashed var(--c6)'}>
                    <Img w={'5.25rem'} h={'5.25rem'} borderRadius={'50%'} src={e.avatar}/>
                  </Flex>
                  <Text fontWeight={800} fontSize={'26px'}>{e.titulo}</Text>
                </Flex>
                <BarChart
                  id={`mediaPerguntas-${e.id}-${i}`}
                  dados={e}
                  legendas={['Média das perguntas com pouco ou nenhum impacto no engajamento', 'Média das perguntas com maior impacto no engajamento']}
                  questoesAfetam
                />
              </Flex>
            )
          })}
        </LoadingContainer>
      </Flex>}

      {!Model.pesquisa.pesqusiaManual && <Flex flexDir={'column'} gap={'1.325rem'}>
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        {((Model.perguntasMaiorAlavancagem && Model.perguntasMaiorAlavancagem.length > 0) || (Model.quatroPiores && Model.quatroPiores?.length > 0))
          ? <>
          <Flex gap={'.75rem'} alignItems={'center'}>
            <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
              <FaChartArea fontSize={'2rem'} color={'var(--Azul)'}/>
            </Flex>
            <h3>Diferença estatística dos grupos de análise</h3>
          </Flex>
          <Text>Abaixo identificamos diferenças estatísticas significativas entre as notas médias dos grupos de análises.</Text>
          <Flex justifyContent={'center'} alignItems='center' border={'1px dashed var(--Azul)'} background={'var(--AzulOpacoBg)'} p={'.75rem 1.5rem'} flexDir={'column'} gap={'.75rem'}>
            <Text color={'var(--Azul)'} fontWeight={700} fontSize={'22px'}>Legenda</Text>
            <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'1.5rem'}>
              <GridItem background={'var(--c1)'} border={'1px solid var(--c6)'} padding={'1rem 1.75rem'} display={'flex'} borderRadius={'.325rem'} alignItems={'center'}>
                <Flex minW={'5.5rem'} alignItems='center'>
                  <FaThumbsUp color='var(--Green2)' fontSize={'4.5rem'}/>
                </Flex>
                <Text fontSize={'18px'} textAlign='justify'>
                  O grupo possui uma nota estatisticamente <Text as={'span'} color={'var(--Green2)'} fontWeight={700}>maior</Text> que os demais
                </Text>
              </GridItem>
              <GridItem background={'var(--c1)'} border={'1px solid var(--c6)'} padding={'1rem 1.75rem'} display={'flex'} borderRadius={'.325rem'} alignItems={'center'}>
                <Flex minW={'5.5rem'} alignItems='center'>
                  <FaThumbsDown color='var(--Orange)' fontSize={'4.5rem'}/>
                </Flex>
                <Text fontSize={'18px'} textAlign='justify'>
                  O grupo possui uma nota estatisticamente <Text as={'span'} color={'var(--Orange)'} fontWeight={700}>menor</Text> que os demais
                </Text>
              </GridItem>
              <GridItem background={'var(--c1)'} border={'1px solid var(--c6)'} padding={'1rem 1.75rem'} display={'flex'} borderRadius={'.325rem'} alignItems={'center'}>
                <Flex minW={'5.5rem'} alignItems='center' justifyContent={'center'} mr={'.75rem'}>
                  <Box w={'80%'} h={'4px'} background={'var(--c6)'} borderRadius={'12px'}></Box>
                </Flex>
                <Text fontSize={'18px'} textAlign='justify'>
                  O grupo <Text as={'span'} color={'var(--c6)'} fontWeight={700}>não</Text> possui diferença estatística
                </Text>
              </GridItem>
            </Grid>
          </Flex>
          <Flex flexDir={'column'} gap={'3rem'} mt={'1.5rem'}>
            {(Model.demografiasSemFiltro && Model.maozinhas && Model.perguntasMaiorAlavancagem)
              ? Model.demografiasSemFiltro.map((e, i) => {
                return (
                  <Flex flexDir={'column'} gap={'1rem'}>
                    <Flex flexDir={'column'}>
                      <Flex gap={'.75rem'} alignItems='center'>
                        <Flex alignItems={'center'} justifyContent={'center'} overflow={'hidden'} w={'2rem'} h={'2rem'}>
                          <FaChartPie fontSize={'2rem'} color={'var(--Azul)'}/>
                        </Flex>
                        <h3>{e.titulo}</h3>
                      </Flex>
                    </Flex>
                    <TabelaMaozinha
                      perguntas={Model.perguntasMaiorAlavancagem}
                      maozinhas={Model.maozinhas}
                      demografia={e}
                    />
                  </Flex>
                )
              })
              : <Flex justifyContent={'center'} alignItems={'center'} fontSize={'20px'} fontWeight={700}>
              Nenhuma das demografias apresentou diferença estatística
            </Flex>}
          </Flex>
        </>
          : <Flex justifyContent={'center'} alignItems={'center'} fontSize={'20px'} fontWeight={700}>
          Nenhuma das demografias apresentou diferença estatística
        </Flex>}
        <Divider borderColor={'var(--AzulOpaco)'} my={'2rem'} opacity={'1'}/>
        <Flex gap={'.25rem'} alignItems={'center'} flexDir={'column'} maxW={'66rem'} margin={'0 auto'} justifyContent={'center'}>
          <h3 style={{ textAlign: 'center' }}>Agora você pode adicionar planos de ação para trabalhar as questões apontadas em sua pesquisa de engajamento!</h3>
          <span style={{ textAlign: 'center' }}>Recomendamos que foque principalmente nas questões que afetam o engajamento na sua organização, ou seja, as questões que os respondentes realmente consideram importantes.</span>
          <ButtonCmp leftIcon={<FaPlus/>} VarColor='Green2' w={'12rem'} my={'.75rem'} onClick={() => { window.location.href = `${process.env.REACT_APP_URL_SIGAH}/Home/Plano` }}>Adicionar plano de ação</ButtonCmp>
        </Flex>
      </Flex>}
      </>
    </LoadingContainer>
  )
}
