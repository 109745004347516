/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable array-callback-return */
import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { InputCmp } from '../../../../components/InputCmp'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { appApi } from '../../../../services/appApi'
import { UpdateElementByPosition } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { PerguntasAfetamEngajamento } from '../Resultado/interfaces'
import { StyledTd, TableDiv } from './styles'

export interface iModel {
  mediasPerguntas: iResultadoAtributo[]
  lancamentos: PerguntasAfetamEngajamento[]
}

export interface iResultadoAtributo {
  perguntaId: string
  media: number
  pergunta: string
  selecionado?: boolean
  alavancagem?: number
}

export const ConsultorPEC: React.FC = () => {
  const [QuestoesAlteradas, setQuestoesAlteradas] = useState<iResultadoAtributo[]>([])
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>({
    lancamentos: [],
    mediasPerguntas: []
  })

  function getInfosConsultor(): void {
    appApi.get(`ResultadoPec/DadosAnalise/${pesquisaId}`)
      .then(res => {
        const data: iModel = res.data
        setModel({
          ...data,
          mediasPerguntas: data.mediasPerguntas.map(e => {
            const lancamento = data.lancamentos.find(l => l.perguntaId === e.perguntaId)
            if (lancamento) {
              e.alavancagem = lancamento.alavancagem
              e.selecionado = true
            }
            return e
          })
        })
      })
      .catch(err => console.log(err))
  }

  function LimparLista(): void {
    setModel({
      ...Model,
      mediasPerguntas: Model.mediasPerguntas.map(e => {
        e.selecionado = false
        return e
      })
    })
  }

  // function OnChangeValues(perguntaId: string, alavancagem?: number, selecionado?: boolean): void {
  //   const pergunta = QuestoesAlteradas.find(e => e.perguntaId === perguntaId)

  //   if (pergunta) {
  //     if (alavancagem !== undefined || alavancagem !== null) pergunta.alavancagem = alavancagem
  //     if (selecionado !== undefined || selecionado !== null) pergunta.selecionado = selecionado

  //     setQuestoesAlteradas(UpdateElementByPosition())
  //   } else {
  //     const newItem = { } as iResultadoAtributo
  //     if (alavancagem !== undefined || alavancagem !== null) newItem.alavancagem = alavancagem
  //     if (selecionado !== undefined || selecionado !== null) newItem.selecionado = selecionado

  //     setQuestoesAlteradas([...QuestoesAlteradas, newItem])
  //   }
  // }

  function OnSend(): void {
    appApi.post(`ResultadoPec/DadosAnalise/${pesquisaId}`, {
      questoes: Model.mediasPerguntas.map(e => {
        if (e.selecionado) {
          return { alavancagem: e.alavancagem, perguntaId: e.perguntaId }
        }
      })
    })
      .then(res => { nav(`/Engajamento/Pec/ResultadoPro/${pesquisaId}`) })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getInfosConsultor()
  }, [])

  return (
    <Body>
      <Flex justifyContent={'space-between'}>
        <h1 style={{ marginBottom: '1rem' }}>Lançamento de resultados</h1>
        <ButtonCmp VarColor='Green2' onClick={() => { nav(`/Engajamento/Pec/ResultadoPro/${pesquisaId}`) }}>Visualizar resultados da pesquisa</ButtonCmp>
      </Flex>
      {Model.lancamentos.length > 0 && <Flex p={'1rem'} bg={'var(--AzulOpacoBg)'} border={'1px solid var(--c4)'} borderRadius={'4px'} fontWeight={'700'} fontSize={'18px'} boxShadow={'var(--SombraBackground)'} mb={'1.5rem'}>Já foram realizados lançamentos por um Consultor</Flex>}
      <WhiteContainer>
        <Flex flexDir={'column'} gap={'1.5rem'}>
          <Text fontWeight={700} fontSize={'18px'}>Média das perguntas</Text>
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th style={{ width: '70%', paddingLeft: '2rem' }}>
                    <Flex width={'100%'} justifyContent='start'>
                      Pergunta
                    </Flex>
                  </th>
                  <th style={{ width: '15%' }}>Média</th>
                  <th style={{ width: '15%' }}>Alavancagem</th>
                </tr>
              </thead>
              <tbody>
                {Model.mediasPerguntas?.map((e, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td>
                          <Flex width={'100%'} pl={'.75rem'} textAlign={'start'} alignItems='center' justifyContent={'start'}>
                              <CheckboxCmp isChecked={e.selecionado} OnChange={(val) => setModel({ ...Model, mediasPerguntas: UpdateElementByPosition(Model.mediasPerguntas, i, { ...e, selecionado: val }) })}>
                                {e.pergunta}
                              </CheckboxCmp>
                          </Flex>
                        </td>
                        <StyledTd>{e.media.toFixed(2)}</StyledTd>
                        <StyledTd>
                          <Flex width={'100%'} alignItems='center' justifyContent={'center'}>
                            <InputCmp type={'number'} value={e.alavancagem} OnChange={(val) => setModel({ ...Model, mediasPerguntas: UpdateElementByPosition(Model.mediasPerguntas, i, { ...e, alavancagem: parseFloat(val) }) })} placeholder='0.000'/>
                          </Flex>
                        </StyledTd>
                      </tr>
                    </>
                  )
                })}
              </tbody>

            </table>
          </TableDiv>
          <Flex gap={'.75rem'}>
            <ButtonCmp VarColor='c6' onClick={LimparLista}>Limpar</ButtonCmp>
            <ButtonCmp VarColor='Green2' onClick={OnSend}>Salvar resultados</ButtonCmp>
          </Flex>
        </Flex>
      </WhiteContainer>
    </Body>
  )
}
