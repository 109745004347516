import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { ConfigurarTrilha } from './pages/RH/ConfigurarTrilha'
import { ModifyIntegracao } from './pages/RH/Integracao/Modify'
import { Reuniao } from './pages/RH/Reuniao/Criar'
import { TrilhaOnboard } from './pages/RH/Trilhas'
import { ConfigIntegracao } from './pages/RH/Integracao/Config'
import { ConfigImersao } from './pages/RH/Imersao/Config'
import { ModifyImersao } from './pages/RH/Imersao/Modify'
import { PrimeroAcesso } from './pages/RH/PrimeiroAcesso'
import { FormularioPesquisa } from './pages/PesquisaPercepcao/FormularioPesquisa'
import { VisualizarFormulario } from './pages/PesquisaPercepcao/VisualizarFormulario'
import { PesquisaParticipantes } from './pages/PesquisaPercepcao/Participantes'
import { EnviarPesquisa } from './pages/PesquisaPercepcao/EnviarPesquisa'
import { ResumoPesquisaPercepcao } from './pages/PesquisaPercepcao/Resumo'
import { GestaoPesquisas } from './pages/PesquisaPercepcao/GestaoPesquisas'
import { ResultadoPesquisaPercepcao } from './pages/PesquisaPercepcao/Resultado'
import { ResultadoIndividual } from './pages/PesquisaPercepcao/ResultadoIndividual'
import { CriarPesquisaIndicativa } from './pages/PesquisaIndicativa/Criar'
import { Formularios as FormulariosPercepcao } from './pages/PesquisaPercepcao/Formularios'
import { Painel } from './pages/Colaborador/Perfil'
import { ImersaoColaborador } from './pages/Colaborador/Imersao'
import { IntegracaoColaborador } from './pages/Colaborador/Integracao'
import { PerguntasFrequentesColab } from './pages/Colaborador/PerguntasFrequentes'
import { PerguntasFrequentesRh } from './pages/RH/PerguntasFrequentes'
import { CargoDesenvolvimento } from './pages/Organograma/Desenvolvimento/Cargos'
import { ColaboradoresDesenvolvimento } from './pages/Organograma/Desenvolvimento/Colaboradores'
import { DashboardDesenvolvimento } from './pages/Organograma/Desenvolvimento/Dashboard'
import { DepartamentoDesenvolvimento } from './pages/Organograma/Desenvolvimento/Departamentos'
import { GestaoFuncionarios } from './pages/RH/GestaoOnboard'
import { VisulaizarTrilhaColaborador } from './pages/RH/VisualizarTrilhaColaborador'
import { TurnOver } from './pages/RH/TurnOver'
import { EngajamentoDashboard } from './pages/Engajamento/Dashboard'
import { EngajamentoeNps } from './pages/Engajamento/eNps'
import { NavBar } from './components/NavBar'
import { AberturaPesquisa } from './pages/PesquisaPercepcao/Abertura'
import { RespostaPesquisa } from './pages/PesquisaPercepcao/RespostaPesquisa'
import { Login } from './pages/Login'
import { AlterarSenha } from './pages/Usuario/AlterarSenha'
import { ConfigNps } from './pages/Engajamento/ConfigNps/indes'
import { RespostaNps } from './pages/Engajamento/RespostaNps'
import { GestaoImersao } from './pages/Imersao/Gestao'
import { CriarImersao } from './pages/Imersao/Editar'
import { GestaoConvitesImersao } from './pages/Imersao/Convites'
import { PrevisualizarImersao } from './pages/Imersao/Previsualizar/Todos'
import { PrevisualizarImersaoIndividual } from './pages/Imersao/Previsualizar/Individual'
import { VisualizarImersao } from './pages/Imersao/Visualizar'
import { GestaoEnpsJornada } from './pages/Jornada/eNps/Gestao'
import { ResultadoEnpsJornada } from './pages/Jornada/eNps/Resultado'
import { GestaoPesquisaInternaJornada } from './pages/Jornada/PesquisaInterna/Gestao'
import { CriarPesquisaInterna } from './pages/Jornada/PesquisaInterna/Criar'
import { FormularioPesquisaInterna } from './pages/Jornada/PesquisaInterna/Formulario'
import { AprovacaoPesquisaInterna } from './pages/Jornada/PesquisaInterna/Aprovacao'
import { ConfiguracaoPesquisaInterna } from './pages/Jornada/PesquisaInterna/Configuracao'
import { ConvitesPesquisaInterna } from './pages/Jornada/PesquisaInterna/Convites'
import { ConfigReuniaoOne } from './pages/Jornada/ReuniaoOne/Configuracao'
import { AberturaPesquisaInterna } from './pages/Jornada/PesquisaInterna/Abertura'
import { PreenchimentoPesquisaInterna } from './pages/Jornada/PesquisaInterna/Preenchimento'
import { AvaliacaoPDI } from './pages/PDI/Avaliacao'
import { ResultadoGeral } from './pages/PesquisaPercepcao/ResultadoGeral'
import { DashboardJornada } from './pages/Jornada/Dashboard'
import { DashboardDesempenho } from './pages/Desempenho/Dashboard'
import { ChamadoRh } from './pages/ChamadoRH'
import { ResultadoPesquisaInterna } from './pages/Jornada/PesquisaInterna/Resultado'
import { LogOut } from './pages/Login/LogOut'
import { Privacidade } from './pages/RH/Privacidade'
import { GerenciarPermissoes } from './pages/Usuario/Empresa/GerenciarPermissoes'
import { ConfiguracaoReuniao } from './pages/RH/Reuniao/Configuracao'
import { ConfiguracaoEmpresa } from './pages/Usuario/Empresa/Configuracao'
import { CriarPDI } from './pages/PDI/Criar'
import { DashboardCorporativoPDI } from './pages/PDI/Dashboard/Corporativo'
import { DashboardIndividualPDI } from './pages/PDI/Dashboard/Individual'
import { EsqueciSenha } from './pages/Login/EsqueciSenha'
import { DetalhesCargo } from './pages/AssessmentPI'
import { DashBoardJobTarget } from './pages/AssessmentPI/JobTarget'
import { JobTargetCreate } from './pages/AssessmentPI/JobTarget/Create'
import { isAuth } from './services/token'
import { CriarEnpsJornada } from './pages/Jornada/eNps/Criar'
import { FormularioProvider } from './contexts/FormularioContext'
import { CentralComando } from './pages/CentralComando'
import { CriarEmpresa } from './pages/CentralComando/CriarEmpresa'
import { ConfigNineBox } from './pages/Usuario/Empresa/ConfigNineBox'
import { EtapaPDI } from './pages/PDI/Etapa'
import { NineBox } from './pages/Desempenho/NineBox'
import { UsuarioEixo } from './pages/Desempenho/NineBox/UsuarioEixo'
import { DashboardPi } from './pages/AssessmentPI/Dashboard'
import { PerfilPi } from './pages/AssessmentPI/components/PerfilPI'
import { EditarEmpresa } from './pages/CentralComando/EditarEmpresa'
import { AssuntoReuniaoOne } from './pages/Jornada/ReuniaoOne/Assunto'
import { DashboardCorporativoReuniaoOne } from './pages/Jornada/ReuniaoOne/Dashboard/Corporativo'
import { DashboardIndividualReuniaoOne } from './pages/Jornada/ReuniaoOne/Dashboard/Individual'
import { DashboardReuniaoOne } from './pages/Jornada/OldReuniaoOne/Dashboard'
import { OldConfigReuniaoOne } from './pages/Jornada/OldReuniaoOne/Configuracao'
import { PreenchimentoFeedback } from './pages/Jornada/Feedback/Preenchimento'
import { AberturaPreenchimentoFeedback } from './pages/Jornada/Feedback/Abertura'
import { VisualizarRespostasFeedback } from './pages/Jornada/Feedback/VisualizarRespostas'
import { FormulariosFeedback } from './pages/Jornada/Feedback/Formularios'
import { ConfigurarFeedback } from './pages/Jornada/Feedback/Configurar'
import { EditarFormularioFeedback } from './pages/Jornada/Feedback/NovoFeedback'
import { SendAssessmentPI } from './pages/AssessmentPI/Send'
import { ReuniaoRealizada } from './pages/RH/Reuniao/Realizada'
import { AgendamentoReuniaoOne } from './pages/Jornada/OldReuniaoOne/Agendamento'
import { DashboardIndividualFeedback } from './pages/Jornada/Feedback/Dashboard/Individual'
import { SelecionarModelo } from './pages/PesquisaPercepcao/SelecionarModelo/Index'
import { CriarPesquisaQuantitativa } from './pages/PesquisaPercepcao/Criar'
// import { PesquisaPercepcaoDemografia } from './pages/PesquisaPercepcao/Demografia'
import { VisualizarFormularioIndicativa } from './pages/PesquisaIndicativa/VisualizarFormulario'
import { TextosIndicativa } from './pages/PesquisaIndicativa/Textos'
import { RespostaIndicativa } from './pages/PesquisaIndicativa/RespostaIndicativa'
import { FormulariosIndicativa } from './pages/PesquisaIndicativa/Formularios'
import { EnviarPI } from './pages/Pi'
import { FormulariosPesquisaInterna } from './pages/Jornada/PesquisaInterna/Formularios'
import { FormularioIndicativa } from './pages/PesquisaIndicativa/FormularioIndicativa'
import { ResultadoIndividualIndicativa } from './pages/PesquisaIndicativa/ResultadoIndividualIndicativa'
import { ResultadoGeralIndicativa } from './pages/PesquisaIndicativa/ResultadoGeralIndicativa'
import { DashboardCorporativoFeedback } from './pages/Jornada/Feedback/Dashboard/Corporativo'
import { Beta } from './pages/Beta'
import { FluxoPersonalizado } from './pages/FluxoPersonalizado/Criar'
import { ConsolidarResposta } from './pages/PesquisaPercepcao/ConsolidarResposta/inde'
import { DashboardFluxoPersonalizado } from './pages/FluxoPersonalizado/Dashboard'
import { GestaoAgendamentos } from './pages/RH/GestaoAgendamentos'
import { GestaoFormularios } from './pages/Desempenho/GestaoFormularios'
import { GestaoGruposPesquisaInternaJornada } from './pages/Jornada/PesquisaInterna/Grupos'
import { GestaoPesquisasEngajamento } from './pages/Engajamento/PEC/GestaoPesquisas'
import { SelecionarModeloEngajamento } from './pages/Engajamento/PEC/SelecionarModelo/Criar'
import { CriarPesquisaEngajamento } from './pages/Engajamento/PEC/CriarPesquisa'
import { PECDemografia } from './pages/Engajamento/PEC/Demografia'
import { FormularioPesquisaPEC } from './pages/Engajamento/PEC/FormularioPesquisa'
import { VisualizarFormularioPec } from './pages/Engajamento/PEC/VisualizarFormulario'
import { RespostaPesquisaPec } from './pages/Engajamento/PEC/RespostaPesquisa'
import { ConfiguracaoPEC } from './pages/Engajamento/PEC/Configuracao'
import { ConvitesPec } from './pages/Engajamento/PEC/Convites'
import { CartaConvite } from './pages/Engajamento/PEC/CartaConvite'
import { DiagnosticoEngajamento } from './pages/Engajamento/PEC/Diagnostico'
import { AberturaPesquisaPec } from './pages/Engajamento/PEC/Abertura'
import { DigitarChavePec } from './pages/Engajamento/PEC/DigitarChave'
import { ResultadoPec } from './pages/Engajamento/PEC/Resultado/ResultadoPec'
import { Indicadores } from './pages/Indicadores/gestao'
import { IndicadoresAprovar } from './pages/Indicadores/adm'
import { ConsultorPEC } from './pages/Engajamento/PEC/Consultor'
import { BaseCsv } from './pages/Engajamento/PEC/BaseCsv'

export function AppRoutes(): any {
  const Rotas = (): any => {
    if (isAuth()) {
      return <Outlet />
    }
    return <Navigate to={'/'} />
  }

  const PesquisaIndicativa = (): React.ReactNode => {
    return (
      <Route path='Indicativa'>
        <Route path='Criar' element={<CriarPesquisaIndicativa />} />
        <Route path='Editar/:pesquisaId' element={<CriarPesquisaIndicativa />} />
        {/* <Route path='Demografia/:pesquisaId' element={<PesquisaPercepcaoDemografia />} /> */}
        <Route path='Formularios/:pesquisaId' element={<FormulariosIndicativa />} />
        <Route path='Formulario/:pesquisaId' element={<FormularioIndicativa />} />

        <Route path='VisualizarFormulario/:pesquisaId'>
          <Route path='' element={<VisualizarFormularioIndicativa />} />
          <Route path='Previsualizar' element={<RespostaIndicativa />} />
        </Route>

        <Route path='Participantes/:pesquisaId' element={<PesquisaParticipantes />}>
          <Route path='Editar' element={<PesquisaParticipantes />} />
        </Route>

        <Route path='EnviarPesquisa/:pesquisaId' element={<EnviarPesquisa />} >
          <Route path='Editar' element={<EnviarPesquisa />} />
        </Route>

        <Route path='Textos/:pesquisaId' element={<TextosIndicativa />}>
          <Route path='Editar' element={<TextosIndicativa />} />
        </Route>

        <Route path='ResumoPesquisa/:pesquisaId' element={<ResumoPesquisaPercepcao />} />
        <Route path='ResultadoPesquisa/:pesquisaId' element={<ResultadoPesquisaPercepcao />} />
        <Route path='ResultadoIndividual/:avaliacaoId' element={<ResultadoIndividualIndicativa />} />
        <Route path='ResultadoGeral/:pesquisaId' element={<ResultadoGeralIndicativa />} />
      </Route>
    )
  }

  const PesquisaQuantitativa = (): React.ReactNode => {
    return (
      <Route path='Quantitativa'>
        <Route path='Criar' element={<CriarPesquisaQuantitativa />}>
          <Route path='Trilha/:trilhaId' element={<CriarPesquisaQuantitativa />} />
          <Route path='Processo/:processoId/:colaboradorId' element={<CriarPesquisaQuantitativa />} />
        </Route>

        <Route path='Editar' element={<CriarPesquisaQuantitativa />}>
          <Route path=':etapaId/Trilha/:trilhaId' element={<CriarPesquisaQuantitativa />} />
          <Route path=':etapaId/Processo/:processoId' element={<CriarPesquisaQuantitativa />} />
        </Route>

        <Route path='Formularios/:pesquisaId' element={<FormulariosPercepcao />}>
          <Route path='Trilha/:trilhaId' element={<FormulariosPercepcao />} />
          <Route path='Processo/:processoId' element={<FormulariosPercepcao />} />
        </Route>

        <Route path='Formulario/:pesquisaId'
          element={
            <FormularioProvider>
              <FormularioPesquisa />
            </FormularioProvider>
          }
        >
          <Route path='Trilha/:trilhaId'
            element={
              <FormularioProvider>
                <FormularioPesquisa />
              </FormularioProvider>
            }
          />
          <Route path='Processo/:processoId'
            element={
              <FormularioProvider>
                <FormularioPesquisa />
              </FormularioProvider>
            }
          />
        </Route>

        <Route path='Processo/:processoId'
          element={
            <FormularioProvider>
              <FormularioPesquisa />
            </FormularioProvider>
          }
        />
        <Route path='VisualizarFormulario/:pesquisaId'>
          <Route path='' element={<VisualizarFormulario />} />
          <Route path='Trilha/:trilhaId' element={<VisualizarFormulario />} />
          <Route path='Processo/:processoId' element={<VisualizarFormulario />} />

          <Route path='Previsualizar' element={<RespostaPesquisa />} />
          <Route path='Trilha/:trilhaId/Previsualizar' element={<RespostaPesquisa />} />
          <Route path='Processo/:processoId/Previsualizar' element={<RespostaPesquisa />} />
        </Route>

        <Route path='Participantes/:pesquisaId' element={<PesquisaParticipantes />}>
          <Route path='Editar' element={<PesquisaParticipantes />} />
          <Route path='Processo' element={<CriarPesquisaQuantitativa />} />
          <Route path='Processo/:processoId' element={<CriarPesquisaQuantitativa />} />
        </Route>

        <Route path='EnviarPesquisa/:pesquisaId' element={<EnviarPesquisa />} >
          <Route path='Editar' element={<EnviarPesquisa />} />
          <Route path='Processo/Editar' element={<EnviarPesquisa />} />
          <Route path='Trilha/:trilhaId' element={<EnviarPesquisa />} />
          <Route path='Processo/:processoId' element={<EnviarPesquisa />} />
        </Route>

        <Route path='ResumoPesquisa/:pesquisaId' element={<ResumoPesquisaPercepcao />} />
        <Route path='ResultadoPesquisa/:pesquisaId' element={<ResultadoPesquisaPercepcao />} />
        <Route path='ResultadoIndividual/:avaliacaoId' element={<ResultadoIndividual />} />
        <Route path='ResultadoGeral/:pesquisaId' element={<ResultadoGeral />} />
        <Route path='ConsolidarResposta/:id' element={<ConsolidarResposta />} />
      </Route>
    )
  }

  const Feedback = (): React.ReactNode => {
    return (
      <Route path='Feedback'>
        <Route path='Dashboard'>
          <Route path='Individual' element={<DashboardIndividualFeedback />}/>
          <Route path='Corporativo' element={<DashboardCorporativoFeedback/>}/>
        </Route>

        <Route path='Visualizar/:feedbackId' element={<VisualizarRespostasFeedback />} />
        <Route path='Configuracao'>
          <Route path='' element={<ConfigurarFeedback />} />
          <Route path='Editar/:feedbackId' element={<ConfigurarFeedback />} />
        </Route>
      </Route>
    )
  }

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path='/LogOut' element={<LogOut />} />

        <Route element={<Rotas />}>
          <Route path='Usuario'>
            <Route path='Permissoes' element={<GerenciarPermissoes />} />
            <Route path='AlterarSenha' element={<AlterarSenha />} />
            <Route path='Empresa'>
              <Route path='' element={<ConfiguracaoEmpresa />} />
              <Route path='NineBox'>
                <Route path='' element={<ConfigNineBox />} />
                <Route path=':id' element={<ConfigNineBox />} />
              </Route>
              <Route path='Feedback'>
                <Route path='Formularios' element={<FormulariosFeedback />} />
                <Route path='EditarFormulario/:formularioId' element={<EditarFormularioFeedback />} />
              </Route>
            </Route>
            <Route path='ChamadoRh' element={<ChamadoRh />} />
            <Route path='PerguntasFrequentes/Editar' element={<PerguntasFrequentesRh />} />
            <Route path='PerguntasFrequentes' element={<PerguntasFrequentesColab />} />
          </Route>

          <Route path='Rh'>
            <Route path='VisualizarPerfil/:colaboradorId' element={<VisulaizarTrilhaColaborador />} />
            <Route path='GestaoAgendamentos' element={<GestaoAgendamentos />} />
          </Route>

          <Route path='Organograma'>
            <Route path='Cargos' element={<CargoDesenvolvimento />} />
            <Route path='Colaboradores' element={<ColaboradoresDesenvolvimento />} />
            <Route path='Dashboard' element={<DashboardDesenvolvimento />} />
            <Route path='Departamentos' element={<DepartamentoDesenvolvimento />} />
          </Route>

          <Route path='Engajamento'>
            <Route path='' element={<EngajamentoDashboard />} />
            <Route path='eNPS' element={<EngajamentoeNps />} />
            <Route path='ConfigNps' element={<ConfigNps />} />
            <Route path='Gestao' element={<GestaoPesquisasEngajamento />} />
            <Route path='SelecionarModelo' element={<SelecionarModeloEngajamento />} />
            <Route path='Diagnostico' element={<DiagnosticoEngajamento />} />
            <Route path='PEC'>
              <Route path='Wiz/:id' element={<CriarPesquisaEngajamento />} />
              <Route path='Demografia/:pesquisaId' element={<PECDemografia />} />
              <Route path='Formulario/:pesquisaId' element={<FormularioPesquisaPEC />} />
              <Route path='Resultado/:pesquisaId' element={<ResultadoPec />} />
              <Route path='ResultadoPro/:pesquisaId' element={<ResultadoPec />} />
              <Route path='VisualizarFormulario/:pesquisaId' element={<VisualizarFormularioPec />} />
              <Route path=':pesquisaId/PrevisualizarFormulario' element={<RespostaPesquisaPec />} />
              <Route path='ConfigurarEnvio/:pesquisaId' element={<ConfiguracaoPEC />} />
              <Route path='Convites/:pesquisaId' element={<ConvitesPec />} />
              <Route path='f/:tokenId/Previsualizar' element={<AberturaPesquisaPec />} />
              <Route path=':pesquisaId/CartaConvite/:id' element={<CartaConvite />} />
            </Route>
          </Route>

          <Route path='Jornada'>
            <Route path='Percepcao'>
              <Route path='Gestao' element={<GestaoPesquisas />} />
              <Route path='SelecionarModelo' element={<SelecionarModelo />} />
              {PesquisaQuantitativa()}
              {PesquisaIndicativa()}
            </Route>

            <Route path='PesquisaInterna'>
              <Route path=':pesquisaId/Formularios' element={<FormulariosPesquisaInterna />} />
              <Route path='Gestao' element={<GestaoPesquisaInternaJornada />} />
              <Route path='Grupos' element={<GestaoGruposPesquisaInternaJornada />} />
              <Route path='Gestao/:paramId/Previsualizar' element={<PreenchimentoPesquisaInterna />} />
              <Route path='Formulario/:paramId/Previsualizar' element={<PreenchimentoPesquisaInterna />} />
              <Route path='Criar' element={<CriarPesquisaInterna />} />
              <Route path='Resultado/:pesquisaId' element={<ResultadoPesquisaInterna />} />
              <Route path='Formulario/:pesquisaId' element={<FormularioPesquisaInterna />} />
              <Route path='Aprovacao/:pesquisaId' element={<AprovacaoPesquisaInterna />} />
              <Route path='Configuracao/:pesquisaId' element={<ConfiguracaoPesquisaInterna />} />
              <Route path='Convites/:pesquisaId' element={<ConvitesPesquisaInterna />} />
              <Route path='Abertura/:paramId/Previsualizar' element={<AberturaPesquisaInterna />} />
            </Route>

            <Route path='Imersao'>
              <Route path='' element={<GestaoImersao />} />

              <Route path=':imersaoId'>
                <Route path='' element={<CriarImersao />} />
                <Route path='Convites' element={<GestaoConvitesImersao />} />
                <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
              </Route>
            </Route>

            <Route path='Configuracao'>
              <Route path='' element={<TrilhaOnboard />} />
              <Route path='Trilha'>
                <Route path=':trilhaId' element={<ConfigurarTrilha />} />

                <Route path=':trilhaId/Reuniao'>
                  <Route path='' element={<Reuniao />} />
                  <Route path='Editar/:etapaId' element={<Reuniao />} />
                </Route>

                <Route path=':trilhaId/Imersao'>
                  <Route path='' element={<ConfigImersao />} />

                  <Route path=':imersaoId'>
                    <Route path='' element={<ModifyImersao />} />
                    <Route path='Previsualizar' element={<PrevisualizarImersao />} />

                    <Route path='Editar/:etapaId'>
                      <Route path='' element={<ModifyImersao />} />
                      <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                      <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                    </Route>

                    <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                  </Route>
                </Route>

                <Route path=':trilhaId/Integracao'>
                  <Route path='' element={<ConfigIntegracao />} />

                  <Route path=':imersaoId'>
                    <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                    <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                    <Route path='' element={<ModifyIntegracao />} />

                    <Route path='Editar/:etapaId'>
                      <Route path='' element={<ModifyIntegracao />} />
                      <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                      <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                    </Route>

                  </Route>
                </Route>

                <Route path=':id/eNps'>
                  <Route path='Criar' element={<CriarEnpsJornada />} />
                  <Route path='Editar/:etapaId' element={<CriarEnpsJornada />} />
                </Route>

                <Route path=':trilhaId/PesquisaInterna'>
                  <Route path=':pesquisaId/Formularios' element={<FormulariosPesquisaInterna />} />
                  <Route path='Convites/:pesquisaId' element={<ConvitesPesquisaInterna />} />
                  <Route path='Criar' element={<CriarPesquisaInterna />} />
                  <Route path='Editar/:etapaId' element={<CriarPesquisaInterna />} />
                  <Route path='Formulario/:pesquisaId' element={<FormularioPesquisaInterna />} />
                  <Route path='Aprovacao/:pesquisaId' element={<AprovacaoPesquisaInterna />} />
                  <Route path='Configuracao/:pesquisaId' element={<ConfiguracaoPesquisaInterna />} />
                </Route>

                <Route path=':trilhaId/PDI'>
                  <Route path='' element={<EtapaPDI />} />
                  <Route path=':etapaId' element={<EtapaPDI />} />
                </Route>

                <Route path=':trilhaId/ReuniaoOne/Configuracao'>
                  <Route path='' element={<OldConfigReuniaoOne />}>
                    <Route path=':etapaId' element={<OldConfigReuniaoOne />} />
                  </Route>
                </Route>

                <Route path=':trilhaId/NovaReuniaoOne'>
                  <Route path='Criar' element={<ConfigReuniaoOne />} />
                  <Route path='Editar/:etapaId' element={<ConfigReuniaoOne />} />

                  <Route path='Feedback/:feedbackId'>
                    <Route path='Formularios' element={<FormulariosFeedback />} />
                    <Route path='EditarFormulario/:formularioId' element={<EditarFormularioFeedback />} />
                    <Route path='Configuracao'>
                      <Route path='' element={<ConfigurarFeedback />} />
                      <Route path='Editar' element={<ConfigurarFeedback />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path='Processo'>
              <Route path=':processoId/ReuniaoOne/Configuracao'>
                <Route path='' element={<OldConfigReuniaoOne />}>
                  <Route path=':etapaId' element={<OldConfigReuniaoOne />} />
                </Route>
              </Route>

              <Route path=':processoId/Reuniao'>
                <Route path='' element={<Reuniao />} />
                <Route path='Editar/:etapaId' element={<Reuniao />} />
              </Route>

              <Route path=':processoId/Imersao'>
                <Route path='' element={<ConfigImersao />} />

                <Route path=':imersaoId'>
                  <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                  <Route path='' element={<ModifyImersao />} />

                  <Route path='Editar/:etapaId'>
                    <Route path='' element={<ModifyImersao />} />
                    <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                    <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                  </Route>

                  <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                </Route>
              </Route>

              <Route path=':processoId/Integracao'>
                <Route path='' element={<ConfigIntegracao />} />

                <Route path=':imersaoId'>
                  <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                  <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                  <Route path='' element={<ModifyIntegracao />} />

                  <Route path='Editar/:etapaId'>
                    <Route path='' element={<ModifyIntegracao />} />
                    <Route path='Previsualizar' element={<PrevisualizarImersao />} />
                    <Route path='Item/:itemId' element={<PrevisualizarImersaoIndividual />} />
                  </Route>

                </Route>
              </Route>

              <Route path=':id/eNps'>
                <Route path='Criar' element={<CriarEnpsJornada />} />
                <Route path='Editar/:etapaId' element={<CriarEnpsJornada />} />
              </Route>

              <Route path=':processoId/PesquisaInterna'>
                <Route path=':pesquisaId/Formularios' element={<FormulariosPesquisaInterna />} />
                <Route path='Convites/:pesquisaId' element={<ConvitesPesquisaInterna />} />
                <Route path='Criar' element={<CriarPesquisaInterna />} />
                <Route path='Editar/:etapaId' element={<CriarPesquisaInterna />} />
                <Route path='Formulario/:pesquisaId' element={<FormularioPesquisaInterna />} />
                <Route path='Aprovacao/:pesquisaId' element={<AprovacaoPesquisaInterna />} />
                <Route path='Configuracao/:pesquisaId' element={<ConfiguracaoPesquisaInterna />} />
              </Route>

              <Route path=':processoId/PDI'>
                <Route path='' element={<EtapaPDI />} />
                <Route path=':etapaId' element={<EtapaPDI />} />
              </Route>

              <Route path=':processoId/NovaReuniaoOne'>
                <Route path='Criar' element={<ConfigReuniaoOne />} />
                <Route path='Editar/:etapaId' element={<ConfigReuniaoOne />} />

                <Route path='Feedback/:feedbackId'>
                  <Route path='Formularios' element={<FormulariosFeedback />} />
                  <Route path='EditarFormulario/:formularioId' element={<EditarFormularioFeedback />} />
                  <Route path='Configuracao'>
                    <Route path='' element={<ConfigurarFeedback />} />
                    <Route path='Editar' element={<ConfigurarFeedback />} />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path='eNps'>
              <Route path='' element={<GestaoEnpsJornada />} />
              <Route path='Resultado/:pesquisaId' element={<ResultadoEnpsJornada />} />
            </Route>

            <Route path='Onboard' element={<GestaoFuncionarios />} />
            <Route path='TurnOver' element={<TurnOver />} />
            <Route path='Dashboard' element={<DashboardJornada />} />

            <Route path='NovaReuniaoOne'>
              <Route path='Criar' element={<ConfigReuniaoOne />} />
              <Route path='Editar/:reuniaoId' element={<ConfigReuniaoOne />} />

              <Route path='Visualizar/:reuniaoId'>
                <Route path='' element={<AssuntoReuniaoOne />} />
                <Route path='Feedback/:feedbackId/Visualizar' element={<VisualizarRespostasFeedback />} />
              </Route>

              <Route path='Dashboard'>
                <Route path='Corporativo' element={<DashboardCorporativoReuniaoOne />} />
                <Route path='Individual' element={<DashboardIndividualReuniaoOne />} />
              </Route>

              <Route path='Feedback/:feedbackId'>
                <Route path='Formularios' element={<FormulariosFeedback />} />
                <Route path='EditarFormulario/:formularioId' element={<EditarFormularioFeedback />} />
                <Route path='Configuracao'>
                  <Route path='' element={<ConfigurarFeedback />} />
                  <Route path='Editar' element={<ConfigurarFeedback />} />
                </Route>
                <Route path='VisualizarRespostas' element={<VisualizarRespostasFeedback />} />
              </Route>
            </Route>

           {Feedback()}

            <Route path='ReuniaoOne'>
              <Route path='' element={<DashboardReuniaoOne />} />
              <Route path='Configuracao'>
                <Route path='' element={<OldConfigReuniaoOne />} />
                <Route path=':reuniaoId' element={<OldConfigReuniaoOne />} />
                <Route path=':reuniaoId/Remarcar' element={<OldConfigReuniaoOne />} />
              </Route>
            </Route>

            <Route path='FeedbackPersonalizado'>
              <Route path='' element={<FluxoPersonalizado />} />
              <Route path='Dashboard' element={<DashboardFluxoPersonalizado />} />
              <Route path=':id' element={<FluxoPersonalizado />} />
              <Route path=':id/Feedback/Formulario/:formularioId' element={<EditarFormularioFeedback />} />
              <Route path=':feedbackId/Pesquisa/Formulario/:formularioId' element={
                <FormularioProvider>
                  <FormularioPesquisa />
                </FormularioProvider>}
              />
              <Route path=':feedbackId/Pesquisa/VisualizarFormulario/:formularioId'>
                <Route path='' element={<VisualizarFormulario />} />
                <Route path='Previsualizar' element={<RespostaPesquisa />} />
              </Route>
            </Route>
          </Route>

          <Route path='Desempenho'>
            <Route path='AnaliseDesempenho'>
              <Route path='Gestao' element={<GestaoPesquisas />} />
              <Route path='SelecionarModelo' element={<SelecionarModelo />} />
              {PesquisaQuantitativa()}
              {PesquisaIndicativa()}
            </Route>

            <Route path='Indicadores'>
                <Route path='' element={<Indicadores />}/>
                <Route path='Adm' element={<IndicadoresAprovar />}/>
            </Route>

            <Route path='Formularios'>
              <Route path='Gestao' element={<GestaoFormularios />}/>
            </Route>

            <Route path='9box'>
              <Route path='' element={<NineBox />} />
              <Route path=':colaboradorId' element={<UsuarioEixo />} />
            </Route>

            <Route path='Dashboard' element={<DashboardDesempenho />} />

            <Route path='PDI'>
              <Route path='Dashboard/Corporativo' element={<DashboardCorporativoPDI />} />
              <Route path='Dashboard/Individual' element={<DashboardIndividualPDI />} />
              <Route path='Criar' element={<CriarPDI />} />
              <Route path='Editar/:pdiId' element={<CriarPDI />} />
              <Route path='Avaliacao/:pdiId' element={<AvaliacaoPDI />} />
            </Route>

            <Route path='ReuniaoOne'>
              <Route path='' element={<DashboardReuniaoOne />} />
              <Route path='Configuracao' element={<OldConfigReuniaoOne />}>
                <Route path=':reuniaoId' />
                <Route path=':reuniaoId/Remarcar' />
              </Route>
            </Route>

            <Route path='NovaReuniaoOne'>
              <Route path='Criar' element={<ConfigReuniaoOne />} />
              <Route path='Editar/:reuniaoId' element={<ConfigReuniaoOne />} />

              <Route path='Visualizar/:reuniaoId'>
                <Route path='' element={<AssuntoReuniaoOne />} />
                <Route path='Feedback/:feedbackId/Visualizar' element={<VisualizarRespostasFeedback />} />
              </Route>

              <Route path='Dashboard'>
                <Route path='Corporativo' element={<DashboardCorporativoReuniaoOne />} />
                <Route path='Individual' element={<DashboardIndividualReuniaoOne />} />
              </Route>

              <Route path='Feedback/:feedbackId'>
                <Route path='Formularios' element={<FormulariosFeedback />} />
                <Route path='EditarFormulario/:formularioId' element={<EditarFormularioFeedback />} />
                <Route path='Configuracao'>
                  <Route path='' element={<ConfigurarFeedback />} />
                  <Route path='Editar' element={<ConfigurarFeedback />} />
                </Route>
                <Route path='VisualizarRespostas' element={<VisualizarRespostasFeedback />} />
              </Route>
            </Route>

            {Feedback()}
          </Route>

          <Route path='AssessmentPI'>
            <Route path='Dashboard' element={<DashboardPi />} />
            <Route path='Dashboard2' element={<DashBoardJobTarget />} />
            <Route path='JobTarget' element={<DetalhesCargo />} />
            <Route path='JobTarget/:jobId' element={<DetalhesCargo />} />
            <Route path='JobTarget/Create' element={<JobTargetCreate />} />
            <Route path='JobTarget/Edit/:jobId' element={<JobTargetCreate />} />
            <Route path='Perfil/:perfilId' element={<PerfilPi />} />
            <Route path='Perfil/' element={<PerfilPi />} />
            <Route path='Send' element={<SendAssessmentPI />} />
          </Route>

          <Route path='CentralComando'>
            <Route path='' element={<CentralComando />} />
            <Route path='Dashboard' element={<CentralComando />} />
            <Route path='Criar' element={<CriarEmpresa />} />
            <Route path='Editar/:empresaId' element={<EditarEmpresa />} />
          </Route>

          <Route path='PrimeiroAcesso' element={<PrimeroAcesso />} />
          <Route path='Colaborador' element={<Painel />} />
          <Route path='Colaborador/Imersao/:interacaoId/Etapa/:etapaId' element={<ImersaoColaborador />} />
          <Route path='Colaborador/Imersao/:interacaoId/Etapa/:etapaId/Visualizar' element={<ImersaoColaborador />} />
          <Route path='Colaborador/Integracao/:interacaoId/Etapa/:etapaId' element={<IntegracaoColaborador />} />
          <Route path='Feedback/Preenchimento/:feedbackId' element={<PreenchimentoFeedback />} />
          <Route path='Feedback/Preenchimento/:feedbackId/Previsualizar' element={<PreenchimentoFeedback />} />
          <Route path='Feedback/Abertura/:feedbackId' element={<AberturaPreenchimentoFeedback />} />
          <Route path='Feedback/Abertura/:feedbackId/Previsualizar' element={<AberturaPreenchimentoFeedback />} />
          <Route path='beta' element={<Beta />} />
        </Route>

        <Route path='/' element={<Login />} />
        <Route path='pi/:jobId' element={<EnviarPI />} />
        <Route path='Abertura/:tokenId' element={<AberturaPesquisa />} />
        <Route path='Engajamento/Pec/:tokenId/ResponderPesquisa' element={<RespostaPesquisaPec />} />
        <Route path='Engajamento/Pec/f/:tokenId' element={<AberturaPesquisaPec />} />
        <Route path='Engajamento/Pec/c/:codigo' element={<AberturaPesquisaPec />} />
        <Route path='ResponderPec/' element={<DigitarChavePec />} />
        <Route path='RespostaNps/:conviteId' element={<RespostaNps />} />
        <Route path='RespostaPesquisa' element={<RespostaPesquisa />} />
        <Route path='PDI/Avaliacao/:pdiId' element={<AvaliacaoPDI />} />
        <Route path='RespostaPesquisaIndicativa' element={<RespostaIndicativa />} />
        <Route path='VisualizarImersao/:conviteId' element={<VisualizarImersao />} />
        <Route path='Processo/:processoId/ReuniaoOne/:etapaId/Agendar' element={<AgendamentoReuniaoOne />} />
        <Route path='Rh/Reuniao/Config/:reuniaoId/:processoId' element={<ConfiguracaoReuniao />} />
        <Route path='Rh/Reuniao/Config/:reuniaoId' element={<ConfiguracaoReuniao />} />
        <Route path='Reuniao/:processoId/:etapaId/Concluir' element={<ReuniaoRealizada />} />
        <Route path='ReuniaoOne/:processoId/:etapaId/Concluir' element={<ReuniaoRealizada />} />
        <Route path='Usuario/EsqueciSenha' element={<EsqueciSenha />} />
        <Route path='Privacidade' element={<Privacidade />} />
        <Route path='PI/Preenchimento/:paramId' element={<PreenchimentoPesquisaInterna />} />
        <Route path='PI/Abertura/:paramId' element={<AberturaPesquisaInterna />} />
        <Route path='RespostaPesquisas/Consultor/:pesquisaId' element={<ConsultorPEC />} />
        <Route path='RespostaPesquisas/Csv/:pesquisaId' element={<BaseCsv />} />
      </Routes>
    </Router>
  )
}
