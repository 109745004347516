import { Image, Text, View } from '@react-pdf/renderer'

interface iProps {
  nomeEmpresa: string
}

export const Footer: React.FC<iProps> = ({ nomeEmpresa }) => {
  return (
    <View
        style={{
          backgroundColor: '#66046B',
          paddingVertical: 8,
          paddingHorizontal: 22,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}
    >
        <Text style={{ fontSize: '10px', color: '#ffffff', fontFamily: 'Roboto' }}>Este relatório é de uso exclusivo da empresa {nomeEmpresa}.</Text>
        <View>
            <Image
                style={{
                  height: 22,
                  objectFit: 'contain'
                }}
                source="https://sigah.blob.core.windows.net/onboard/0acc742b-1a78-4d9e-9bfd-26b6c1f46c19.png"
            />
        </View>
    </View>
  )
}
