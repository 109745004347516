/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { useEffect, useState } from 'react'
import { GraficoPEC } from '../../../../interfaces'

interface iProps {
  id: string
  dados: GraficoPEC[]
  setGrafico: (str: string) => void
}

export const ComparativoChartPDF: React.FC<iProps> = ({ id, dados, setGrafico }) => {
  const [Position, setPosition] = useState('')
  const [OffsetY, setOffsetY] = useState(0)
  const [Orientation, setOrientation] = useState('')

  function getSeries(): any {
    const response: any[] = []
    if (dados[0]?.labels && dados[0]?.serie && dados[1]?.serie) {
      if (dados[0]?.labels?.length < 12) {
        setPosition('top')
        setOrientation('horizontal')
        setOffsetY(-20)
      } else {
        setPosition('top')
        setOffsetY(-40)
        setOrientation('vertical')
      }
      response.push({
        name: dados[0].labels[0],
        data: dados[0].serie
      })
      response.push({
        name: dados[0].labels[0],
        data: dados[1].serie
      })
    }

    return response
  }

  const colors = [
    '#fea611',
    'rgb(56, 99, 176)',
    '#ffb347',
    '#ffcc66',
    '#366ed8',
    '#285bac',
    '#fff'
  ]

  async function GerarGrafico(): Promise<void> {
    const options = {
      colors: colors,
      series: getSeries(),
      chart: {
        type: 'bar',
        height: 450,
        width: 900,
        animations: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'rounded',
          dataLabels: {
            position: Position,
            orientation: Orientation
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: OffsetY,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          if (val === -1) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Média dos atributos', 'Média do engajamento'],
        position: 'top',
        fontSize: '16px',
        itemMargin: {
          horizontal: 10
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: dados[0].labels
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: 10,
        show: false
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          },
          title: {
            formatter: function (val: number, { series, seriesIndex }: { series: any, seriesIndex: any }) {
              return seriesIndex === 0 ? 'Média dos atributos' : 'Média do engajamento'
            }
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()

    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })

    setGrafico(dataURL.imgURI)
  }

  useEffect(() => {
    GerarGrafico()
  }, [dados])

  return (
        <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}
