import styled from 'styled-components'
interface iProps {
  color: string
}

export const Container = styled.div<iProps>`
  cursor: pointer;
  font-size: .925rem;
  border-radius: 0.75rem;
  display: flex;
  width: 100%;
  height: 100%;
  border: 2px solid var(--c1);
  :hover{
      border-color: var(--${props => props.color});
      font-weight: 700;
      box-shadow: var(--SombraBackground);
  }
  `
