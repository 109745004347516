/* eslint-disable array-callback-return */
import { Flex, Text } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { FaArrowCircleDown, FaArrowCircleRight, FaChartPie, FaCheckCircle, FaEdit, FaPencilAlt, FaPlus, FaShare, FaTrash } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { iAtributo, iDemografiaPEC, PerguntaPec } from '../../../../../../interfaces'
import { CollapseText, ColumnIcons, CompetenciaContent, CompetenciaLine, CompetenciaMenu, Container, ImgText, SectionQuestoes, StyledQuestion } from './styles'

interface iProps {
  atributo: iAtributo
  selecionadas: string[]
  demografiaArea?: iDemografiaPEC
  RemoverAtributo: (atributoId: string) => void
  RemoverQuestaoVinculada: (questaoId: string) => void
  SelecionarQuestao: (atributoId: string, questaoId?: string) => void
  onVincularQuestao: (questaoId: string, jaVinculadas: string[], vinculoId?: string) => void
  onVincularDemografia: (demografiaId: string, atributoId: string, questaoId: string) => void
  onModalQuestao: (atributoId: string, questaoId?: string, duplicar?: boolean) => void
  onOpenModalDelete: (atributoId: string, questaoId: string) => void
  Edit: () => void
}

export const AtributoEngajamento: React.FC<iProps> = ({ onModalQuestao, onOpenModalDelete, Edit, atributo, selecionadas, SelecionarQuestao, RemoverAtributo, demografiaArea, onVincularDemografia, onVincularQuestao, RemoverQuestaoVinculada }) => {
  const [AddDemografia, setAddDemografia] = useState<Array<{attId: string, pergId: string}>>([])
  const [DemografiaSelecionada, setDemografiaSelecionada] = useState('1')
  const [isOpen, setisOpen] = useState(false)
  useEffect(() => {
    setisOpen(atributo.perguntas?.filter((e: PerguntaPec) => {
      if (selecionadas.some(r => r === e.id)) {
        return e
      }
    }).length === 0)
  }, [atributo])

  function OcultarVinculos(): boolean {
    return (atributo.id !== 'b067640d-dfe0-475f-9a5c-8c7541efede1' && atributo.id !== 'a92f6984-e374-4bb5-a319-69ac12c3804f' && atributo.id !== '4fd26649-b408-478d-b4a7-c797163f1a7a' && atributo.id !== 'ab2d00c8-67db-4477-9460-cf440a6964cb')
  }

  function ReturnAtrelada(ids: string[], pergunta?: PerguntaPec, temVinculo?: boolean): any {
    const arr = ids
    if (temVinculo && ids.length === 1) return <></>
    if (pergunta) {
      arr.push(pergunta.id)
      return (<>
        <Flex alignItems={'center'} justifyContent={'space-between'} fontSize={'13px'} ml={'2rem'} pl={'.5rem'} pr={'.5rem'} py={'.25rem'}>
          <Flex gap={'.5rem'} alignItems={'center'} color={'var(--Azul)'}>
            <FaShare color='black'/>
            {pergunta.pergunta}
          </Flex>
          <Flex justifyContent={'end'} gap={'.625rem'}>
            {!pergunta.perguntaAtrelada && <ElementoTooltipCmp
            background={'var(--Green2)'} label={'Vincular questão'}
            >
              <FaShare size={13} cursor={'pointer'} color='var(--Green2)' onClick={() => onVincularQuestao(pergunta.id, arr, pergunta.vinculoId) }/>
            </ElementoTooltipCmp>}
            {!pergunta.perguntaAtrelada && <ElementoTooltipCmp
            background={'var(--Red)'} label={'Remover questão vinculada'}
            >
              <FaTrash color='Red' size={13} cursor={'pointer'} onClick={() => RemoverQuestaoVinculada(pergunta.id)}/>
            </ElementoTooltipCmp>}
          </Flex>
        </Flex>
        {ReturnAtrelada(arr, pergunta.perguntaAtrelada, pergunta.temVinculo)}
      </>)
    } else {
      return <></>
    }
  }

  return (
    <Container>
      <CompetenciaMenu>
        <ImgText>
          <img src={atributo.avatar} />
          <span>{atributo.nome}</span>
        </ImgText>
        <ColumnIcons>
          {
            (atributo.empresaId && atributo.empresaId !== '86d5c9f2-0645-41dd-8253-a4918de9f6f2') && (
              <span onClick={() => {
                Edit()
              }} style={{ color: 'var(--Azul)' }}><FaEdit style={{ marginRight: '.125rem' }}
                />Editar</span>
            )
          }
          <Flex onClick={() => RemoverAtributo(atributo.id)} cursor={'pointer'} alignItems={'center'} gap={'.325rem'} mt={'.25rem'} fontSize={'13px'} color={'var(--Red)'} _hover={{ textDecoration: 'underline' }}><FaTrash/> Remover</Flex>
        </ColumnIcons>
      </CompetenciaMenu>
      <Flex flexDirection={'column'} width='100%' justifyContent={'space-between'} px={'.5rem'}>

        <CompetenciaContent>
          {
            atributo.perguntas?.filter((e: PerguntaPec) => {
              if (selecionadas?.find(r => r === e.id) !== undefined) {
                return e
              }
              return null
            }).map((e: PerguntaPec, i) => {
              if (!e.discursiva) {
                return (
                  <Flex flexDir={'column'}>
                    <CompetenciaLine key={i}>
                      <StyledQuestion onClick={() => SelecionarQuestao(atributo.id, e.id)}>
                        {e.temVinculo ? <Flex w={'1.5rem'} h={'1.5rem'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} border={'2px solid var(--Green3)'}><FaShare size={11} color='var(--Green3)' /></Flex> : <FaCheckCircle size={26} color='var(--Azul)' />}
                        <span>{e.pergunta}</span>
                      </StyledQuestion>
                      <Flex gap={'.75rem'} alignItems={'center'} justifyContent={'flex-end'} >
                        {OcultarVinculos() && !e.perguntaAtrelada && !e.temVinculo && <ElementoTooltipCmp
                        background={'var(--Azul)'} label={'Vincular demografia'}
                        >
                          <FaChartPie size={18} color='var(--Azul)' onClick={() => setAddDemografia([...AddDemografia, { attId: e.atributoId, pergId: e.id }]) }/>
                        </ElementoTooltipCmp>}
                        {OcultarVinculos() && !e.perguntaAtrelada && !e.temVinculo && <ElementoTooltipCmp
                        background={'var(--Green2)'} label={'Vincular questão'}
                        >
                          <FaShare size={18} color='var(--Green2)' onClick={() => onVincularQuestao(e.id, [e.id]) }/>
                        </ElementoTooltipCmp>}
                        {
                          (e.empresaId && e.empresaId !== '86d5c9f2-0645-41dd-8253-a4918de9f6f2') && (
                            <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                              <ElementoTooltipCmp
                                background={'var(--Rosa)'} label={'Editar questão'}
                                >
                              <div>
                                  <FaEdit size={18} color='var(--Rosa)' onClick={() => onModalQuestao(atributo.id, e.id)} />
                                  </div>
                              </ElementoTooltipCmp>
                            <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                              <ElementoTooltipCmp
                              background={'var(--Red)'} label={'Excluir questão'}
                              >
                                <div>
                                  <FaTrash size={18} color='var(--Red)' onClick={() => onOpenModalDelete(atributo.id, e.id)} />
                                </div>
                            </ElementoTooltipCmp>
                            </Flex>
                          </Flex>

                          )
                        }
                      </Flex>
                    </CompetenciaLine>
                    {ReturnAtrelada([e.id], e.perguntaAtrelada, e.temVinculo)}
                    {e.nomeDemografia ? <Flex justifyContent={'end'} alignItems={'center'} gap={'.325rem'} color={'var(--Azul)'} fontSize={'.813rem'}><FaChartPie/><Text>Vinculado a <strong>{e.nomeDemografia}</strong></Text><Text ml={'1rem'} textDecor={'underline'} cursor={'pointer'} fontWeight={'700'} color={'var(--Green2)'} onClick={() => setAddDemografia([...AddDemografia, { attId: e.atributoId, pergId: e.id }])}><FaPencilAlt/> Editar vinculo</Text></Flex> : ''}
                    {AddDemografia.some(a => a.attId === e.atributoId && a.pergId === e.id) && <>
                      {demografiaArea
                        ? <>
                        <Flex gap={'.5rem'} mt={'.5rem'}>
                          <SelectCmp h={'1.625rem'} OnChange={setDemografiaSelecionada} value={DemografiaSelecionada}>
                            <option value={''}>Remover vínculo</option>
                            {demografiaArea.demografias?.map(e => {
                              return (<option value={e.id}>{demografiaArea.pergunta} / {e.nome}</option>)
                            })}
                          </SelectCmp>
                          <ButtonCmp h={'1.625rem'} VarColor='Green2' onClick={() => { onVincularDemografia(DemografiaSelecionada, e.atributoId, e.id); setAddDemografia(AddDemografia.filter(a => a.attId !== e.atributoId && a.pergId !== e.id)) }}>Salvar</ButtonCmp>
                          <ButtonCmp h={'1.625rem'} VarColor='Red' onClick={() => setAddDemografia(AddDemografia.filter(a => a.attId !== e.atributoId && a.pergId !== e.id))}>Cancelar</ButtonCmp>
                        </Flex>
                      </>
                        : <Flex mt={'.5rem'} gap={'.5rem'} alignItems='center'><Text fontSize={'14px'} color={'var(--Red)'}>Você precisa adicionar o grupo de análise Área para poder vincular a questão!</Text><ButtonCmp h={'1.625rem'} VarColor='Red' onClick={() => setAddDemografia(AddDemografia.filter(a => a.attId !== e.atributoId && a.pergId !== e.id))}>Cancelar</ButtonCmp></Flex>}
                    </>}
                  </Flex>
                )
              } else {
                return (
                  <CompetenciaLine key={i}>
                    <StyledQuestion onClick={() => SelecionarQuestao(atributo.id, e.id)}>
                      <FaCheckCircle size={20} color='var(--Azul)' />
                      <span>{e.pergunta}</span>
                    </StyledQuestion>

                    <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    {/* <ElementoTooltipCmp
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Azul)'} label={'Duplicar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                      <FaCopy size={20} color='var(--Azul)' onClick={() => onModalQuestao(atributo.id, e.id, true) }/>
                      </div>
                      </ElementoTooltipCmp> */}
                      {
                        (e.empresaId && e.empresaId !== '86d5c9f2-0645-41dd-8253-a4918de9f6f2') && (
                          <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <ElementoTooltipCmp
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Roxo)'} label={'Editar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                            <FaEdit size={20} color='var(--Roxo)' onClick={() => onModalQuestao(atributo.id, e.id)} />
                            </div>
                            </ElementoTooltipCmp>
                            <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                            <ElementoTooltipCmp
                      background={'var(--Red)'} label={'Excluir questão'}
                      >
                        <div>
                            <FaTrash size={20} color='var(--Red)' onClick={() => onOpenModalDelete(atributo.id, e.id)} />
                            </div>
                            </ElementoTooltipCmp>
                          </Flex>
                          </Flex>
                        )
                      }
                    </Flex>
                  </CompetenciaLine>
                )
              }
            })
          }

          {
            (atributo?.perguntas && atributo?.perguntas?.filter((e: PerguntaPec) => {
              if (selecionadas?.find(r => r === e.id) !== undefined) {
                return e
              }
              return null
            })?.length < atributo.perguntas?.length) && (
              <CollapseText onClick={() => { setisOpen(!isOpen) }}>
                {
                  !isOpen
                    ? <FaArrowCircleDown />
                    : <FaArrowCircleRight />
                }
                <span>Clique aqui para recolher as questões não selecionadas</span>
              </CollapseText>
            )
          }

          <SectionQuestoes active={isOpen}>
            {
              atributo?.perguntas && atributo.perguntas.filter((e: PerguntaPec) => {
                if (selecionadas?.find(r => r === e.id) === undefined) {
                  return e
                }
                return null
              })
                .map((e: PerguntaPec, i) => {
                  return (
                  <CompetenciaLine key={i}>
                    <StyledQuestion onClick={() => SelecionarQuestao(atributo.id, e.id)}>
                      <FaCheckCircle size={20} color='var(--c6)' />
                      <span>{e.pergunta}</span>
                    </StyledQuestion>
                    <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    {/* <ElementoTooltipCmp
                      background={'var(--Azul)'} label={'Duplicar questão'}
                      >
                        <div>
                      <FaCopy color='var(--Azul)' onClick={() => onModalQuestao(atributo.id, e.id, true) }/>
                      </div>
                      </ElementoTooltipCmp> */}
                      {
                        (e.empresaId && e.empresaId !== '86d5c9f2-0645-41dd-8253-a4918de9f6f2') && (
                          <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <ElementoTooltipCmp
                      background={'var(--Rosa)'} label={'Editar questão'}
                      >
                        <div>
                            <FaEdit color='var(--Rosa)' onClick={() => onModalQuestao(atributo.id, e.id)} />
                            </div>
                            </ElementoTooltipCmp>
                            <Flex gap={'.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
                            <ElementoTooltipCmp
                      background={'var(--Red)'} label={'Excluir questão'}
                      >
                        <div>
                            <FaTrash color='var(--Red)' onClick={() => onOpenModalDelete(atributo.id, e.id)} />
                            </div>
                            </ElementoTooltipCmp>
                            </Flex>
                          </Flex>

                        )
                      }
                    </Flex>
                  </CompetenciaLine>
                  )
                })
            }
          </SectionQuestoes>
        </CompetenciaContent>
        <Flex justifyContent={'end'} alignItems={'end'} margin={'0rem 0rem .5rem 0rem'}>
          {!(atributo.id === 'a92f6984-e374-4bb5-a319-69ac12c3804f' || atributo.id === '4fd26649-b408-478d-b4a7-c797163f1a7a') && <ButtonCmp
            onClick={() => onModalQuestao(atributo.id)} leftIcon={<FaPlus size={12}/>} VarColor='Green2'>Criar questão</ButtonCmp>}
        </Flex>
      </Flex>

    </Container>
  )
}
