/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Flex, Grid, Text, useToast
} from '@chakra-ui/react'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { Body } from '../../../Layouts/Body'
import { Main } from '../../../PesquisaIndicativa/Criar/styles'
import { iEscala } from '../../../../interfaces'
import { TimelineCriarPEC } from '../Components/Timeline'
import { useEffect, useState } from 'react'
import { FaBalanceScale, FaChevronDown, FaChevronRight, FaCogs, FaSearchLocation, FaSeedling } from 'react-icons/fa'
import { CardCultura } from './Components/CardCultura'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { appApi } from '../../../../services/appApi'
import { ButtonCmp } from '../../../../components/ButtonCmp'

interface iModel {
  nome: string
  escalaId: string
  id: string
  escalas: iEscala[]
}

interface iCultura {
  id: string
  tiposDeCultura: string
  categoria: number
}

export const DiagnosticoEngajamento: React.FC = () => {
  const [Stage, setStage] = useState(0)
  const [Culturas, setCulturas] = useState<iCultura[]>([])
  const [Selecionados, setSelecionados] = useState<string[]>([])
  const toast = useToast()
  const nav = useNavigate()

  function onSelectCheckbox(id: string): void {
    if (Selecionados.length === 3 && !Selecionados.includes(id)) {
      toast({
        title: 'Escolha apenas as 3 principais prioridades',
        duration: 3000,
        position: 'top',
        status: 'error'
      })
      return
    }
    if (Selecionados.includes(id)) {
      setSelecionados(Selecionados.filter(e => e !== id))
    } else {
      setSelecionados([...Selecionados, id])
    }
  }

  function onSelect(state: number): void {
    if (Stage === state) {
      setStage(0)
    } else {
      setStage(state)
    }
  }

  const verificarArray = (): void => {
    const id = uuidv4()
    const selecionados = Culturas.filter(e => Selecionados.includes(e.id)).map(e => e.categoria)

    if (selecionados.every(val => val === selecionados[0])) {
      SelecionarModelo(id, selecionados[0])
      return
    }

    const frequencia: Record<number, number> = {}
    selecionados.forEach(num => {
      frequencia[num] = (frequencia[num] || 0) + 1
    })

    let maisRepetido = selecionados[0]
    let maxFrequencia = 0

    for (const num in frequencia) {
      if (frequencia[num] > maxFrequencia) {
        maxFrequencia = frequencia[num]
        maisRepetido = parseInt(num)
      }
    }

    const todosDiferentes = selecionados.length === new Set(selecionados).size

    if (todosDiferentes) {
      SelecionarModelo(id, 4)
    } else {
      SelecionarModelo(id, maisRepetido)
    }
  }

  function SelecionarModelo(id?: string, cultura?: number, duplicar?: boolean): void {
    appApi.get(`Pec/Criar/Rota?id=${id ?? ''}&cultura=${cultura ?? ''}&duplicar=${duplicar ?? ''}`)
      .then(res => {
        nav(res.data)
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Ocorreu um erro para criar a pesquisa, tente novamente mais tarde',
          duration: 4000,
          status: 'error'
        })
      })
  }

  function GetCulturas(): void {
    appApi.get('Pec/Cultura')
      .then(res => setCulturas(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetCulturas()
  }, [])

  return (
    <Body>
      <TimelineCriarPEC color={['var(--Azul)', 'var(--Azul)']}/>
      <Main>
        <h1>Diagnóstico</h1>
        <WhiteContainer>
          <Flex flexDir={'column'} gap={'.625rem'} mb={'1rem'}>
            <Text fontSize={'14px'} color={'var(--Azul)'}>Para utilizar a versão de Pesquisa de Engajamento rápida (versão Express), customizada de acordo com a Cultura da Organização, há duas opções para entendimento geral dessa Cultura:</Text>
            <h3>Escolha uma opção</h3>
            <Flex borderRadius={'.325rem'} cursor={'pointer'} boxShadow={'var(--SombraBackground)'} flexDir={'column'} background={Stage === 1 ? 'var(--Azul)' : 'var(--c3)'} color={Stage === 1 ? 'var(--c1)' : 'black'} padding={'1rem'} fontSize={'14px'} onClick={() => onSelect(1)} border={`2px solid ${Stage === 1 ? 'var(--Azul)' : 'var(--c3)'}`} _hover={{ borderColor: !(Stage === 1) ? 'var(--Azul)' : 'var(--Red)' }}>
              <Text fontWeight={700}>{Stage === 1 ? <FaChevronDown/> : <FaChevronRight/>} Opção 1 – Já tenho clara a cultura predominante na minha organização (segundo o modelo de Cameron e Quinn):</Text>
              <Text>Clique no estereótipo cultural abaixo que predomina em sua organização:</Text>
            </Flex>
          </Flex>
          {Stage === 1 && <Grid padding={'2rem 5rem'} gridTemplateColumns={'repeat(2, 1fr)'} gap={'2rem'}>
            <CardCultura
              onSelect={() => nav(`/Engajamento/PEC/Wiz/${uuidv4()}?cultura=produzir`)}
              color={'Green3'}
              icon={<FaCogs/>}
              text='Resultados e disciplina (produzir)'
            />
            <CardCultura
              onSelect={() => nav(`/Engajamento/PEC/Wiz/${uuidv4()}?cultura=cultivar`)}
              color={'Orange'}
              icon={<FaSeedling/>}
              text='Trabalho em equipe e consenso (cultivar)'
            />
            <CardCultura
              onSelect={() => nav(`/Engajamento/PEC/Wiz/${uuidv4()}?cultura=explorar`)}
              color={'Yellow'}
              icon={<FaSearchLocation/>}
              text='Agilidade e conexão informal (explorar)'
            />
            <CardCultura
              onSelect={() => nav(`/Engajamento/PEC/Wiz/${uuidv4()}?cultura=estabilizar`)}
              color={'Azul'}
              icon={<FaBalanceScale/>}
              text='Processo e precisão (estabilizar)'
            />
          </Grid>}
          <Flex mt={'1rem'} borderRadius={'.325rem'} cursor={'pointer'} boxShadow={'var(--SombraBackground)'} flexDir={'column'} background={Stage === 2 ? 'var(--Azul)' : 'var(--c3)'} color={Stage === 2 ? 'var(--c1)' : 'black'} padding={'1rem'} fontSize={'14px'} onClick={() => onSelect(2)} border={`2px solid ${Stage === 2 ? 'var(--Azul)' : 'var(--c3)'}`} _hover={{ borderColor: !(Stage === 2) ? 'var(--Azul)' : 'var(--Red)' }}>
            <Text fontWeight={700}>{Stage === 2 ? <FaChevronDown/> : <FaChevronRight/>} Opção 2 – Não tenho clara a cultura predominante na minha  organização (segundo o modelo de Cameron e Quinn):</Text>
            <Text>Responda a pesquisa a abaixo, para que possamos selecionar as questões mais adequadas de acordo com o que a alta liderança de sua organização busca:</Text>
          </Flex>
          {Stage === 2 && <Flex flexDir={'column'} mt={'1rem'} padding={'0 2rem'}>
              <Text fontWeight={700} fontSize={'16px'}>Marque abaixo, as 3 principais prioridades que você acredita que a alta liderança da sua organização busca:</Text>
              <Flex flexDir={'column'} gap={'.75rem'} padding={'1rem 2.5rem'}>
                {Culturas.map(e => {
                  return (
                    <CheckboxCmp isChecked={Selecionados.includes(e.id)} OnChange={() => onSelectCheckbox(e.id)}>{e.tiposDeCultura}</CheckboxCmp>
                  )
                })}
              </Flex>
              <Flex justifyContent={'end'} mb={'1rem'}>
                <ButtonCmp onClick={verificarArray} VarColor='Green2' isDisabled={Selecionados.length !== 3}>Continuar</ButtonCmp>
              </Flex>
            </Flex>}
        </WhiteContainer>
      </Main>
    </Body>
  )
}
