/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex } from '@chakra-ui/layout'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import LoadingContainer from '../../../../components/LoadingContainer'
import { NavLine } from '../../../../components/NavLine'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { IModel } from './interfaces'
import { TabAnaliseEstatistica } from './tabs/AnaliseEstatisticaTab'
import { TabComentarios } from './tabs/ComentariosTab'
import { TabComparativaDemografia } from './tabs/ComparativoDemografiaTab'
import { TabComparativaPesquisa } from './tabs/ComparativoPesquisa'
import { TabRelatorioGeral } from './tabs/RelatorioGeral'

export const ResultadoPec: React.FC = () => {
  const nav = useNavigate()
  const { pesquisaId } = useParams<{pesquisaId: string}>()
  const { pathname } = useLocation()
  const [Model, setModel] = useState<IModel>()
  const [ActiveTab, setActiveTab] = useState(0)
  const [IsLoadingGeral, setIsLoadingGeral] = useState(false)
  const [IsLoadingComentarios, setIsLoadingComentarios] = useState(false)
  const [IsLoadingComparativo, setIsLoadingComparativo] = useState(false)

  function GetDados(didId?: string): void {
    setIsLoadingGeral(true)
    appApi.get(`ResultadoPec/${pesquisaId}?didId=${didId || ''}`)
      .then((res) => {
        if (didId) {
          setModel({ ...res.data, demografiasSemFiltro: Model?.demografiasSemFiltro })
        } else {
          setModel({ ...res.data, demografiasSemFiltro: res.data.demografias })
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadingGeral(false))
  }

  function GetComentarios(grupoComentarios?: string): void {
    setIsLoadingComentarios(true)
    appApi.get(`ResultadoPec/${pesquisaId}/Comentarios?comentariosFiltro=${grupoComentarios || ''}`)
      .then((res) => {
        if (Model) {
          setModel({ ...Model, comentarios: res.data })
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadingComentarios(false))
  }

  function GetComparativo(pesquisaCompId: string, demId: string): void {
    setIsLoadingComparativo(true)
    appApi.get(`ResultadoPec/${pesquisaId}?pesquisaCompId=${pesquisaCompId}&compDemId=${demId}`)
      .then((res) => {
        if (Model) {
          setModel({ ...Model, comparativoPerguntasLista: res.data.comparativoPerguntasLista, comparativoAtributos: res.data.comparativoAtributos })
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadingComparativo(false))
  }

  function onAprovar(): void {
    setIsLoadingGeral(true)
    appApi.patch(`ResultadoPec/LiberarResultados/${pesquisaId}`)
      .then((res) => {
        nav(`/Engajamento/Pec/Resultado/${pesquisaId}`)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadingGeral(false))
  }

  useEffect(() => {
    GetDados()
  }, [])

  return (<Body>
    <Flex margin={'1rem 0'} justifyContent={'space-between'}>
      <NavLine options={[
        {
          nome: 'Gestão de pesquisas',
          path: '/Engajamento/Gestao'
        },
        {
          nome: Model?.pesquisa.descricao ?? '',
          path: `/Engajamento/PEC/Convites/${Model?.pesquisa?.id}`
        },
        {
          nome: 'Resultado',
          path: ''
        }
      ]} />
      {pathname.includes('ResultadoPro') && <ButtonCmp VarColor='Green2' onClick={onAprovar}>Aprovar resultados e disponibilizar para o cliente</ButtonCmp>}
    </Flex>
    {!Model && (IsLoadingGeral ? <LoadingContainer linhas={24} loading></LoadingContainer> : <Flex mt={'3rem'} p={'2rem'} borderRadius={'.325rem'} bg={'var(--c4)'} boxShadow={'var(--SombraBackground)'} fontWeight={600} justifyContent={'center'} alignItems={'center'}>Resultados ainda não estão disponíveis</Flex>) }
    {Model && <Tabs variant='unstyled' marginTop={'1rem'} onChange={setActiveTab}>
      <TabList>
        <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Relatório Geral</Tab>
        <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Análise estatística</Tab>
        <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Comparativo entre pesquisas</Tab>
        <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Comparativo entre demografias</Tab>
        <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Comentários</Tab>
      </TabList>
      <TabPanels background={'white'} borderBottomLeftRadius='.25rem' borderBottomRightRadius='.25rem'>
        <TabPanel padding={'1rem'} position={'relative'}>
          <TabRelatorioGeral
            isLoading={IsLoadingGeral}
            onGetGrupo={GetDados}
            tabAtiva={ActiveTab === 0}
            Model={Model}
          />
        </TabPanel>
        <TabPanel padding={'1rem'} position={'relative'}>
          <TabAnaliseEstatistica
            Model={Model}
            tabAtiva={ActiveTab === 1}
          />
        </TabPanel>
        <TabPanel padding={'1rem'} position={'relative'}>
          <TabComparativaPesquisa
            Model={Model}
            onGetComparativo={GetComparativo}
            isLoading={IsLoadingComparativo}
            tabAtiva={ActiveTab === 2}
          />
        </TabPanel>
        <TabPanel padding={'1rem'} position={'relative'}>
          <TabComparativaDemografia
            Model={Model}
            tabAtiva={ActiveTab === 3}
          />
        </TabPanel>
        <TabPanel padding={'1rem'} position={'relative'}>
          <TabComentarios
            isLoading={IsLoadingComentarios}
            onGetGrupo={GetComentarios}
            Model={Model}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>}
  </Body>)
}
