/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Flex, FormControl, useToast, Grid, GridItem, Alert, AlertDescription, AlertIcon
} from '@chakra-ui/react'
import { appApi } from '../../../../services/appApi'
import { FaChevronRight } from 'react-icons/fa'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { InputCmp } from '../../../../components/InputCmp'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { Body } from '../../../Layouts/Body'
import { Main, Legenda } from '../../../PesquisaIndicativa/Criar/styles'
import { iEscala } from '../../../../interfaces'
import { BoxEscala } from './Components/BoxEscala'
import { TimelineCriarPEC } from '../Components/Timeline'

interface iModel {
  nome: string
  escalaId: string
  id: string
  escalas: iEscala[]
  adviceText?: string
}

interface iPostModel {
  id: string
  nome: string
  escalaId: string
  manual?: boolean
  completa?: boolean
  cultura?: number
}

export const CriarPesquisaEngajamento: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const path = useLocation().pathname
  const { id } = useParams<{id: string}>()
  const [isValid, setisValid] = useState(true)
  const [IsLoading, setIsLoading] = useState(false)
  const [EtapaCriacao, setEtapaCriacao] = useState(1)
  const [Model, setModel] = useState<iModel>({ escalaId: '', nome: '', id: '', escalas: [] })

  const useQuery = (): any => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const manual = query.get('manual')
  const completa = query.get('completa')
  const cultura = query.get('cultura')

  function GetCultura(cultura: string): any {
    switch (cultura) {
      case 'baseadaNaUltimaPec':
        return 13
      case 'maisUsadas':
        return 6
      case 'experienciaColaborador':
        return 7
      case 'Adaptativa':
        return 4
      case 'produzir':
        return 0
      case 'cultivar':
        return 2
      case 'explorar':
        return 3
      case 'estabilizar':
        return 1
    }

    return undefined
  }

  function criarPesquisa(): void {
    setisValid(true)

    if (Model?.nome.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Model?.escalaId === '' || !Model.escalaId) {
      toast({
        title: 'Selecione uma escala para sua pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const PostModel: iPostModel = {
      escalaId: Model.escalaId,
      id: Model.id,
      nome: Model.nome,
      completa: completa === 'true' ? true : completa === 'false' ? false : undefined,
      cultura: GetCultura(cultura ?? ''),
      manual: manual === 'true' ? true : manual === 'false' ? false : undefined
    }

    setIsLoading(true)
    appApi.post('Pec/Wiz/Criar', PostModel)
      .then(response => {
        nav(`/Engajamento/PEC/Demografia/${response.data}`)
        toast({
          title: 'Pesquisa criada com sucesso',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
      }).catch(({ response }) => {
        toast({
          title: 'Erro',
          status: 'error',
          position: 'top-right',
          isClosable: false
        })
      }).finally(() => {
        setIsLoading(false)
      })
  }

  function GetWiz(): void {
    appApi.get(`Pec/Wiz/Criar/${id}?completa=${completa || ''}`)
      .then((res) => setModel(res.data))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (Model.nome.length >= 5) {
      setEtapaCriacao(2)
    } else {
      setEtapaCriacao(1)
    }
  }, [Model])

  useEffect(() => {
    GetWiz()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <TimelineCriarPEC color={['var(--Azul)', 'var(--Azul)']}/>
      {Model.adviceText
        ? <Alert borderRadius={'.25rem'} justifyContent='space-between' status='warning' px={'2rem'} mt={'1rem'} py={'1.5rem'}>
    <Flex flexDir={'column'}>
      <Flex alignItems={'center'} gap={'.75rem'}>
        <AlertIcon color={'var(--Orange)'} w={'2.5rem'} h={'2.5rem'}/>
        <AlertDescription lineHeight={'2rem'} fontSize={'1.325rem'}>{Model.adviceText}</AlertDescription>
      </Flex>
    </Flex>
  </Alert>
        : <Main>
        <h1>Criar pesquisa</h1>
        <WhiteContainer>
          <FormControl marginBottom={'.75rem'} isInvalid={!isValid} >
            <InputCmp
              label='Nome da pesquisa'
              type={'text'}
              placeholder={'Digite um nome'}
              value={Model.nome}
              OnChange={(e) => setModel({ ...Model, nome: e })}
            />
          </FormControl>
        </WhiteContainer>

        {Model.escalas?.length > 0 && <WhiteContainer margin='1rem 0' IsDisabled={EtapaCriacao > 1 ? undefined : 'Complete a etapa anterior para seguir com a criação da pesquisa'}>
            <Flex flexDir={'column'} gap={'1rem'}>
              <h3>Escolha uma escala</h3>
              <Grid templateColumns={'repeat(2, 1fr)'} gap={'1rem'}>
                {
                  Model.escalas.map((e: iEscala, i: number) => {
                    if (e.fator !== 1) {
                      return (
                        <GridItem>
                          <BoxEscala
                            key={i}
                            Escala={e}
                            AdviceMessage={i === 0 ? 'O diferencial dessa escala é a ausência de um ponto neutro absoluto, o que incentiva o colaborador a refletir de maneira mais crítica sobre suas respostas' : 'O diferencial dessa escala é sua simplicidade, resultante do número reduzido de categorias disponíveis para escolha'}
                            Selecionado={Model.escalaId === e.id}
                            onClick={() => setModel({ ...Model, escalaId: e.id })}
                          />
                        </GridItem>
                      )
                    } else {
                      return (<></>)
                    }
                  })
                }
              </Grid>
            </Flex>
          </WhiteContainer>}

        <Flex justifyContent={'end'} marginTop='1rem' gap={'1rem'}>
          <ButtonCmp
            VarColor={'c6'}
            onClick={() => nav(-1)}
          >Voltar</ButtonCmp>

          <ButtonCmp
            onClick={criarPesquisa}
            VarColor='Green2'
            isLoading={IsLoading}
            rightIcon={<FaChevronRight />}
          >Criar pesquisa</ButtonCmp>
        </Flex>
      </Main>}
    </Body>
  )
}
